import React from 'react';

import { useStore } from '../../../../../hooks';

import Tick from '../../../../../images/icons/tick.svg';

import FakePageModal from '../FakePageModal';

import styles from './ConfirmationModal.module.scss';

interface Props {
  isOpen: boolean;
  continueLink?: string;
}

const ConfirmModal = ({ isOpen, continueLink }: Props) => {
  const { siteName, invertedLogo } = useStore('globals');

  const toHome = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (typeof window !== 'undefined' && continueLink) {
      return window.location.assign(continueLink);
    }
  };

  return (
    <FakePageModal
      isOpen={isOpen}
      title={'Successfully Submitted Application! 🎉'}
      icon={<Tick className={styles.tick} />}
    >
      <>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <a href="/" className={styles.logo}>
              <img src={invertedLogo} alt={siteName} />
            </a>
            <h1 className={styles.title}>Nursing Home Application</h1>
          </div>
          <div className={styles.headerRight}>
            <button className={styles.activeBtn} onClick={toHome}>
              Back to home
            </button>
          </div>
        </div>
        <p className={styles.info}>
          Thankyou for submitting your application. Amana Living with process your application ASAP and get back to you.
          If you have any issues please contact us at{' '}
          <a href="mailto:info@amanaliving.com.au">info@amanaliving.com.au</a>
        </p>
        <div className={styles.btnOptions}>
          <button className={styles.activeBtn} onClick={toHome}>
            Go back to homepage
          </button>
        </div>
      </>
    </FakePageModal>
  );
};

export default ConfirmModal;
