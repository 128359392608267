import React from 'react';

import QuestionnaireForm from '../forms/QuestionnaireForm';
import { useSiteQuery } from '../../hooks';

import query from './index.graphql';

interface Props {
  questionId: number;
  isOpen: boolean;
  onClose: () => void;
  initValue?: string;
}

const Questionnaire = ({ questionId, isOpen, onClose, initValue }: Props) => {
  const [{ data }] = useSiteQuery<QuestionnairesQuery>({ query, variables: { questionId } });
  const entry = data?.entry;

  if (entry?.question?.length && isOpen) {
    const questions: QuestionFragment[] = [];
    for (let i = 0; i < entry.question.length; i++) {
      const question = entry?.question?.[i];
      if (question) {
        questions.push(question);
      }
    }
    return <QuestionnaireForm questionnaire={entry} questions={questions} initValue={initValue} onClose={onClose} />;
  }

  return null;
};

export default Questionnaire;
