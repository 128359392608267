import React, { useCallback, useState } from 'react';
import cc from 'classcat';
import AnimateHeight from 'react-animate-height';

import { useSiteQuery } from '../../hooks';

import ArrowDownIcon from '../../images/icons/arrow-down.svg';
import ArrowUpIcon from '../../images/icons/arrow-up.svg';
import ExploreIcon from '../../images/icons/explore-connect.svg';

import query from './pagesInSection.graphql';

import styles from './styles.module.scss';

interface Props {
  uri: string;
}

const PagesInSection = ({ uri }: Props) => {
  const parentURI = uri.split('/').slice(0, -1).join('/');
  const level = parentURI.split('/').length + 1;

  const [active, setActive] = useState(true);
  const [{ data }] = useSiteQuery<PagesInSectionQuery>({
    query,
    variables: {
      parentURI: `${parentURI}/*`,
      uri: `${uri}/*`,
      level: level,
    },
  });

  const entries = data?.childs?.length ? data?.childs : data?.entries;

  const toggleActive = useCallback(() => {
    setActive(!active);
  }, [active]);

  let longTitles = false;

  entries?.forEach((entry) => {
    entry?.title && entry.title?.length > 28 && (longTitles = true);
  });

  if (entries?.length) {
    return (
      <div className={styles.pageInSection}>
        <div className="container">
          <div className={styles.inner}>
            <ExploreIcon className={styles.connect} />
            <div className={styles.toggle} role="button" tabIndex={0} onClick={toggleActive} onKeyPress={toggleActive}>
              EXPLORE THIS SECTION
              {active ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </div>
            <AnimateHeight duration={200} height={active ? 'auto' : 0}>
              <div className={cc([styles.sections, { [styles.active]: active }])}>
                {entries.map(
                  (item) =>
                    item?.uri !== uri && (
                      <div key={item?.title} className={cc([styles.link, { [styles.wideLink]: longTitles }])}>
                        <a href={`/${item?.uri}`}>{item?.title}</a>
                      </div>
                    ),
                )}
              </div>
            </AnimateHeight>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default PagesInSection;
