import React from 'react';
import { useFormikContext } from 'formik';

import { useIsMobile } from '../../../../../hooks';
import { homeOwnershipValues } from '../../../../../lib/NursingFormikValues';

import { AddressField, InputField, NextStepField, RadioField } from '../../Fields';

import formStyles from '../../nursingHomeForm.module.scss';

interface Props {
  setStep: (step: number) => void;
}

const HomeOwnership = ({ setStep }: Props) => {
  const { values } = useFormikContext<NursingHomeApplicationInterface>();
  const [isMobile] = useIsMobile(1000);

  return (
    <>
      <div>
        {!isMobile && <p className={formStyles.headingL}>Home ownership details</p>}
        <p className={formStyles.headingS}>Have you owned your home for the last two years?</p>
        <RadioField label="" name="ownedHome2years" values={['yes', 'no']} clearBg />
        <p className={formStyles.headingS}>Is your home occupied by your Carer/Spouse?</p>
        <RadioField label="" name="homeOccupied" values={['yes', 'no']} clearBg />
        <p className={formStyles.headingS}>Is it intended to sell the home on entry to residential aged care</p>
        <RadioField label="" name="sellHomeOnEntry" values={['yes', 'no']} clearBg />
        <p className={formStyles.headingS}>Address of primary residence</p>
        <div className={formStyles.inputSection}>
          <AddressField label="Address" name="homeAddress" />
        </div>
        <div className={`${formStyles.hide} ${values.sellHomeOnEntry == 'no' && formStyles.show}`}>
          <p className={formStyles.headingS}>
            If it is not intended to sell the home, please advise expected weekly rental
          </p>
          <div className={formStyles.inputSection}>
            <InputField label="Rental income per week" name="rentalPerWeek" type="number" min="0" />
          </div>
        </div>
      </div>
      <NextStepField setStep={setStep} stepNum={8} fieldsToCheck={homeOwnershipValues} />
    </>
  );
};

export default HomeOwnership;
