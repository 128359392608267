import React from 'react';
import { useFormikContext } from 'formik';

import { allFieldsBySection } from '../../../../lib/NursingFormikValues';

import ErrorIcon from '../../../../images/icons/error-circle.svg';
import GreenTick from '../../../../images/icons/greenTick.svg';

import { handleRequiredFields } from '../Fields/NextStepField';

import styles from './formProgress.module.scss';

interface Props {
  steps: Array<string>;
  setStep: (step: number) => void;
  currentStep: number;
}

const FormProgress = ({ steps, setStep, currentStep }: Props) => {
  const {
    values,
    errors,
    touched,
    validateField,
    setFieldValue,
    setTouched,
  } = useFormikContext<NursingHomeApplicationInterface>();

  const getIcon = (stepIdx: number) => {
    const icon =
      stepIdx === currentStep ? (
        <div className={styles.dotActive}>
          <div className={styles.dotActiveInner} />
        </div>
      ) : values[`sectionStatus_${stepIdx}`] == 'error' ? (
        <div className={styles.dotGreen}>
          <ErrorIcon className={styles.dotIcon} />
        </div>
      ) : values[`sectionStatus_${stepIdx}`] === 'done' ? (
        <div className={styles.dotGreen}>
          <GreenTick className={styles.dotIcon} />
        </div>
      ) : (
        <div className={styles.dot} />
      );
    return icon;
  };

  const isAllowed = (stepIdx: number) => values[`sectionStatus_${stepIdx}`] || stepIdx <= currentStep;

  const handleStepClick = (stepIdx: number, currentIdx: number) => {
    const fieldsToCheck = currentIdx === 9 ? [] : allFieldsBySection[currentIdx];
    if (!fieldsToCheck || !isAllowed(stepIdx)) return;
    handleRequiredFields(
      undefined,
      stepIdx,
      currentIdx,
      fieldsToCheck,
      errors,
      touched,
      setStep,
      validateField,
      setFieldValue,
      setTouched,
    );
  };

  return (
    <div className={styles.container}>
      {steps?.map((step, stepIdx) => (
        <div key={`step-${step}-${stepIdx}`}>
          <div
            className={`${styles.step} ${isAllowed(stepIdx) ? styles.cursor : ''}`}
            onClick={() => handleStepClick(stepIdx, currentStep)}
            aria-hidden
          >
            <div className={styles.details}>
              {getIcon(stepIdx)}
              <div className={styles.textBox}>
                <p className={styles.inactiveTitle}>{step}</p>
                {values[`sectionStatus_${stepIdx}`] == 'error' && <p className={styles.errorText}>Incomplete</p>}
              </div>
            </div>
          </div>
          <div className={styles.dividerContainer}>
            <div className={styles.divider} />
          </div>
          {/* After all step show "complete step" */}
          {steps.length - 1 == stepIdx && (
            <div className={styles.step}>
              <div className={styles.details}>
                <div className={styles.dotComplete} />
                <p className={styles.inactiveTitle}>Complete</p>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FormProgress;
