import React, { useCallback, useState } from 'react';
import cc from 'classcat';
import slugify from 'slugify';

import { useIsMobile } from '../../../hooks';

import NextIcon from '../../../images/icons/chevon-next.svg';
import PrevIcon from '../../../images/icons/chevon-prev.svg';

import styles from './styles.module.scss';

export type ColourSchemaType = 'purple' | 'green' | 'orange';

interface Props {
  entry: HomeCareServicesHomeCareServicesEntry;
  odd: boolean;
}

const Services = ({ entry, odd }: Props) => {
  const [isMobile] = useIsMobile(991);
  const [activeItem, setActiveItem] = useState((entry?.services?.[0] as any) as ServicesFragment);
  const id = slugify(entry?.title || '').toLowerCase();
  const classnames = cc([styles.serviceContainer, styles[(entry?.colourScheme as any) as ColourSchemaType]]);

  const isActive = useCallback(
    (item: ServicesFragment) => {
      if (activeItem) {
        return activeItem.title === item.title;
      }
      return false;
    },
    [activeItem],
  );

  const handleClick = (item: ServicesFragment, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setActiveItem(item);
  };

  const nextClick = useCallback(() => {
    const idx = entry?.services?.indexOf(activeItem as ServicesNeoField);
    const length = entry?.services?.length || 0;
    if (idx === length - 1) {
      setActiveItem((entry?.services?.[0] as any) as ServicesFragment);
    } else if (entry?.services?.[idx! + 1]) {
      setActiveItem((entry?.services?.[idx! + 1] as any) as ServicesFragment);
    }
  }, [activeItem, entry?.services]);

  const prevClick = useCallback(() => {
    const idx = entry?.services?.indexOf(activeItem as ServicesNeoField);
    const length = entry?.services?.length || 0;
    if (idx === 0 && length >= 1) {
      setActiveItem((entry?.services?.[length - 1] as any) as ServicesFragment);
    } else if (entry?.services?.[idx! - 1]) {
      setActiveItem((entry?.services?.[idx! - 1] as any) as ServicesFragment);
    }
  }, [activeItem, entry?.services]);

  if (entry) {
    return (
      <div id={id} className={classnames}>
        <div className="container">
          <div className={styles.services}>
            <div className="row">
              {!!odd && !isMobile && (
                <div className="col-lg-6">
                  <div className="sectionHeading">
                    <span>{entry?.title}</span>
                  </div>
                  {activeItem && (
                    <div>
                      <h2>{activeItem.title}</h2>
                      {activeItem.description ? ( // eslint-disable-next-line react/no-danger
                        <div dangerouslySetInnerHTML={{ __html: activeItem.description }} />
                      ) : null}
                    </div>
                  )}
                </div>
              )}
              <div className="col-lg-6">
                <div className="sectionHeading">HOME CARE SERVICES</div>
                <h2>{entry?.title}</h2>
                <p>{entry?.description}</p>
                <div className={styles.items}>
                  {entry?.services?.map(
                    (s) =>
                      s && (
                        <button
                          key={s?.title}
                          type="button"
                          className={cc([
                            styles.item,
                            styles[(entry?.colourScheme as any) as ColourSchemaType],
                            { [styles.active]: isActive((s as any) as ServicesFragment) },
                          ])}
                          onClickCapture={(e) => handleClick((s as any) as ServicesFragment, e)}
                        >
                          <div id={slugify(s?.title || '').toLowerCase()} className={styles.icon}>
                            {s?.icon?.[0]?.url && <img src={s?.icon?.[0]?.url} alt={s?.title || undefined} />}
                          </div>
                          <p className="service-label">{s?.title}</p>
                        </button>
                      ),
                  )}
                </div>
              </div>
              {(!odd || isMobile) && (
                <div className={cc(['col-lg-6', styles.serviceMobile])}>
                  <div className="sectionHeading">
                    <span>{entry?.title}</span>
                  </div>
                  {activeItem && (
                    <div>
                      <h2>{activeItem.title}</h2>
                      {activeItem.description ? ( // eslint-disable-next-line react/no-danger
                        <div dangerouslySetInnerHTML={{ __html: activeItem.description }} />
                      ) : null}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <button className={styles.nextButton} type="button" onClickCapture={() => nextClick()}>
          <NextIcon />
        </button>
        <button className={styles.prevButton} type="button" onClickCapture={() => prevClick()}>
          <PrevIcon />
        </button>
      </div>
    );
  }

  return null;
};

export default Services;
