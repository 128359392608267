import React from 'react';

import { Readmore } from '../index';
import Image from '../layout/Image';

import thumbImage from '../../images/placeholder.gif';

import styles from './styles.module.scss';

export type ArticleType = NewsFragment | EventFragment | InformationGuideFragment;

interface Props {
  item: ArticleType;
}

const renderTopic = (item: ArticleType) => {
  switch (item.__typename) {
    case 'articles_news_Entry':
      return 'News';
    case 'articles_event_Entry':
      return 'Event';
    case 'informationGuides_informationGuides_Entry':
      return item.topic?.[0]?.title;
    default:
      // TODO: should never happen
      return null;
  }
};

const CardItem = ({ item }: Props) => {
  const title = item.title!;
  const image = item.heroImage;

  return (
    <div className={styles.cardItem}>
      <div>
        <div className={styles.info}>
          <span>{renderTopic(item)}</span>
          <h3>
            <a href={`/${item.uri}`}>{title}</a>
          </h3>
        </div>
        <a className={styles.thumbImage} href={`/${item.uri}`} aria-label={title}>
          <div>{image ? <Image {...image[0]} alt={title} /> : <img loading="lazy" src={thumbImage} alt={title} />}</div>
        </a>
        <div className={styles.bottom}>
          <Readmore href={`/${item.uri}`} />
        </div>
      </div>
    </div>
  );
};

export default CardItem;
