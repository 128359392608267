import React from 'react';
import { useFormikContext } from 'formik';

import { useIsMobile } from '../../../../../hooks';

import { FileField, NextStepField, RadioField } from '../../Fields';

import formStyles from '../../nursingHomeForm.module.scss';

interface Props {
  setStep: (step: number) => void;
}

const Guardianship = ({ setStep }: Props) => {
  const { values } = useFormikContext<NursingHomeApplicationInterface>();
  const [isMobile] = useIsMobile(1000);

  return (
    <>
      <div>
        {!isMobile && <p className={formStyles.headingL}>Enduring Power of Guardianship</p>}
        <RadioField label="Same as Enduring Power of Attorney" name="powerOfGuardianship_sameAs_attorney" stretch />
        {values?.powerOfGuardianship_sameAs_attorney == 'no' && (
          <FileField label="Enduring Power of Guardianship" name="powerOfGuardianship" />
        )}
      </div>
      <NextStepField setStep={setStep} stepNum={5} fieldsToCheck={['powerOfGuardianship_sameAs_attorney']} />
    </>
  );
};

export default Guardianship;
