import React from 'react';

import styles from './styles.module.scss';

interface Props {
  info: CourseInfoFragment;
}

const InfoTable = ({ info }: Props) => {
  if (info) {
    return (
      <table className={styles.table}>
        <tbody>
          {info.studyMethod && (
            <tr>
              <td>Study Method</td>
              <td>{info.studyMethod}</td>
            </tr>
          )}
          {info.location && (
            <tr>
              <td>Location</td>
              <td>{info.location}</td>
            </tr>
          )}
          {info.recognition && (
            <tr>
              <td>Recognition</td>
              <td>{info.recognition}</td>
            </tr>
          )}
          {info.startDate && (
            <tr>
              <td>Start Date</td>
              <td>{info.startDate}</td>
            </tr>
          )}
          {info.fees && (
            <tr>
              <td>Fees</td>
              <td>{info.fees}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  return null;
};

export default InfoTable;
