import React from 'react';
import mbxStatic from '@mapbox/mapbox-sdk/services/static';

import styles from './styles.module.scss';

interface Props {
  lat?: Nullable<number | string>;
  lng?: Nullable<number | string>;
  width?: number;
  height?: number;
  zoom?: number;
  pitch?: number;
  address?: string;
  alt?: string;
}

const Map = ({ lat, lng, width, height, zoom, pitch, address, alt }: Props) => {
  if (lat && lng) {
    lat = Number.parseFloat(lat?.toString());
    lng = Number.parseFloat(lng?.toString());
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address || `${lat},${lng}`)}`;

    const request = mbxStatic({
      accessToken: process.env.MAPBOX_TOKEN || process.env.NEXT_PUBLIC_MAPBOX_TOKEN || '',
    }).getStaticImage({
      ownerId: 'mapbox',
      styleId: 'streets-v11',
      width: width || 720,
      height: height || 200,
      highRes: true,
      position: {
        coordinates: [lng, lat],
        zoom: zoom || 13,
        pitch: pitch || 0,
      },
      overlays: [
        {
          marker: {
            coordinates: [lng, lat],
            size: 'large',
            color: 'B43347',
          } as any, // TODO: fix typing at https://github.com/DefinitelyTyp/DedefinitelyTyped/tree/master/types/mapbox__mapbox-sdk
        },
      ],
    });
    return (
      <a href={url} target="_blank" rel="noreferrer noopener" className={styles.map}>
        <img src={request.url()} alt={alt || ''} />
      </a>
    );
  }

  return null;
};

export default Map;
