import React from 'react';
import cc from 'classcat';

import Next from '../../../images/icons/arrow-next.svg';
import Previous from '../../../images/icons/arrow-previous.svg';

import styles from './styles.module.scss';

interface Props {
  href?: string;
  onClick?: () => void;
  type: 'previous' | 'next';
  children: React.ReactNode;
}

const ArrowButton = ({ href, onClick, type, children }: Props) => {
  const classes = cc({
    [styles.arrowBtn]: true,
    [styles[type]]: true,
  });

  if (onClick) {
    return (
      <button className={classes} onClick={onClick} type="button" aria-label={type}>
        {type === 'previous' && <Previous />}
        {children}
        {type === 'next' && <Next />}
      </button>
    );
  }

  return (
    <a className={classes} href={href}>
      {type === 'previous' && <Previous />}
      {children}
      {type === 'next' && <Next />}
    </a>
  );
};

export default ArrowButton;
