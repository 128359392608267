import React from 'react';

import { UnitCard } from '../../index';
import { useSiteQuery } from '../../../hooks';

import query from './index.graphql';

import styles from './styles.module.scss';

interface Props {
  location: string;
  except?: string;
}

const Units = ({ location, except }: Props) => {
  const [{ data }] = useSiteQuery<UnitsQuery>({ query, variables: { location } });
  const entries = data?.entries;

  if (entries && entries.filter((item) => item?.slug !== except).length) {
    return (
      <div className="section left">
        <div className="container">
          <h2 id="available-units" className={styles.unitHeader}>
            Available Units
          </h2>
          <div className="row">
            {entries
              ?.filter((item) => item?.slug !== except)
              .map((item) => item && <UnitCard key={item.uid!} item={item} />)}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default Units;
