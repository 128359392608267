import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { replace, sum } from 'lodash';

import { useIsMobile } from '../../../../../hooks';
import { audCurrency } from '../../../../../lib/CurrencyUtils';
import { financeValues } from '../../../../../lib/NursingFormikValues';

import { GiftField, NextStepField, PriceField, RadioField } from '../../Fields';

import formStyles from '../../nursingHomeForm.module.scss';
import styles from './styles.module.scss';

interface Props {
  setStep: (step: number) => void;
}

const FinancialStatement = ({ setStep }: Props) => {
  const { values } = useFormikContext<NursingHomeApplicationInterface>();
  const [isMobile] = useIsMobile(1000);

  return (
    <>
      {!isMobile && <p className={formStyles.headingL}>Financial Statement</p>}
      {values.isCouple == 'yes' && (
        <RadioField
          label="Are the assets of both residents combined?"
          name="combinedAssets"
          values={['yes', 'no']}
          stretch
        />
      )}
      <div className={`${formStyles.hide} ${values.combinedAssets == 'no' && formStyles.show}`}>
        <p className={formStyles.headingM}>First resident</p>
        <div className={formStyles.thinDivider} />
      </div>
      {values.combinedAssets == 'no' ? (
        <>
          <FinancialSection id="1" />
          <p className={formStyles.headingM}>Second Resident</p>
          <div className={formStyles.thinDivider} />
          <FinancialSection id="2" />
        </>
      ) : (
        <FinancialSection id="1" />
      )}
      <NextStepField setStep={setStep} stepNum={9} fieldsToCheck={financeValues} />
    </>
  );
};

interface FinanceSectionProps {
  id: string;
}

const FinancialSection = ({ id }: FinanceSectionProps) => {
  const [isMobile] = useIsMobile(1000);

  return (
    <div className={`${styles.financeSection} ${formStyles.fadeIn}`}>
      <p className={formStyles.headingM}>Assets</p>
      <FinanceFields
        totalText="assets"
        fields={[
          { label: 'Home (excluding contents)', name: `homeValue_${id}` },
          { label: 'Home Contents', name: `homeContentValue_${id}` },
          { label: 'Other Properties (including vacant land)', name: `otherPropValue_${id}` },
          { label: 'Shares/Managed Funds', name: `managedFundsValue_${id}` },
          { label: 'Term Deposits/Bonds/Debentures etc.', name: `termDepositsValue_${id}` },
          { label: 'Bank Accounts/Credit Unions/Building Services', name: `bankValue_${id}` },
          { label: 'Superannuation/Allocated Pension Balance', name: `superAnnuationValue_${id}` },
          { label: 'Loans to Other Parties', name: `loansValue_${id}` },
          { label: 'Antiques/Works of Art etc.', name: `artValue_${id}` },
          { label: 'Motor Vehicles/Boat/Caravan', name: `vehicleValue_${id}` },
          { label: 'Other Assets', name: `otherAssetsValue_${id}` },
          { label: 'Funeral Bond', name: `funeralBondValue_${id}` },
        ]}
      />
      <p className={formStyles.headingM}>Debts</p>
      <FinanceFields
        totalText="debts"
        fields={[
          { label: 'Mortgage', name: `mortgage_${id}` },
          { label: 'Other debts/commitments owed', name: `debtsOwed_${id}` },
        ]}
      />
      <p className={formStyles.headingM}>Gifting</p>
      <RadioField
        label="Have you gifted away any assets in the last 5 years?"
        name={`giftedAssets_${id}`}
        values={['yes', 'no']}
        stretch
      />
      <GiftField financeId={id} />
      <div className={formStyles.thickDivider} />
      <p className={formStyles.headingM}>Income</p>
      {isMobile ? (
        <div className={styles.spacerForPensionType}>
          <p className={styles.assetLabel}>Age Pension Type</p>
          <RadioField name={`pensionType_${id}`} clearBg values={['Full', 'Part']} className={styles.pensionType} />
        </div>
      ) : (
        <RadioField name={`pensionType_${id}`} clearBg values={['Full', 'Part']} className={styles.pensionType} />
      )}
      <FinanceFields
        totalText="income"
        fields={[
          { label: 'Australian Age Pension ', name: `agePension_${id}` },
          { label: 'Veteran Affairs Pension', name: `veteranPension_${id}` },
          { label: 'Overseas Pension', name: `overseasPension_${id}` },
          { label: 'Other Pensions (War widow/blind etc)', name: `otherPensions_${id}` },
          { label: 'Income Support Supplement', name: `incomeSupport_${id}` },
          { label: 'Superannuation', name: `superannuation_${id}` },
          { label: 'Property Income (net)', name: `propertyIncome_${id}` },
        ]}
      />
    </div>
  );
};

interface FinanceProps {
  fields: Array<{
    label: string;
    name: string;
  }>;
  totalText: string;
}

const FinanceFields = ({ fields, totalText }: FinanceProps) => {
  const { values } = useFormikContext<NursingHomeApplicationInterface>();
  const total = useMemo(() => audCurrency.format(sum(fields.map((asset) => values?.[asset?.name] || 0))), [
    fields,
    values,
  ]);

  return (
    <>
      {fields?.map((asset, idx) => (
        <div key={`${asset.label}-${asset.name}`}>
          <div className={styles.asset}>
            <p className={styles.assetLabel}>{replace(asset.label, '/', ' / ')}</p>
            <PriceField name={asset.name} />
          </div>
          {fields.length - 1 !== idx && <div className={formStyles.thinDivider} />}
        </div>
      ))}
      <div className={formStyles.mediumDivider} />
      <div className={styles.summary}>
        <p className={formStyles.headingS}>Total {totalText}</p>
        <p className={formStyles.headingS}>{total}</p>
      </div>
      <div className={formStyles.thickDivider} />
    </>
  );
};

export default FinancialStatement;
