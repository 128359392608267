import React from 'react';
import cc from 'classcat';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';

import FormikField from '../../../FormikField';
import { Heading } from '../../../Wizard';
import { QuestionnaireFormValues, StepComponent } from '../../../../../typings';

import styles from './styles.module.scss';

const ContactDetailsStep: StepComponent = () => {
  const { values } = useFormikContext<QuestionnaireFormValues>();

  return (
    <>
      <Heading>Tell us some details so we can so we can help you further</Heading>
      <fieldset className={styles.detail}>
        <div className={styles.row}>
          <div className={cc([styles.column, 'customInput'])}>
            <label htmlFor="firstName">First name</label>
            <FormikField name="firstName" type="text" value={values.firstName} required placeholder="e.g. John" />
          </div>
          <div className={cc([styles.column, 'customInput'])}>
            <label htmlFor="lastName">Last name</label>
            <FormikField name="lastName" type="text" value={values.lastName} required placeholder="e.g. John Smith" />
          </div>
        </div>
        <div className={styles.row}>
          <div className={cc([styles.column, 'customInput'])}>
            <label htmlFor="phone">Contact number</label>
            <FormikField name="phone" type="tel" value={values.phone} required placeholder="e.g. 0404 555 444" />
          </div>
          <div className={cc([styles.column, 'customInput'])}>
            <label htmlFor="email">Email address</label>
            <FormikField name="email" type="email" value={values.email} required placeholder="e.g. john@email.com" />
          </div>
        </div>
        <div className={styles.row}>
          <div className={cc([styles.column, 'customInput'])}>
            <label htmlFor="phone">Postcode or suburb</label>
            <FormikField
              name="postcode"
              type="text"
              value={values.postcode}
              required
              placeholder="e.g. 6000 or Perth"
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.full}>
            <div className={styles.checkbox}>
              <FormikField
                name="termsAndConditions"
                id="termsAndConditions"
                type="checkbox"
                checked={values.termsAndConditions}
                required
              >
                <label htmlFor="termsAndConditions">&nbsp;</label>
              </FormikField>
              <span>
                I accept the
                <a href="/terms-conditions"> Terms & Conditions </a>
                and
                <a href="/privacy"> Privacy Policy </a>
                for Amana Living.
              </span>
            </div>
          </div>
        </div>
      </fieldset>
    </>
  );
};

ContactDetailsStep.label = 'Your details';
ContactDetailsStep.validationSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
  phone: Yup.string().required(),
  termsAndConditions: Yup.boolean(),
});

export default ContactDetailsStep;
