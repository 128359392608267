import React from 'react';
import scrollIntoView from 'scroll-into-view';

import { Button } from '../../index';
import { useIsMobile } from '../../../hooks';
import { urlFor } from '../../../lib/Link';

import Arrow from '../../../images/icons/arrow-next.svg';

import styles from './styles.module.scss';

interface Props {
  item: AccordionItemFragment;
}

const AccordionLink = ({ item }: Props) => {
  const linkField = item?.linkField?.[0];
  const [isMobile] = useIsMobile(768);

  const scrollToSection = (slug: string) => {
    const isRowSection = slug.startsWith('row-section');
    const sectionPadding = isRowSection ? (isMobile ? 40 : 160) : isMobile ? 0 : 80;

    if (document.getElementById(slug)) {
      scrollIntoView(document.getElementById(slug)!, { align: { top: 0, topOffset: sectionPadding } });
    }
  };

  if (linkField) {
    const props =
      urlFor(linkField).indexOf('/#') === 0
        ? {
            onClick: () => scrollToSection(urlFor(linkField).replace('/#', '')),
          }
        : {
            href: urlFor(linkField),
          };

    return (
      <p>
        <Button type="link" {...props} className={styles.link}>
          Learn more
          <Arrow />
        </Button>
      </p>
    );
  }
  return null;
};

export default AccordionLink;
