import React from 'react';
import ReactModal from 'react-modal';

import styles from './fakePageModal.module.scss';

interface Props {
  title?: string;
  isOpen: boolean;
  children: JSX.Element;
  icon?: JSX.Element;
  onRequestClose?: () => void;
}

const FakePageModal = ({ isOpen, icon, onRequestClose, title, children }: Props) => {
  function afterOpenModal() {
    window?.scrollTo(0, 0);
  }

  return (
    <ReactModal
      onRequestClose={onRequestClose}
      onAfterOpen={afterOpenModal}
      isOpen={isOpen}
      className={{
        base: styles.modalOverlay,
        afterOpen: '',
        beforeClose: '',
      }}
      overlayClassName={{
        base: '',
        afterOpen: '',
        beforeClose: '',
      }}
      shouldCloseOnOverlayClick
    >
      <div className={styles.container}>
        {icon}
        {title && <p className={styles.title}>{title}</p>}
        <div className={styles.content}>{children}</div>
      </div>
    </ReactModal>
  );
};

export default FakePageModal;
