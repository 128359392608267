import React from 'react';

import { isExternal, urlFor } from '../../../lib/Link';

import styles from './styles.module.scss';

interface Props {
  course: TrainingCourseFragment;
}

const renderItem = (item: TrainingCourseItemFragment) => {
  if (item) {
    if (item.linkField?.length) {
      const linkField = item.linkField?.[0];
      const link = urlFor(linkField);
      const props = isExternal(linkField)
        ? {
            target: '_blank',
            rel: 'noopener noreferrer',
          }
        : {};

      return (
        <li key={item.uid!}>
          <a href={link} {...props}>
            {item.title!}
          </a>
        </li>
      );
    }

    return <li key={item.uid!}>{item.title!}</li>;
  }

  return null;
};

const Course = ({ course }: Props) => {
  const items = course?.children || [];

  if (items.length) {
    return (
      <div key={course.uid} className={styles.download}>
        <h4>{course.title!}</h4>
        <ul>{items.map((item) => item && renderItem(item))}</ul>
      </div>
    );
  }
  return null;
};

export default Course;
