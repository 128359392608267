import React from 'react';

import {
  About,
  Accommodation,
  Attorney,
  Centerlink,
  ConfirmationStep,
  FinancialStatement,
  Guardianship,
  HomeOwnership,
  NextOfKin,
  PersonalDetails,
} from './Steps';

import styles from './nursingHomeForm.module.scss';

interface Props {
  setStep: (step: number) => void;
  currentStep: number;
}

const NursingHomeForm = ({ setStep, currentStep }: Props) => {
  return (
    <form className={styles.container}>
      {currentStep === 0 && <About setStep={setStep} />}
      {currentStep === 1 && <PersonalDetails setStep={setStep} />}
      {currentStep === 2 && <NextOfKin setStep={setStep} />}
      {currentStep === 3 && <Attorney setStep={setStep} />}
      {currentStep === 4 && <Guardianship setStep={setStep} />}
      {currentStep === 5 && <Accommodation setStep={setStep} />}
      {currentStep === 6 && <Centerlink setStep={setStep} />}
      {currentStep === 7 && <HomeOwnership setStep={setStep} />}
      {currentStep === 8 && <FinancialStatement setStep={setStep} />}
      {currentStep === 9 && <ConfirmationStep />}
    </form>
  );
};

export default NursingHomeForm;
