import React from 'react';
import cc from 'classcat';
import { JsonLd } from 'react-schemaorg';
import { BreadcrumbList, Thing } from 'schema-dts';

import styles from './styles.module.scss';

interface Props {
  breadcrumbs: BreadcrumbItem[];
}

const Breadcrumbs = ({ breadcrumbs }: Props) => {
  return (
    <div className={cc([styles.breadcrumbs, 'sectionWrapper'])}>
      <div className="container">
        <a href="/">Home</a>
        {breadcrumbs.map((b, i) => (
          <React.Fragment key={b?.title}>
            <span className={styles.slash}>/</span>
            {i === breadcrumbs.length - 1 ? <span>{b.title}</span> : <a href={b.uri}>{b.title}</a>}
          </React.Fragment>
        ))}
        <JsonLd<BreadcrumbList>
          item={{
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: breadcrumbs.map((b, i) => ({
              '@type': 'ListItem',
              position: i + 1,
              item: {
                '@type': 'ItemPage',
                '@id': b.uri,
                name: b.title,
              } as Thing,
            })),
          }}
        />
      </div>
    </div>
  );
};

export default Breadcrumbs;
