import React from 'react';

import PathwayNode from './node';

import styles from './styles.module.scss';

interface Props {
  item: PathwayItemFragment;
  level: number;
  currentActive: string;
  nexts: string[];
  previous: string[];
  onClick: (level: number, node: string) => void;
}

const PathwayItem = ({ item, level, currentActive, nexts, previous, onClick }: Props) => {
  if (item) {
    const childs = item?.children || [];
    return (
      <div className={styles.column}>
        {childs.map(
          (node, index) =>
            node && (
              <PathwayNode
                key={node.uid}
                node={node}
                level={level}
                index={index + 1}
                currentActive={currentActive}
                isActive={currentActive === `${level}-${index + 1}`}
                isNext={nexts.includes(`${level}-${index + 1}`)}
                isPrevious={previous.includes(`${level}-${index + 1}`)}
                onClick={onClick}
              />
            ),
        )}
      </div>
    );
  }
  return null;
};

export default PathwayItem;
