import React from 'react';

import query from '../../../../../graphql/NursingHomeApplication.graphql';
import { useIsMobile, useSiteQuery } from '../../../../../hooks';

import { MainFiles, NextStepField } from '../../Fields';

import formStyles from '../../nursingHomeForm.module.scss';

interface Props {
  setStep: (step: number) => void;
}

// The first section in the Nursing Homes Application Form
const About = ({ setStep }: Props) => {
  const [isMobile] = useIsMobile(1000);
  const [{ data }] = useSiteQuery<NursingHomeApplicationQuery>({ query });
  const aboutBlurb = data?.entry?.__typename == 'pages_pages_Entry' && data?.entry?.pageContent?.[0]?.children;

  return (
    <>
      {!isMobile && <p className={formStyles.headingXS}>About this application</p>}
      <div className={formStyles.redactorField} dangerouslySetInnerHTML={{ __html: aboutBlurb?.[0]?.body }} />
      {isMobile && (
        <p className={formStyles.mobileHelpText}>
          NB: It may be easier to find and attach these documents using a desktop computer or laptop. If you are having
          difficulty uploading these documents, Save & Exit to save your progress and complete this application on
          another device.
        </p>
      )}
      {isMobile && <NextStepField setStep={setStep} stepNum={1} fieldsToCheck={[]} />}
      {!isMobile && <div className={formStyles.divider} />}
      <p className={formStyles.headingL}>What you will need</p>
      <MainFiles showBg showDescription />
      {!isMobile && <NextStepField setStep={setStep} stepNum={1} fieldsToCheck={[]} />}
    </>
  );
};

export default About;
