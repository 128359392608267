import React, { useEffect, useState } from 'react';
import AutoSuggest from 'react-autosuggest';
import { useDebouncedCallback } from 'use-debounce';

import Autocomplete from '../../lib/Autocomplete';

import styles from './styles.module.scss';

interface Props {
  active: boolean;
}

type SuggestInputType = HTMLInputElement | undefined;

const SearchInput = ({ active }: Props) => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState<SearchItem[]>([]);

  let inputRef: SuggestInputType = undefined;

  useEffect(() => {
    if (active && value === '') {
      setTimeout(() => inputRef && inputRef.focus(), 200);
    }
  }, [active, value, inputRef]);

  const [getSuggestions] = useDebouncedCallback(async (value: string) => {
    const data = await Autocomplete.getSuggestions(value);
    setSuggestions(data);
  }, 300);

  function renderInputComponent(inputProps: any) {
    return (
      <div className="react-autosuggest__container">
        <input {...inputProps} />
      </div>
    );
  }

  function renderSuggestion(item: SearchItem) {
    if (item?.uri) {
      const props = /^http/.test(item.uri)
        ? {
            target: '_blank',
            rel: 'noopener noreferrer',
          }
        : {};
      const href = /^http/.test(item.uri) ? item.uri : `/${item.uri}`;

      return (
        <a key={item.uid} href={href} {...props}>
          <p>
            <strong>{item.title}</strong>
          </p>
        </a>
      );
    }
    return null;
  }

  function inputReference(autosuggest: any) {
    if (autosuggest !== null) {
      inputRef = autosuggest.input;
    }
  }

  return (
    <div className={styles.searchInput}>
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionsFetchRequested={({ value }) => getSuggestions(value)}
        onSuggestionSelected={(_, { suggestion }) => suggestion?.title && setValue(suggestion.title)}
        getSuggestionValue={(suggestion) => suggestion.title}
        shouldRenderSuggestions={(value) => value.trim().length >= 3}
        renderInputComponent={renderInputComponent}
        renderSuggestion={(item) => renderSuggestion(item)}
        renderSuggestionsContainer={({ containerProps, children }) => {
          return (
            <div {...containerProps}>
              <div>Top results</div>
              {children}
            </div>
          );
        }}
        inputProps={{
          placeholder: 'Enter a keyword to search',
          value: value,
          onChange: (_, { newValue }) => {
            setValue(newValue);
          },
        }}
        ref={inputReference}
      />
    </div>
  );
};

export default SearchInput;
