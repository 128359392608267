import React from 'react';
import cc from 'classcat';

import { Button } from '../index';
import { useSiteQuery } from '../../hooks';
import { isExternal, urlFor } from '../../lib/Link';

import query from './alert.graphql';

import styles from './styles.module.scss';

interface Props {
  active: boolean;
}

const alertButton = (block: AlertButtonFragment) => {
  const buttonText = block.title!;
  const linkField = block.linkField?.[0];
  const link = urlFor(linkField);
  const props = isExternal(linkField)
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {};

  return (
    <Button type="solidOrange" className={styles.button} href={link} {...props}>
      {buttonText}
    </Button>
  );
};

const AlertBar = ({ active }: Props) => {
  const [{ data }] = useSiteQuery<AlertQuery>({ query });
  const alert = data?.entry?.alert?.[0];

  if (alert) {
    const button = alert?.children?.[0];
    return (
      <div className={cc([styles.alertBar, { [styles.active]: active }])}>
        <div className="container">
          <div className={styles.inner}>
            <div className={styles.content}>
              <p>{alert.title}</p>
              <p>{alert.description}</p>
            </div>
            <div>{button && alertButton(button)}</div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default AlertBar;
