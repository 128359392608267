import {captureException} from '@sentry/browser';
import * as Yup from "yup";
import CSRF from './Csrf';
import {getServerAddress} from "../lib/isBrowser";

declare global {
    interface Window {
        dataLayer: Array<any>;
    }
}

type ValueField = {
    value: string;
}

export default class FreeformHelper {
    public static async sendForm(formData: FormData, freeForm: FreeformFormInterface|undefined, eventText?: string) {
        if (freeForm) {
            const csrfToken = await CSRF.getCsrf();
            formData.append('CRAFT_CSRF_TOKEN', csrfToken || '');

            formData.set('handle', freeForm?.handle?.toString()!);

            const response = await this.submit(formData, freeForm);
            if (response.errors) {
                return response;
            }
            if (eventText && window && window.dataLayer) {
                window.dataLayer.push({event: eventText});
            }
            return response;
        }

        return {
            success: false
        }
    }

    private static async submit(formData: FormData, freeForm: FreeformFormInterface) {
        try {
            const server = getServerAddress();
            const contextRequestBody = new FormData();

            contextRequestBody.set('formHandle',freeForm?.handle?.toString()!);
            formData.set('formHash', freeForm?.hash?.toString()!);
            formData.set('action', 'freeform/submit');
            formData.set('freeform-action', 'submit');

            for (let hiddenField of this.getHiddenFields(freeForm)) {
                if (!formData.has(hiddenField!.handle)) {
                    formData.set(hiddenField!.handle, hiddenField!.value);
                }
            }

            return await fetch(`${server}/actions/formUtils/user/get-form-context`, {
                method: 'POST',
                credentials: 'include',
                body: contextRequestBody
            }).then(async (resp) => {
                const bodyContent = await resp.text();
                formData.set('freeform_payload',bodyContent);

                return fetch(`${server}/actions/freeform/submit`, {
                    body: formData,
                    method: "POST",
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                    },
                    credentials: 'include',
                }).then((resp) => {
                    if (!resp.ok) {
                        throw new Error('An unexpected error occurred')
                    }

                    return resp.json();
                }).then((json) => {
                    if (!json?.success) {
                        throw new Error('An unexpected Freeform error occurred')
                    }

                    return json;
                });
            });
        } catch (error) {
            captureException(error);
            return false;
        }
    }

    public static getHiddenFields(freeForm: FreeformFormInterface): any[] {
        return freeForm!.fields!.filter((field) => field!.type === 'hidden') || [];
    }

    // Generate the initialValues for Formik, for our Freeform object
    public static generateInitialValues(freeForm: FreeformFormInterface, overrideValues = {}) {
        const initialValues = {};

        // Honeypot field
        if (freeForm?.honeypot?.name) {
            initialValues[freeForm?.honeypot?.name] = '';
        }

        // Regular fields
        for (let page of freeForm!.pages || []) {
            for (let row of page!.rows || []) {
                for (let field of row!.fields || []) {
                    if (field!.handle && field!.handle!.length) {
                        const fieldVal = field as ValueField;
                        initialValues[field!.handle] = fieldVal!.value ?? '';
                    }
                }
            }
        }

        for (let key of Object.keys(overrideValues)) {
            if (overrideValues[key] === null) {
                delete overrideValues[key];
            }
        }

        return {
            ...initialValues,
            ...overrideValues
        };
    }

    // Generate the validation schema for Formik, for our Freeform object
    public static generateValidationSchema(freeForm: FreeformFormInterface) {
        const validationSchema = {};

        for (let page of freeForm!.pages || []) {
            for (let row of page!.rows || []) {
                for (let field of row!.fields || []) {
                    if (field!.handle && field!.handle!.length) {
                        switch (field!.type) {
                            case 'email':
                                validationSchema[field!.handle] = Yup.string().email();
                                break;

                            default:
                                validationSchema[field!.handle] = Yup.string();
                        }

                        if (field!.required) {
                            validationSchema[field!.handle] = validationSchema[field!.handle].required();
                        }
                    }
                }
            }
        }

        return Yup.object().shape(validationSchema);
    }
}
