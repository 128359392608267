import React, { useState } from 'react';

import { MultiSwitch } from '../index';

import CalculatorItem from './item';

import styles from './styles.module.scss';

interface Props {
  rooms: Maybe<RoomFragment>[];
}

const renderRoom = (room: Maybe<RoomFragment>) => {
  if (room) {
    return <CalculatorItem maximumRad={room?.maximumRad as number} defaultRad={room?.defaultRad as number} />;
  }
  return null;
};

const Calculator = ({ rooms }: Props) => {
  const [activeItem, setActiveItem] = useState(0);
  const filterItems: Array<MultiSwitchItem> = rooms.map((room, idx) => {
    return { title: room?.roomName || '', value: idx };
  });

  if (rooms.length) {
    return (
      <div className={styles.rooms}>
        <MultiSwitch
          items={filterItems}
          defaultActiveItem={filterItems?.[0]}
          onClick={(item: MultiSwitchItem) => setActiveItem((item?.value as number) || 0)}
          dark
        />
        {renderRoom(rooms?.[activeItem])}
      </div>
    );
  }
  return null;
};

export default Calculator;
