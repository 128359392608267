import React from 'react';
import scrollIntoView from 'scroll-into-view';

import { Button } from '../../index';
import { useIsMobile } from '../../../hooks';
import { trackEvent } from '../../../lib/GA';
import { isExternal, urlFor } from '../../../lib/Link';

import styles from './styles.module.scss';

interface Props {
  fragment: HighlightFragment;
}

const trackDownload = (type: string, url: string) => {
  switch (type) {
    case 'homeCare':
      trackEvent('PDF', 'Download', 'Home Care');
      break;
    case 'rentals':
      trackEvent('PDF', 'Download', 'Rentals');
      break;
    case 'nursingHomesApplicationBegin':
      trackEvent('Form', 'Begin', 'Nursing Homes');
      break;
    default:
      break;
  }
  window.open(url, '_blank');
};

const renderButton = (button: ButtonFragment | null | undefined, isMobile: boolean) => {
  const linkField = button?.linkField?.[0];

  if (button && linkField) {
    const props = button.tracking
      ? {
          onClick: () => trackDownload(button.tracking!, urlFor(linkField)),
        }
      : urlFor(linkField).indexOf('/#') === 0
      ? {
          onClick: () => scrollToSection(urlFor(linkField).replace('/#', ''), isMobile),
        }
      : isExternal(linkField) || button.toggle
      ? {
          href: urlFor(linkField),
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      : {
          href: urlFor(linkField),
        };

    return (
      <Button large {...props} type="solidOrange" className={styles.button}>
        {button.customTitle}
      </Button>
    );
  }

  return null;
};

const scrollToSection = (slug: string, isMobile: boolean) => {
  const isRowSection = slug.startsWith('row-section');
  const sectionPadding = isRowSection ? (isMobile ? 40 : 160) : isMobile ? 0 : 80;

  if (document.getElementById(slug)) {
    scrollIntoView(document.getElementById(slug)!, { align: { top: 0, topOffset: sectionPadding } });
  }
};

const HighlightBlock = ({ fragment }: Props) => {
  const [isMobile] = useIsMobile(768);

  if (fragment) {
    return (
      <div className="sectionWrapper section-blue">
        <div className="container">
          <div className={styles.highlightBlock}>
            <div>
              <h3>{fragment.title!}</h3>
              <p>{fragment.description}</p>
            </div>
            <div>{renderButton(fragment.children?.[0], isMobile)}</div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default HighlightBlock;
