import React from 'react';
import cc from 'classcat';

import { Button } from '../../../index';
import { useStore } from '../../../../hooks';

import Arrow from '../../../../images/icons/back.svg';

import styles from '../styles.module.scss';

interface Props {
  title: string;
  onClick: () => void;
}

const MicroHeader = ({ title, onClick }: Props) => {
  const { invertedLogo } = useStore('globals');

  return (
    <header className={styles.header}>
      <div className={cc({ [styles.skinny]: true, [styles.micro]: true })}>
        <div className={cc({ container: true, [styles.headerContainer]: true })}>
          <a href="/" className={styles.invertedLogo}>
            <img src={invertedLogo} alt="Amana Living" />
          </a>

          <div className={styles.title}>{title}</div>

          <Button onClick={onClick} className={styles.closeButton} type="whiteBorder">
            <Arrow /> Back to website
          </Button>
        </div>
      </div>
    </header>
  );
};

export default MicroHeader;
