import React from 'react';
import cc from 'classcat';

import PinIcon from '../../../images/icons/pin.svg';

import styles from './styles.module.scss';

interface Props {
  address: Maybe<AddressFragment> | null | undefined;
  inline?: boolean;
  className?: string;
}

const getGoogleMapLink = (fragment: Maybe<AddressFragment>) => {
  if (fragment?.address) {
    return `https://www.google.com/maps/search/?api=1&query=${fragment?.address}`;
  }
  return '';
};

const getAddressText = (fragment: Maybe<AddressFragment>, inline = false) => {
  if (
    fragment?.parts?.number &&
    fragment.parts.address &&
    fragment.parts.city &&
    fragment.parts.state &&
    fragment.parts.postcode
  ) {
    const str = `${fragment.parts.number} ${fragment.parts.address},${inline ? ' ' : '<br />'}${fragment.parts.city} ${
      fragment.parts.state
    } ${fragment.parts.postcode}`;
    return (
      <span dangerouslySetInnerHTML={{ __html: str.replace('Western Australia', 'WA').replace(', Australia', '') }} />
    );
  }

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: fragment?.address!.replace('Western Australia', 'WA').replace(', Australia', '') || '',
      }}
    />
  );
};

const Address = ({ address, inline, className }: Props) => {
  if (address && getGoogleMapLink(address)) {
    return (
      <p className={cc([styles.address, className])}>
        <a href={getGoogleMapLink(address)} target="_blank" rel="noopener noreferrer">
          <PinIcon />
          {getAddressText(address, inline)}
        </a>
      </p>
    );
  }
  return null;
};

export default Address;
