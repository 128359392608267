import React from 'react';

import { Button } from '../../../index';
import { useStore } from '../../../../hooks';

import styles from './styles.module.scss';

interface Props {
  onClick: () => void;
}

const SolutionsMicroHeader = ({ onClick }: Props) => {
  const { invertedLogo } = useStore('globals');
  return (
    <header className={styles.header}>
      <a href="/" className={styles.invertedLogo}>
        <img src={invertedLogo} alt="Solutions Logo" />
      </a>

      <Button onClick={onClick} className={styles.closeButton} type="orangeBorder">
        Close
      </Button>
    </header>
  );
};

export default SolutionsMicroHeader;
