
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"InformationGuide"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"informationGuides_informationGuides_Entry"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"uid"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"uri"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"postDate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"heroImage"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Asset"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"topic"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TopicCategory"},"directives":[]}]}}]}}],"loc":{"start":0,"end":278}};
    doc.loc.source = {"body":"#import \"@/graphql/Asset.graphql\"\n#import \"@/graphql/TopicsCategory.graphql\"\n\nfragment InformationGuide on informationGuides_informationGuides_Entry {\n  __typename\n  uid\n  uri\n  postDate\n  title\n  description\n  heroImage {\n    ...Asset\n  }\n\n  topic {\n    ...TopicCategory\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("@/graphql/Asset.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("@/graphql/TopicsCategory.graphql").definitions));


      module.exports = doc;
    
