import React, { useState } from 'react';
import { useFormikContext } from 'formik';

import { useIsMobile } from '../../../../../hooks';

import { FileField } from '../index';

import formStyles from '../../nursingHomeForm.module.scss';
import styles from './prereqField.module.scss';

interface Props {
  label: string;
  name: string;
  text?: string;
  uploadText?: string;
}

export const PrereqField = ({ label, name, text = '', uploadText }: Props) => {
  const { values } = useFormikContext<NursingHomeApplicationInterface>();
  const [isMobile] = useIsMobile(1000);
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div className={styles.prereq}>
      {!isMobile && <div className={`${styles.checkbox} ${values?.[`${name}_url`] ? styles.checked : ''}`} />}
      <div className={styles.prereqDetails}>
        <div className={styles.titleWrapper} onClick={() => setShowDropdown(!showDropdown)} aria-hidden>
          <label className={styles.prereqTitle}>{label}</label>
          {isMobile && <div className={`${styles.checkbox} ${values?.[`${name}_url`] ? styles.checked : ''}`} />}
        </div>
        {(!isMobile || showDropdown) && (
          <div className={isMobile ? formStyles.fadeIn : ''}>
            <div className={styles.prereqText} dangerouslySetInnerHTML={{ __html: text }} />
            <FileField label={uploadText || label} name={name} showNotAttached />
          </div>
        )}
      </div>
    </div>
  );
};

export default PrereqField;
