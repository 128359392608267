import React, { useCallback, useEffect, useState } from 'react';

import { Slider } from '../index';
import { useStore } from '../../hooks';
import { getPriceFormat } from '../../lib/FunctionLib';

import styles from './styles.module.scss';

interface Props {
  maximumRad: number;
  defaultRad: number;
}

const calculateDap = (maxRad: number, value: number, mpir: number) => {
  const dapValue = (value * maxRad) / 100;
  const dap = ((maxRad - dapValue) * mpir) / 36500;
  return getPriceFormat(dap, 2);
};

const CalculatorItem = ({ maximumRad, defaultRad }: Props) => {
  const { mpir } = useStore('globals');

  const initValue = Math.floor((defaultRad / maximumRad) * 100);
  const [value, setValue] = useState(initValue);
  const [dap, setDap] = useState(calculateDap(maximumRad, initValue, mpir));
  const maxDap = calculateDap(maximumRad, 0, mpir);

  useEffect(() => setDap(calculateDap(maximumRad!, initValue, mpir)), [mpir, maximumRad, initValue]);

  const updateValues = useCallback(
    (newValues: readonly number[]) => {
      const newValue = Math.floor(newValues[0]);
      setValue(newValue);
      setDap(calculateDap(maximumRad!, newValue, mpir));
    },
    [mpir, maximumRad],
  );

  return (
    <div className={styles.room}>
      <div className={styles.text}>
        <p className={styles.title}>Refundable Accommodation Deposit (RAD)</p>
        <p className={styles.price}>{getPriceFormat((value * maximumRad) / 100)}</p>
        <div className={styles.slider}>
          <Slider domain={[0, 100]} values={[initValue]} onUpdate={updateValues} onChange={updateValues} />
        </div>
        <p className={styles.max}>Maximum RAD is {getPriceFormat(maximumRad)}</p>
      </div>
      <div className={styles.box}>
        <p className={styles.title}>Daily Accommodation Payment (DAP)</p>
        <p className={styles.price}>
          {dap}
          <span>{` per day`}</span>
        </p>
        <p className={styles.max}>{`Maximum DAP is ${maxDap}`}</p>
      </div>
      <div className={styles.text}>
        <p className={styles.private}>* with private ensuite</p>
      </div>
    </div>
  );
};

export default CalculatorItem;
