import React from 'react';
import { default as ReactModal } from 'react-modal';

import MicroHeader from '../layout/Header/MicroHeader';
import SolutionsMicroHeader from '../layout/Header/SolutionsMicroHeader';

import styles from './styles.module.scss';

if (process.env.NODE_ENV !== 'test' && typeof document !== 'undefined') {
  const appElement = document.querySelector<HTMLElement>('body');
  if (appElement) {
    ReactModal.setAppElement(appElement);
  }
}

interface Props {
  onRequestClose: () => void;
  children: React.ReactNode;
  isOpen: boolean;
  title?: string;
  overrideTitle?: string;
  solutionsModal?: boolean;
}

const Modal = ({ onRequestClose, children, isOpen, title, overrideTitle, solutionsModal }: Props) => {
  const formTitle = overrideTitle || (title ? `Enquire about ${title}` : 'Enquire');
  function afterOpenModal() {
    window?.scrollTo(0, 0);
  }

  return (
    <ReactModal
      onRequestClose={onRequestClose}
      onAfterOpen={afterOpenModal}
      isOpen={isOpen}
      className={{
        base: styles.modal,
        afterOpen: '',
        beforeClose: '',
      }}
      overlayClassName={{
        base: styles.modalOverlay,
        afterOpen: '',
        beforeClose: '',
      }}
    >
      {solutionsModal ? (
        <SolutionsMicroHeader onClick={onRequestClose} />
      ) : (
        <MicroHeader onClick={onRequestClose} title={formTitle} />
      )}
      <div className="container">
        <div className={styles.content}>{children}</div>
      </div>
    </ReactModal>
  );
};

export default Modal;
