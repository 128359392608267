import React, { useState } from 'react';
import cc from 'classcat';
import Spinner from '@atlaskit/spinner';
import { captureException, setExtra } from '@sentry/core';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { Button } from '../../index';
import FormikField from '../FormikField';
import { Heading } from '../Wizard';
import { trackEvent } from '../../../lib/GA';
import FreeformHelper from '../../../lib/FreeformHelper';

import styles from './styles.module.scss';
import { useStore } from '../../../hooks';

interface EventFormValues {
  fullName: string;
  phone: string;
  email: string;
  adults: number;
  childrens: number;
  freeform_form_handle: string;
}

interface Props {
  eventName: string;
}

const EventForm = ({ eventName }: Props) => {
  const globals = useStore('globals');
  const initialValues: EventFormValues = {
    fullName: '',
    phone: '',
    email: '',
    adults: 0,
    childrens: 0,
    freeform_form_handle: ''
  };

  const [submitted, setSubmitted] = useState(false);
  const [errored, setErrored] = useState(false);

  const formHandle = 'eventForm';
  const freeForm = globals?.freeform?.find((form) => form.handle === formHandle);

  async function handleSubmit(values: EventFormValues, formikHelpers: FormikHelpers<EventFormValues>) {
    const formData = new FormData();
    // istanbul ignore next
    Object.keys(values).forEach((key) => formData.append(key, values[key] == null ? '' : values[key]));
    formData.append('handle', formHandle);
    formData.append('eventName', eventName);

    const response = await FreeformHelper.sendForm(formData, freeForm);
    if (response.success) {
      setSubmitted(true);
      trackEvent('Event Form', 'Successful Submission', document.title);
    } else {
      setErrored(true);
      setExtra('errors', response.errors);
      captureException('Event Form submission failed');
    }

    formikHelpers.setSubmitting(false);
  }

  function renderConfirmation() {
    return (
      <div className={styles.message}>
        <Heading>Thank you for your enquiry!</Heading>
        <p>Thanks! Your enquiry has been received and someone from our team will be in touch with you shortly.</p>
      </div>
    );
  }

  function renderError() {
    return (
      <div className={styles.message}>
        <h3>Something went wrong</h3>
        <p>An error occured while trying to submit your enquiry.</p>
        <p>Please try again or call us below.</p>
      </div>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={false}
      validationSchema={EventForm.validationSchema}
      onSubmit={handleSubmit}
      validateOnChange
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className={cc({ [styles.eventForm]: true, [styles.submitting]: isSubmitting })}>
          {submitted && renderConfirmation()}
          {errored && renderError()}
          {!submitted && !errored && (
            <>
              <Heading>To register your interest, please fill in the form below.</Heading>
              <div className="row mt-1">
                <div className="col-lg-6 mb-3">
                  <label htmlFor="fullName">Name</label>
                  <FormikField type="text" name="fullName" required placeholder="e.g. John" />
                </div>

                <div className="col-lg-6 mb-3">
                  <label htmlFor="phone">Phone</label>
                  <FormikField as="input" type="tel" name="phone" required placeholder="e.g. 0404 555 444" />
                </div>
              </div>

              <div className="row">
                <div className="col-12 mb-3">
                  <label htmlFor="email">Email</label>
                  <FormikField type="email" name="email" required placeholder="e.g. john@email.com" />
                </div>
              </div>

              <div className="row mt-1">
                <div className="col-lg-6 mb-3">
                  <label htmlFor="adults">Adults Attendees</label>
                  <FormikField type="number" name="adults" required placeholder="0" />
                </div>

                <div className="col-lg-6 mb-3">
                  <label htmlFor="childrens">Children Under 15 Attendees *</label>
                  <FormikField type="number" name="childrens" required placeholder="0" />
                </div>
              </div>

              <div>
                <div className="col-12 mt-3">
                  {
                    freeForm?.honeypot ? <input type={'hidden'} name={freeForm.honeypot?.name || ''} value={freeForm.honeypot?.value || ''}/> : null
                  }

                  <Button as="submit" disabled={isSubmitting} type="orangeBorder" large>
                    Submit
                  </Button>
                </div>
              </div>
            </>
          )}

          <div className={cc({ [styles.spinner]: true, [styles.visible]: isSubmitting })}>
            <Spinner size="xlarge" isCompleting={!isSubmitting} />
          </div>
        </form>
      )}
    </Formik>
  );
};

EventForm.validationSchema = Yup.object().shape({
  fullName: Yup.string().required(),
  phone: Yup.string().required(),
  email: Yup.string().email().required(),
  adults: Yup.number().required(),
  childrens: Yup.number().required(),
});

export default EventForm;
