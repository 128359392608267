import React from 'react';

import { useSiteQuery } from '../../hooks';

import Breadcrumbs from './index';
import query from './breadcrumbs.graphql';

interface Props {
  pageId: number;
  title: string;
  uri: string;
}

const BreadcrumbsPage = ({ pageId, title, uri }: Props) => {
  const [{ data }] = useSiteQuery<BreadcrumbsQuery>({ query, variables: { pageId } });
  const entries = data?.entries;

  if (entries?.length) {
    const breadcrumbs: BreadcrumbItem[] = entries
      .filter((b): b is BreadcrumbItemFragment => b !== null && b !== undefined)
      .map((b) => ({
        title: b.title!,
        uri: b.uri!.replace(/^([^\/])/, '/$1')!,
      }));
    breadcrumbs.push({
      title: title,
      uri: uri.replace(/^([^\/])/, '/$1'),
    });

    return <Breadcrumbs breadcrumbs={breadcrumbs} />;
  }

  return <Breadcrumbs breadcrumbs={[{ title, uri }]} />;
};

export default BreadcrumbsPage;
