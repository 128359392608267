import React, { useMemo } from 'react';
import { Field, useFormikContext } from 'formik';

import fieldStyles from '../fields.module.scss';
import styles from './inputField.module.scss';

interface Props {
  label: string;
  type: string;
  name: string;
  placeholder?: string;
  stretch?: boolean;
  overrideContainer?: string;
  max?: string;
  min?: string;
}

const InputField = ({ label, type, name, placeholder, stretch = false, overrideContainer = '', min, max }: Props) => {
  const { errors, touched } = useFormikContext<NursingHomeApplicationInterface>();
  const customMax = useMemo(() => {
    const today = new Date();
    if (max == 'now') return today.toISOString().split('T')[0];
    return max;
  }, [max]);

  const customMin = useMemo(() => {
    const today = new Date();
    if (min == 'now') return today.toISOString().split('T')[0];
    return min;
  }, [min]);

  return (
    <div className={`${styles.wrapper} ${stretch ? styles.stretch : ''} ${overrideContainer}`}>
      <div
        className={`${styles.container} ${(touched?.[name] && errors?.[name] && fieldStyles.errorInput) || ''} ${
          stretch ? styles.stretch : ''
        } ${overrideContainer}`}
      >
        <label className={styles.label}>{label}</label>
        {type == 'number' ? (
          <div className={styles.innerContainer}>
            <span className={styles.priceTag}>$</span>
            <Field
              className={styles.input}
              type={type}
              name={name}
              placeholder={placeholder}
              min={customMin}
              max={customMax}
            />
          </div>
        ) : (
          <Field
            className={styles.input}
            type={type}
            name={name}
            placeholder={placeholder}
            min={customMin}
            max={customMax}
          />
        )}
      </div>
      {touched?.[name] && errors?.[name] && (
        <div className={fieldStyles.errorBox}>
          <p className={fieldStyles.errorMsg}>
            {errors?.[name]?.includes('invalid') && errors?.[name]?.includes('dob')
              ? `Applicant must be aged 65 or over.`
              : `${label} is a required field`}
          </p>
        </div>
      )}
    </div>
  );
};

export default InputField;
