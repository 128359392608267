import React from 'react';
import cc from 'classcat';

import Image from '../layout/Image';

import styles from './styles.module.scss';

interface Props {
  photos?: Maybe<AssetFragment>[];
  title: string;
  children?: React.ReactNode;
}

const HeroStatic = ({ photos, title, children }: Props) => (
  <div className={cc([styles.hero, styles.heroWithoutCta, styles.heroStatic])}>
    <div className={cc(['container', styles.heroContainer])}>
      <div className="row">
        <div className={photos ? 'col-lg-6' : 'col-lg-12'}>{children}</div>
        {photos && (
          <div className="col-lg-6">
            <div className={styles.heroImage}>
              <Image {...photos[0]} alt={title} />
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default HeroStatic;
