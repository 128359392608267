import React, { useCallback, useState } from 'react';

import { Button, VideoModal } from '../../index';

import PlayIcon from '../../../images/icons/watch.svg';

import styles from './styles.module.scss';

interface Props {
  spotlight: SpotlightFragment;
}

const Spotlight = ({ spotlight }: Props) => {
  const [videoOpen, setVideoOpen] = useState(false);
  const photo = spotlight.photo?.[0]?.url;

  const toggleVideoModal = useCallback(() => {
    setVideoOpen(!videoOpen);
  }, [videoOpen]);

  function onVideoClick(e: React.MouseEvent<Element> | React.KeyboardEvent<Element>) {
    if (e.type !== 'keypress' || (e as React.KeyboardEvent).key === 'Enter') {
      toggleVideoModal();
    }
  }

  return (
    <>
      <div key={spotlight.uid!} className={styles.spotlight}>
        {photo && (
          <div className={styles.spotlightPhoto}>
            <img src={photo} alt={spotlight.title!} />
          </div>
        )}
        <div>
          <h4>{spotlight.title!}</h4>
          <p dangerouslySetInnerHTML={{ __html: spotlight.shortText! }} />
          <Button type="orangeBorder" className={styles.videoBtn} onClick={onVideoClick} large>
            <PlayIcon />
            Watch video
          </Button>
        </div>
      </div>
      {videoOpen && spotlight.videoUrl! && (
        <VideoModal isOpen={videoOpen} video={spotlight.videoUrl!} onRequestClose={toggleVideoModal} />
      )}
    </>
  );
};

export default Spotlight;
