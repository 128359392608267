import React from 'react';
import cc from 'classcat';
import scrollIntoView from 'scroll-into-view';

import Image from '../layout/Image';
import {useIsMobile, useStore} from '../../hooks';

import styles from './styles.module.scss';
import HeroCTA from "./cta";
import PhoneIcon from "@/images/icons/phone.svg";
import Button from "../buttons/Button";
import ContactButtons from "../ContactButtons";

interface Props {
  heroArea: HeroAreaFragment;
  parent?: string;
  headings?: HeadingAnchor[];
  children?: React.ReactNode;
}

const HeroWithoutCta = ({ heroArea, parent, headings, children }: Props) => {
  const [isMobile] = useIsMobile(768);
  const heroImage = heroArea.heroImage;
  const headline = heroArea.headline!;
  const retainSbsLayout = heroArea.retainSbsLayout;
  const ctaTheme = retainSbsLayout ? 'orange' : 'default';
  const globals = useStore('globals');

  const scrollToSection = (slug: string) => {
    const isRowSection = slug.startsWith('row-section');
    const sectionPadding = isRowSection ? (isMobile ? 40 : 160) : isMobile ? 0 : 80;

    if (document.getElementById(slug)) {
      scrollIntoView(document.getElementById(slug)!, { align: { top: 0, topOffset: sectionPadding } });
    }
  };

  const showButton = !!heroArea?.children?.find(( child) => {
    return child?.linkToSalesForm;
  });

  return (
      <div className={cc(['sectionWrapper', styles.hero, styles.heroWithoutCta])}>
        <div className={cc(['container', styles.heroContainer])}>
          <div className="row">
            <div className="col-lg-6">
              <div className="sectionHeading">{parent}</div>
              {heroArea.customTitle && (
                  <h1 className="heroH1" data-test-id="customTitle">
                    {heroArea.customTitle}
                  </h1>
              )}
              {heroArea.headline && (
                  <p className="heroHeadline" data-test-id="headline">
                    {heroArea.headline}
                  </p>
              )}

              <div className={styles.diviver} />

              <div className={styles.ctaHolder}>
                {retainSbsLayout && (
                    <div className={styles.retainSbsCtas}>
                      {heroArea?.children?.map(
                          (child) => child && <HeroCTA theme={ctaTheme} key={child.customTitle} cta={child} data-test-id={child.customTitle} name={parent ?? ''} />,
                      )}

                      { showButton ?
                        <Button href={`tel:${globals.phone}`} large type="orangeBorder" className={styles.button}>
                          <PhoneIcon />
                          {globals.phone}
                        </Button>
                      : null }

                    </div>
                )}

                {heroArea.toggle && (
                    <div className={styles.toggle}>
                      <p>On this page</p>
                      {headings?.map((heading) => (
                          <button key={heading.title} type="button" onClickCapture={() => scrollToSection(heading.slug)}>
                            {heading.title}
                          </button>
                      ))}
                    </div>
                )}

                {children}
              </div>
            </div>
            <div className="col-lg-6">
              <div className={styles.heroImage}>{heroImage && <Image {...heroImage[0]} alt={headline} />}</div>

              {
                heroArea.showContactButtons &&
                  <div className={styles.contactButtons}>
                    <ContactButtons />
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
  );
};

export default HeroWithoutCta;
