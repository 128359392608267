import React from 'react';
import cc from 'classcat';

import { Readmore, UnitProperties } from '../index';
import Image from '../layout/Image';

import thumbImage from '../../images/placeholder.gif';

import styles from './styles.module.scss';

interface Props {
  item: UnitFragment;
}

const UnitCard = ({ item }: Props) => {
  const title = item.title!;
  const images = item.photos;
  const location = item.retirementVillageLocation?.[0]?.title;

  return (
    <div className={cc([styles.cardItem, styles.isUnit])}>
      <div>
        <div className={styles.info}>
          <span>{location}</span>
          <h3>
            <a href={`/${item.uri}`}>{title}</a>
          </h3>
          <UnitProperties unit={item} inline />
        </div>
        <a className={styles.thumbImage} href={`/${item.uri}`} aria-label={title}>
          <div>
            {images ? <Image {...images[0]} alt={title} /> : <img loading="lazy" src={thumbImage} alt={title} />}
          </div>
        </a>
        <div className={styles.bottom}>
          <Readmore text="View package details" href={`/${item.uri}`} />
        </div>
      </div>
    </div>
  );
};

export default UnitCard;
