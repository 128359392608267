import { captureException } from '@sentry/browser';
import {getServerAddress} from "../lib/isBrowser";

export default class CSRF {
  static getCsrf = async () => {
    try {
      const session = await fetch(
        `${getServerAddress()}/api/users/session-info`,
        {
          headers: {
            Accept: 'application/json',
            'origin': window?.location?.host || 'server',
          },
          credentials: 'include',
        },
      ).then((response) => response.json());
      if (session?.csrfTokenValue) {
        return session.csrfTokenValue;
      }
      return '';
    } catch (e) {
      captureException(e);
      return '';
    }
  };
}
