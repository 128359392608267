import React from 'react';
import cc from 'classcat';
import scrollIntoView from 'scroll-into-view';

import { useIsMobile } from '../../hooks';

import PhotoIcon from '../../images/icons/photo.svg';

import ButtonBlock from '../PageContent/ButtonBlock';
import PhotoCarousel from '../PhotoCarousel';

import styles from './styles.module.scss';
import ContactButtons from "../ContactButtons";

interface Props {
  sectionHeading: string;
  title: string;
  headline: string;
  headings?: HeadingAnchor[];
  photos?: Maybe<AssetFragment>[];
  buttons?: Maybe<Array<Maybe<FeesButtonFragment>>>;
  onGalleryClick: (e: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>) => void;
  showContactButtons?: boolean;
}

const HeroLocation = ({ sectionHeading, title, headline, headings, photos, buttons, onGalleryClick, showContactButtons }: Props) => {
  const [isMobile] = useIsMobile(768);
  const num = photos?.length || 0;

  const scrollToSection = (slug: string) => {
    const sectionPadding = isMobile ? 40 : 160;

    if (document.getElementById(slug)) {
      scrollIntoView(document.getElementById(slug)!, { align: { top: 0, topOffset: sectionPadding } });
    }
  };

  return (
    <div className={cc(['sectionWrapper', styles.hero, styles.heroWithoutCta])}>
      <div className={cc(['container', styles.heroContainer])}>
        <div className="row">
          <div className="col-lg-6">
            <div className="sectionHeading">{sectionHeading}</div>
            <h1 className="heroH1" data-test-id="customTitle">
              {title}
            </h1>
            {photos && (
              <div className={styles.heroImageMobile}>
                <PhotoCarousel photos={photos.filter((p): p is AssetFragment => p !== null && p !== undefined)} />
                {num > 1 && (
                  <button className={styles.galleryBtn} onClick={onGalleryClick}>
                    <PhotoIcon />
                    Photos <span>{num}</span>
                  </button>
                )}
              </div>
            )}
            <p className="heroHeadline" data-test-id="headline">
              {headline}
            </p>
            {buttons?.map((button) => button && <ButtonBlock key={button.uid!} item={button} />)}
            <div className={styles.diviver} />
            <div className={styles.toggle}>
              {!!headings?.length && <p>On this page</p>}
              {headings?.map((heading) => (
                <button key={heading.title} type="button" onClickCapture={() => scrollToSection(heading.slug)}>
                  {heading.title}
                </button>
              ))}
            </div>
          </div>
          <div className="col-lg-6">
            <div className={styles.heroImage}>
              {photos && (
                <PhotoCarousel photos={photos.filter((p): p is AssetFragment => p !== null && p !== undefined)} />
              )}
              {num > 1 && (
                <button className={styles.galleryBtn} onClick={onGalleryClick}>
                  <PhotoIcon />
                  Photos <span>{num}</span>
                </button>
              )}
            </div>

            {
              showContactButtons &&
              <div className={cc([styles.contactButtons, styles.locationHeader])}>
                <ContactButtons />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroLocation;
