import React from 'react';

import { Readmore } from '../index';
import Image from '../layout/Image';

import PinIcon from '../../images/icons/pin.svg';
import thumbImage from '../../images/placeholder.gif';

import styles from './styles.module.scss';

export type LocationType = RetirementVillageLocationFragment | NursingHomeLocationFragment;

interface Props {
  item: LocationType;
}

const renderAddres = (item: LocationType) => {
  const isRental = item.__typename == 'retirementVillageLocations_retirementVillageLocations_Entry' && item?.rentalOnly;

  if (item.address?.address && item.address?.parts?.city) {
    const link = `https://www.google.com/maps/search/?api=1&query=${item.address?.address}`;
    return (
      <a href={link} className={styles.address} target="_blank" rel="noopener noreferrer">
        <PinIcon />
        {item.address?.parts?.city}
        {isRental == 'true' && <div className={styles.rentalTag}>Rental Only</div>}
      </a>
    );
  }
  return null;
};

const LocationCard = ({ item }: Props) => {
  const title = item.title!;
  const images = item.thumbnail?.length ? item.thumbnail : item.photos;

  return (
    <div className={styles.cardItem}>
      <div>
        <a className={styles.thumbImage} href={`/${item.uri}`} aria-label={title}>
          <div>
            {images ? <Image {...images[0]} alt={title} /> : <img loading="lazy" src={thumbImage} alt={title} />}
          </div>
        </a>
        <div className={styles.info}>
          <h3>
            <a href={`/${item.uri}`}>{title}</a>
          </h3>
          {renderAddres(item)}
        </div>
        <div className={styles.bottom}>
          <Readmore href={`/${item.uri}`} />
        </div>
      </div>
    </div>
  );
};

export default LocationCard;
