import React from 'react';
import cc from 'classcat';
import { observer } from 'mobx-react';

import { useStore } from '../../../hooks';

import styles from './styles.module.scss';

const CoursesTotal = () => {
  const store = useStore('coursesStore');

  return (
    <div className={cc([styles.total, 'sectionWrapper'])}>
      <div className="container">
        {!store.loading ? (
          <p>
            <span>{store.total}</span> courses available
          </p>
        ) : (
          <p>&nbsp;</p>
        )}
      </div>
    </div>
  );
};

export default observer(CoursesTotal);
