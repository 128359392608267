import React from 'react';

import { useIsMobile } from '../../../../../hooks';
import { nextOfKinValues } from '../../../../../lib/NursingFormikValues';

import { AddressField, InputField, NextStepField, RadioField, SelectField } from '../../Fields';

import formStyles from '../../nursingHomeForm.module.scss';

interface Props {
  setStep: (step: number) => void;
}

const NextOfKin = ({ setStep }: Props) => {
  const [isMobile] = useIsMobile(1000);

  return (
    <>
      {!isMobile && <p className={formStyles.headingL}>Next of kin/nominate representative</p>}
      <p className={formStyles.headingS}>Who is the next of kin or nominate representative?</p>
      <div className={formStyles.inputSection}>
        <SelectField
          label="Title"
          name="title_nok"
          options={[
            { value: '', text: 'Select Title', disabled: true },
            { value: 'Mr' },
            { value: 'Mrs' },
            { value: 'Miss' },
            { value: 'Ms' },
            { value: 'Dr' },
            { value: 'Other' },
          ]}
        />
        <InputField label="First name" type="text" name="firstName_nok" />
        <InputField label="Middle name/s" type="text" name="middleNames_nok" />
        <InputField label="Surname" type="text" name="lastName_nok" />
        <InputField label="Email" type="email" name="email_nok" />
        <InputField label="Phone" type="tel" name="phone_nok" />
        <AddressField label="Address" name="address_nok" />
        <SelectField
          label="Relationship"
          name="relationship_nok"
          options={[
            { value: '', text: 'Select Relationship', disabled: true },
            { value: 'Spouse' },
            { value: 'Partner' },
            { value: 'Power of Guardianship' },
            { value: 'Power of Attorney' },
            { value: 'Daughter' },
            { value: 'Son' },
            { value: 'Sibling' },
            { value: 'Grandchild' },
            { value: 'Other' },
          ]}
        />
        <RadioField label="Do you wish to be the primary contact?" name="primaryContact_nok" stretch />
        <NextStepField setStep={setStep} stepNum={3} fieldsToCheck={nextOfKinValues} />
      </div>
    </>
  );
};

export default NextOfKin;
