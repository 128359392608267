import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';

import { useIsMobile, useSiteQuery } from '../../../../../hooks';
import { accomodationValues } from '../../../../../lib/NursingFormikValues';

import { InputField, NextStepField, RadioField, SelectField } from '../../Fields';
import query from './accommodation.graphql';

import formStyles from '../../nursingHomeForm.module.scss';

interface NursingHomeSelect {
  value: Maybe<string> | undefined;
  text: Maybe<string> | undefined;
}

interface Props {
  setStep: (step: number) => void;
}

const Accommodation = ({ setStep }: Props) => {
  const [{ data }] = useSiteQuery<NursingHomesQuery>({ query });
  const { values } = useFormikContext<NursingHomeApplicationInterface>();
  const [isMobile] = useIsMobile(1000);

  const homeOptions = useMemo(() => {
    const results: Array<NursingHomeSelect> = [];
    data?.entries?.forEach((home) => {
      home && home?.title && results.push({ value: home.title, text: home.title });
    });
    return results;
  }, [data]);

  return (
    <>
      <div>
        {!isMobile && <p className={formStyles.headingL}>Accommodation requirements</p>}
        <p className={formStyles.headingS}>What type of accommodation do you require?</p>
        <RadioField name="accomRequirements" values={['High Care', 'Dementia secure', 'Low Care']} clearBg stretch />
        <div className={formStyles.divider} />
        <p className={formStyles.headingS}>Please indicate your preferred Amana Living hostel or nursing home:</p>
        <div className={formStyles.inputSection}>
          <SelectField
            label="First preference"
            name="homePreference_1"
            options={
              !!homeOptions
                ? [{ value: '', text: 'Select a home', disabled: true }, ...homeOptions]
                : [{ value: '', text: 'Loading homes', disabled: true }]
            }
          />
          <SelectField
            label="Second preference"
            name="homePreference_2"
            options={
              !!homeOptions
                ? [{ value: '', text: 'Select a home', disabled: true }, ...homeOptions]
                : [{ value: '', text: 'Loading homes', disabled: true }]
            }
          />
        </div>
        <RadioField label="Would you consider a shared room?" name="sharedRoom" stretch />
        <div className={formStyles.divider} />
        <p className={formStyles.headingS}>Timeline for considering moving into a Care Home</p>
        <SelectField
          label="How soon will you need care?"
          name="careTimeframe"
          options={[
            { value: '', text: 'Select a timeframe', disabled: true },
            { value: 'now', text: 'Immediately' },
            { value: '1 week', text: '1 week' },
            { value: '1 month', text: '1 month' },
            { value: '1 year', text: '1 year' },
          ]}
        />
        <div className={formStyles.divider} />
        <RadioField label="Is the resident currently in hospital or transition care?" name="isInCare" stretch />
        {values?.isInCare === 'yes' && (
          <InputField label="Name of hospital or transition care" type="text" name="careFacilityName" stretch />
        )}
      </div>
      <NextStepField setStep={setStep} stepNum={6} fieldsToCheck={accomodationValues} />
    </>
  );
};

export default Accommodation;
