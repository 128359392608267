import React, { useState } from 'react';
import cc from 'classcat';
import Spinner from '@atlaskit/spinner';
import { captureException, setExtra } from '@sentry/core';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { Button } from '../../index';
import FormikField from '../FormikField';
import FormikSelect from '../FormikSelect';
import { Heading } from '../Wizard';
import { useStore } from '../../../hooks';
import { trackEvent } from '../../../lib/GA';
import FreeformHelper from '../../../lib/FreeformHelper';

import styles from './styles.module.scss';

interface EnquireFormValues {
  fullName: string;
  phone: string;
  email: string;
  prefer: string;
  message: string;
  callTime: string;
  freeform_form_handle: string;
}

interface Props {
  handleClose: () => void;
  phoneSelected?: boolean;
}

const EnquireForm = ({ handleClose, phoneSelected }: Props) => {
  const globals = useStore('globals');
  const initialValues: EnquireFormValues = {
    fullName: '',
    phone: '',
    email: '',
    prefer: '',
    message: '',
    callTime: '',
    freeform_form_handle: ''
  };

  const [submitted, setSubmitted] = useState(false);
  const [errored, setErrored] = useState(false);
  const [isPhone, setIsPhone] = useState(phoneSelected);

  const formHandle = 'solutionsEnquireForm';
  const freeForm = globals?.freeform?.find((form) => form.handle === formHandle);

  async function handleSubmit(values: EnquireFormValues, formikHelpers: FormikHelpers<EnquireFormValues>) {
    const formData = new FormData();

    if (!isPhone) values.callTime = '';

    // istanbul ignore next
    Object.keys(values).forEach((key) => formData.append(key, values[key] == null ? '' : values[key]));

    formData.append('handle', formHandle);
    formData.append('origin', globals.siteName || 'Amana Living');

    const response = await FreeformHelper.sendForm(formData, freeForm);
    if (response.success) {
      setSubmitted(true);
      trackEvent('Enquire Form', 'Successful Submission', document.title);
    } else {
      setErrored(true);
      setExtra('errors', response.errors);
      captureException('Enquire Form submission failed');
    }

    formikHelpers.setSubmitting(false);
  }

  function handleRadioSelect(
    f: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    e: React.ChangeEvent<HTMLInputElement>,
  ) {
    f('prefer', e.target.value);
    setIsPhone(e.target.value === 'Phone');
  }

  function renderConfirmation() {
    return (
      <div className={styles.message}>
        <Heading>Thank you for your enquiry!</Heading>
        <p>Thanks! Your enquiry has been received and someone from our team will be in touch with you shortly.</p>
        <Button onClick={handleClose} type="orangeBorder" roundCorners>
          Back to the website
        </Button>
      </div>
    );
  }

  function renderError() {
    return (
      <div className={styles.message}>
        <h3>Something went wrong</h3>
        <p>An error occured while trying to submit your enquiry.</p>
        <p>Please try again or call us below.</p>
        <Button onClick={handleClose} type="orangeBorder" roundCorners>
          Back to the website
        </Button>
      </div>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={false}
      validationSchema={EnquireForm.validationSchema}
      onSubmit={handleSubmit}
      validateOnChange
    >
      {({ handleSubmit, isSubmitting, setFieldValue }) => (
        <form onSubmit={handleSubmit} className={cc({ [styles.enquireForm]: true, [styles.submitting]: isSubmitting })}>
          {submitted && renderConfirmation()}
          {errored && renderError()}
          {!submitted && !errored && (
            <>
              <Heading>Fill in your details below</Heading>
              <div className="row mt-1">
                <div className="col-lg-6 mb-3 customInput">
                  <label htmlFor="fullName">Name</label>
                  <FormikField type="text" name="fullName" required placeholder="e.g. John" />
                </div>

                <div className="col-lg-6 mb-3 customInput">
                  <label htmlFor="phone">Phone</label>
                  <FormikField as="input" type="tel" name="phone" required placeholder="e.g. 0404 555 444" />
                </div>
              </div>

              <div className="row">
                <div className="col-12 mb-3 customInput">
                  <label htmlFor="email">Email</label>
                  <FormikField type="email" name="email" required placeholder="e.g. john@email.com" />
                </div>

                <div className="col-12 col-md-6 mb-3 customInput">
                  <p className="label">Preferred contact method</p>
                  <div className={styles.radios}>
                    <div className={styles.radio}>
                      <input
                        id="perferEmail"
                        name="prefer"
                        type="radio"
                        value="Email"
                        onChange={(e) => handleRadioSelect(setFieldValue, e)}
                        required
                        checked={!isPhone}
                      />
                      <label htmlFor="perferEmail">Email</label>
                    </div>
                    <div className={styles.radio}>
                      <input
                        id="preferPhone"
                        name="prefer"
                        type="radio"
                        value="Phone"
                        onChange={(e) => handleRadioSelect(setFieldValue, e)}
                        required
                        checked={isPhone}
                      />
                      <label htmlFor="preferPhone">Phone</label>
                    </div>
                  </div>
                </div>

                {isPhone && (
                  <div className="col-12 col-md-6 mb-3 customInput">
                    <p className="label">What time should we call you?</p>
                    <FormikSelect name="callTime" {...(isPhone && { required: true })}>
                      <option value="">-Select-</option>
                      <option value="8am-12pm">8am - 12pm</option>
                      <option value="12pm-6pm">12pm - 6pm</option>
                      <option value="6pm-8pm">6pm - 8pm</option>
                    </FormikSelect>
                  </div>
                )}

                <div className="col-12 customInput">
                  <label htmlFor="message">Message</label>
                  <textarea
                    name="message"
                    onChange={(e) => setFieldValue('message', e.currentTarget.value)}
                    defaultValue={initialValues.message}
                    placeholder="What would you like to know?"
                    maxLength={800}
                  />
                </div>

                <div className="col-12 mt-3">
                  {
                    freeForm?.honeypot ? <input type={'hidden'} name={freeForm.honeypot?.name || ''} value={freeForm.honeypot?.value || ''}/> : null
                  }

                  <Button as="submit" disabled={isSubmitting} type="solidOrange" large roundCorners>
                    Send your enquiry
                  </Button>
                </div>
              </div>
            </>
          )}

          <div className={cc({ [styles.spinner]: true, [styles.visible]: isSubmitting })}>
            <Spinner size="xlarge" isCompleting={!isSubmitting} />
          </div>
        </form>
      )}
    </Formik>
  );
};

EnquireForm.validationSchema = Yup.object().shape({
  fullName: Yup.string().required(),
  phone: Yup.string().required(),
  email: Yup.string().email().required(),
  method: Yup.string(),
  message: Yup.string(),
  callTime: Yup.string(),
});

export default EnquireForm;
