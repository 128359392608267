import React from 'react';

import { MultiSwitch } from '../index';
import { useSiteQuery } from '../../hooks';

import query from './student.graphql';

import styles from './styles.module.scss';

interface Props {
  handleClick: (item: string) => void;
}

const StudentInfoMenu = ({ handleClick }: Props) => {
  const [{ data, error }] = useSiteQuery<StudentCategoriesQuery>({ query });

  if (error) {
    return null;
  }

  const categories = data?.categories;
  const defaultActiveItem: MultiSwitchItem = { title: 'All', value: '' };
  const filterItems: Array<MultiSwitchItem> = [defaultActiveItem];
  if (categories) {
    categories.map(
      (category: Maybe<StudentCategoryFragment>) =>
        category && filterItems.push({ title: category.title!, value: category.slug! }),
    );
  }

  return (
    <div className={styles.multiSwitchContainer}>
      <MultiSwitch
        items={filterItems}
        defaultActiveItem={defaultActiveItem}
        onClick={(item: MultiSwitchItem) => item && handleClick(item?.value as string)}
      />
      <select className={styles.select} onBlur={(e) => handleClick(e.target.value)}>
        {filterItems.map((item) => (
          <option key={item.title} value={item.value}>
            {item.title}
          </option>
        ))}
      </select>
    </div>
  );
};

export default StudentInfoMenu;
