import React from 'react';
import cc from 'classcat';

import { Button } from '../../index';
import { useStore } from '../../../hooks';

import Course from './course';
import Spotlight from './spotlight';

import styles from './styles.module.scss';

interface Props {
  item: TeamFragment;
}

const Team = ({ item }: Props) => {
  const { jobsListingUrl } = useStore('globals');

  if (item) {
    const photo = item.photo?.[0]?.url;
    const trainingCourses = item.trainingCourses || [];
    const spotlights = item.spotlights || [];
    const teamClasses = !!photo || trainingCourses.length > 0 ? 'col-lg-8 col-xl-7 teamBody' : 'col-12 teamBody';

    return (
      <div className={styles.team}>
        <div className="row">
          {(!!photo || trainingCourses.length > 0) && (
            <div className="col-lg-4 col-xl-5 pr-lg-1 pr-xl-5 pb-2">
              {photo && (
                <div className={styles.photo}>
                  <img src={photo} alt={item.title!} />
                </div>
              )}
              {trainingCourses.map((course) => course && <Course key={course.uid!} course={course} />)}
            </div>
          )}
          <div className={cc([teamClasses, styles.teamBody])}>
            <h2>{item.title!}</h2>
            <div dangerouslySetInnerHTML={{ __html: item.intro! }} />
            {spotlights.map((spotlight) => spotlight && <Spotlight key={spotlight.uid!} spotlight={spotlight} />)}
            <div dangerouslySetInnerHTML={{ __html: item.description! }} />
            <div className={styles.contactButton}>
              <Button type="solidOrange" href={jobsListingUrl} target="_blank" rel="noopener noreferrer">
                See current vacancies
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default Team;
