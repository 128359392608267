import React from 'react';

import { useSiteQuery } from '../../../hooks';
import { renderActivityItem } from '../../../lib/ContentRenderer';

import query from './activities.graphql';

const ActivitiesGlobals = () => {
  const [{ data }] = useSiteQuery<ActivitiesQuery>({ query });
  const activities = data?.activities;

  if (activities) {
    const str = activities
      .map((a) => a?.title)
      .join(', ')
      .toLowerCase();

    return (
      <div className="section">
        <div className="container">
          <div id="lifestyle-services" className="sectionHeading">
            Lifestyle & Services
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="max-width">
                <h3>Each Village offers a unique range of activities and services for you to enjoy.</h3>
                <p>Just some of the amenities across our Villages include: {str}</p>
                <p>&nbsp;</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">{activities?.map((activity) => activity && renderActivityItem(activity))}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default ActivitiesGlobals;
