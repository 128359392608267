import React from 'react';
import cc from 'classcat';

import styles from './styles.module.scss';

interface Props {
  items: Maybe<TeamFragment>[] | null | undefined;
  currentActive: TeamFragment | null;
  onClick: (item: TeamFragment) => void;
}

const renderIcon = (item: TeamFragment) => {
  const icon = item.icon?.[0]?.url;
  const photo = item.photo?.[0]?.url;

  if (icon) {
    return (
      <div className={styles.icon}>
        <img src={icon} alt={item.title!} />
      </div>
    );
  }

  if (photo) {
    return <div className={styles.photo} style={{ backgroundImage: `url(${photo})` }} />;
  }

  return <div className={styles.photo} />;
};

const TeamsCarousel = ({ items, currentActive, onClick }: Props) => {
  if (items) {
    return (
      <div className={styles.buttons}>
        {items.map(
          (item) =>
            item && (
              <button
                key={item.uid}
                onClick={() => onClick(item)}
                className={cc([styles.button, { [styles.active]: item.uid === currentActive?.uid }])}
              >
                {renderIcon(item)}
                {item.title}
              </button>
            ),
        )}
      </div>
    );
  }
  return null;
};

export default TeamsCarousel;
