import { differenceInYears } from 'date-fns';
import * as Yup from 'yup';

export const nursingHomeValues: NursingHomeApplicationInterface = {
  // About Step
  myAgedCare: '',
  myAgedCare_url: '',
  powerOfAttorney: '',
  powerOfAttorney_url: '',
  incomeAssessment: '',
  incomeAssessment_url: '',
  vaccinationAccessCondition: '',
  vaccinationAccessCondition_url: '',
  // Personal Details Step
  isCouple: '',
  title_1: '',
  firstName_1: '',
  middleNames_1: '',
  lastName_1: '',
  dob_1: '',
  gender_1: '',
  maritalStatus_1: '',
  spouseName: '',
  isAboriginalOrTorres_1: '',
  countryOfBirth_1: '',
  nationality_1: '',
  languageSpoken_1: '',
  interpreterRequired_1: '',
  email_1: '',
  phone_1: '',
  address_1: '',
  covidStatus_1: '',
  covidDoseDate_1_1: '',
  covidDoseDate_2_1: '',
  covidDoseDate_3_1: '',
  title_2: '',
  firstName_2: '',
  middleNames_2: '',
  lastName_2: '',
  dob_2: '',
  gender_2: '',
  maritalStatus_2: '',
  isAboriginalOrTorres_2: '',
  countryOfBirth_2: '',
  nationality_2: '',
  languageSpoken_2: '',
  interpreterRequired_2: '',
  email_2: '',
  phone_2: '',
  address_2: '',
  covidStatus_2: '',
  covidDoseDate_1_2: '',
  covidDoseDate_2_2: '',
  covidDoseDate_3_2: '',
  // Next Of Kin Step
  title_nok: '',
  firstName_nok: '',
  middleNames_nok: '',
  lastName_nok: '',
  email_nok: '',
  phone_nok: '',
  address_nok: '',
  relationship_nok: '',
  primaryContact_nok: '',
  // Power of Attorney Step
  powerOfAttorney_sameAs_nok: '',
  // Power of Guardianship Step
  powerOfGuardianship_sameAs_attorney: '',
  // optional file upload
  powerOfGuardianship: '',
  powerOfGuardianship_url: '',
  // Accommodation Step
  accomRequirements: '',
  homePreference_1: '',
  homePreference_2: '',
  sharedRoom: '',
  careTimeframe: '',
  isInCare: '',
  careFacilityName: '',
  // Centerlink Step
  referralCode: '',
  centerlinkId: '',
  veteranAffairsId: '',
  medicareId: '',
  medicareRef: '',
  medicareExpiry: '',
  diabeticId: '',
  policyId: '',
  insurerId: '',
  assetAssessment: '',
  assetAssessmentDate: '',
  // Home Ownership Step
  ownedHome2years: '',
  homeOccupied: '',
  sellHomeOnEntry: '',
  homeAddress: '',
  rentalPerWeek: '',
  // Finance Step
  combinedAssets: '',
  // assets
  homeValue_1: '',
  homeContentValue_1: '',
  otherPropValue_1: '',
  managedFundsValue_1: '',
  termDepositsValue_1: '',
  bankValue_1: '',
  superAnnuationValue_1: '',
  loansValue_1: '',
  artValue_1: '',
  vehicleValue_1: '',
  otherAssetsValue_1: '',
  funeralBondValue_1: '',
  homeValue_2: '',
  homeContentValue_2: '',
  otherPropValue_2: '',
  managedFundsValue_2: '',
  termDepositsValue_2: '',
  bankValue_2: '',
  superAnnuationValue_2: '',
  loansValue_2: '',
  artValue_2: '',
  vehicleValue_2: '',
  otherAssetsValue_2: '',
  funeralBondValue_2: '',
  // debts
  mortgage_1: '',
  debtsOwed_1: '',
  mortgage_2: '',
  debtsOwed_2: '',
  // gifts
  giftedAssets_1: '',
  giftedAssets_2: '',
  giftedAsset_1_1: '',
  dateGifted_1_1: '',
  giftValue_1_1: '',
  giftedAsset_2_1: '',
  dateGifted_2_1: '',
  giftValue_2_1: '',
  giftedAsset_3_1: '',
  dateGifted_3_1: '',
  giftValue_3_1: '',
  giftedAsset_4_1: '',
  dateGifted_4_1: '',
  giftValue_4_1: '',
  giftedAsset_1_2: '',
  dateGifted_1_2: '',
  giftValue_1_2: '',
  giftedAsset_2_2: '',
  dateGifted_2_2: '',
  giftValue_2_2: '',
  giftedAsset_3_2: '',
  dateGifted_3_2: '',
  giftValue_3_2: '',
  giftedAsset_4_2: '',
  dateGifted_4_2: '',
  giftValue_4_2: '',
  // income
  pensionType_1: '',
  agePension_1: '',
  veteranPension_1: '',
  overseasPension_1: '',
  otherPensions_1: '',
  incomeSupport_1: '',
  superannuation_1: '',
  propertyIncome_1: '',
  pensionType_2: '',
  agePension_2: '',
  veteranPension_2: '',
  overseasPension_2: '',
  otherPensions_2: '',
  incomeSupport_2: '',
  superannuation_2: '',
  propertyIncome_2: '',
  // Confirmation Step
  keepUpToDate: false,
  termsAccepted: false,
  // inital token URI
  tokenUri: '',
  emailTo: '',
  // section Status
  sectionStatus_0: '',
  sectionStatus_1: '',
  sectionStatus_2: '',
  sectionStatus_3: '',
  sectionStatus_4: '',
  sectionStatus_5: '',
  sectionStatus_6: '',
  sectionStatus_7: '',
  sectionStatus_8: '',
  freeform_form_handle: ''
};

export const NursingHomeFormSteps = [
  'About this application',
  "Resident's Personal Details",
  'Next of kin',
  'Enduring power of attorney',
  'Enduring power of guardianship',
  'Accommodation Requirements',
  'Centrelink & Health Fund Details',
  'Home Ownership Details',
  'Financial Statement',
];

export const validationSchema = Yup.object().shape({
  // About Step
  myAgedCare: Yup.string(),
  myAgedCare_url: Yup.string(),
  powerOfAttorney: Yup.string(),
  powerOfAttorney_url: Yup.string(),
  incomeAssessment: Yup.string(),
  incomeAssessment_url: Yup.string(),
  vaccinationAccessCondition: Yup.string(),
  vaccinationAccessCondition_url: Yup.string(),
  // Personal Details Step
  isCouple: Yup.string().required(),
  title_1: Yup.string().required(),
  firstName_1: Yup.string().required(),
  middleNames_1: Yup.string(),
  lastName_1: Yup.string().required(),
  dob_1: Yup.string()
    .required()
    .test('Applicant must be 65 or older', function (value?: string) {
      return (value && differenceInYears(new Date(), new Date(value)) >= 65) || false;
    }),
  gender_1: Yup.string().required(),
  maritalStatus_1: Yup.string().required(),
  spouseName: Yup.string().when('isCouple', {
    is: 'no',
    then: Yup.string().when('maritalStatus_1', {
      is: 'Married',
      then: Yup.string().required(),
    }),
  }),
  isAboriginalOrTorres_1: Yup.string().required(),
  countryOfBirth_1: Yup.string().required(),
  nationality_1: Yup.string().required(),
  languageSpoken_1: Yup.string().required(),
  interpreterRequired_1: Yup.string().required(),
  email_1: Yup.string().email().required(),
  phone_1: Yup.string(),
  address_1: Yup.string().required(),
  covidStatus_1: Yup.string().required(),
  covidDoseDate_1_1: Yup.string().when('covidStatus_1', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  covidDoseDate_2_1: Yup.string().when('covidStatus_1', {
    is: 'yes',
    then: Yup.string(),
  }),
  covidDoseDate_3_1: Yup.string().when('covidStatus_1', {
    is: 'yes',
    then: Yup.string(),
  }),
  title_2: Yup.string().when('isCouple', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  firstName_2: Yup.string().when('isCouple', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  middleNames_2: Yup.string().when('isCouple', {
    is: 'yes',
    then: Yup.string(),
  }),
  lastName_2: Yup.string().when('isCouple', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  dob_2: Yup.string().when('isCouple', {
    is: 'yes',
    then: Yup.string()
      .required()
      .test('Applicant must be 65 or older', function (value?: string) {
        return (value && differenceInYears(new Date(), new Date(value)) >= 65) || false;
      }),
  }),
  gender_2: Yup.string().when('isCouple', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  maritalStatus_2: Yup.string().when('isCouple', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  isAboriginalOrTorres_2: Yup.string().when('isCouple', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  countryOfBirth_2: Yup.string().when('isCouple', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  nationality_2: Yup.string().when('isCouple', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  languageSpoken_2: Yup.string().when('isCouple', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  interpreterRequired_2: Yup.string().when('isCouple', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  email_2: Yup.string().email().when('isCouple', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  phone_2: Yup.string().when('isCouple', {
    is: 'yes',
    then: Yup.string(),
  }),
  address_2: Yup.string().when('isCouple', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  covidStatus_2: Yup.string().when('isCouple', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  covidDoseDate_1_2: Yup.string().when('isCouple', {
    is: 'yes',
    then: Yup.string().when('covidStatus_2', {
      is: 'yes',
      then: Yup.string().required(),
    }),
  }),
  covidDoseDate_2_2: Yup.string().when('isCouple', {
    is: 'yes',
    then: Yup.string().when('covidStatus_2', {
      is: 'yes',
      then: Yup.string(),
    }),
  }),
  covidDoseDate_3_2: Yup.string().when('isCouple', {
    is: 'yes',
    then: Yup.string().when('covidStatus_2', {
      is: 'yes',
      then: Yup.string(),
    }),
  }),
  // Next Of Kin Step
  title_nok: Yup.string().required(),
  firstName_nok: Yup.string().required(),
  middleNames_nok: Yup.string(),
  lastName_nok: Yup.string().required(),
  email_nok: Yup.string().email().required(),
  phone_nok: Yup.string(),
  address_nok: Yup.string().required(),
  relationship_nok: Yup.string().required(),
  primaryContact_nok: Yup.string().required(),
  // Power of Attorney Step
  powerOfAttorney_sameAs_nok: Yup.string().required(),
  // Power of Guardianship Step
  powerOfGuardianship_sameAs_attorney: Yup.string().required(),
  // optional file upload
  powerOfGuardianship: Yup.string(),
  powerOfGuardianship_url: Yup.string(),
  // Accommodation Step
  accomRequirements: Yup.string().required(),
  homePreference_1: Yup.string().required(),
  homePreference_2: Yup.string().required(),
  sharedRoom: Yup.string().required(),
  careTimeframe: Yup.string().required(),
  isInCare: Yup.string().required(),
  careFacilityName: Yup.string().when('isInCare', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  // Centerlink Step
  referralCode: Yup.string().required(),
  centerlinkId: Yup.string().required(),
  veteranAffairsId: Yup.string(),
  medicareId: Yup.string().required(),
  medicareRef: Yup.string().required(),
  medicareExpiry: Yup.string().required(),
  diabeticId: Yup.string(),
  policyId: Yup.string(),
  insurerId: Yup.string(),
  assetAssessment: Yup.string().required(),
  assetAssessmentDate: Yup.string().when('assetAssessment', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  // Home Ownership Step
  ownedHome2years: Yup.string().required(),
  homeOccupied: Yup.string().required(),
  sellHomeOnEntry: Yup.string().required(),
  homeAddress: Yup.string().required(),
  rentalPerWeek: Yup.string(),
  // Finance Step
  combinedAssets: Yup.string().when('isCouple', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  // assets
  homeValue_1: Yup.string().required(),
  homeContentValue_1: Yup.string().required(),
  otherPropValue_1: Yup.string().required(),
  managedFundsValue_1: Yup.string().required(),
  termDepositsValue_1: Yup.string().required(),
  bankValue_1: Yup.string().required(),
  superAnnuationValue_1: Yup.string().required(),
  loansValue_1: Yup.string().required(),
  artValue_1: Yup.string().required(),
  vehicleValue_1: Yup.string().required(),
  otherAssetsValue_1: Yup.string().required(),
  funeralBondValue_1: Yup.string().required(),
  homeValue_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  homeContentValue_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  otherPropValue_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  managedFundsValue_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  termDepositsValue_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  bankValue_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  superAnnuationValue_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  loansValue_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  artValue_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  vehicleValue_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  otherAssetsValue_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  funeralBondValue_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  // debts
  mortgage_1: Yup.string().required(),
  debtsOwed_1: Yup.string().required(),
  mortgage_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  debtsOwed_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  // gifts
  giftedAssets_1: Yup.string().required(),
  giftedAssets_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  giftedAsset_1_1: Yup.string().when('giftedAssets_1', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  dateGifted_1_1: Yup.string().when('giftedAssets_1', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  giftValue_1_1: Yup.string().when('giftedAssets_1', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  giftedAsset_2_1: Yup.string(),
  dateGifted_2_1: Yup.string(),
  giftValue_2_1: Yup.string(),
  giftedAsset_3_1: Yup.string(),
  dateGifted_3_1: Yup.string(),
  giftValue_3_1: Yup.string(),
  giftedAsset_4_1: Yup.string(),
  dateGifted_4_1: Yup.string(),
  giftValue_4_1: Yup.string(),
  giftedAsset_1_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().when('giftedAssets_2', {
      is: 'yes',
      then: Yup.string().required(),
    }),
  }),
  dateGifted_1_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().when('giftedAssets_2', {
      is: 'yes',
      then: Yup.string().required(),
    }),
  }),
  giftValue_1_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().when('giftedAssets_2', {
      is: 'yes',
      then: Yup.string().required(),
    }),
  }),
  giftedAsset_2_2: Yup.string(),
  dateGifted_2_2: Yup.string(),
  giftValue_2_2: Yup.string(),
  giftedAsset_3_2: Yup.string(),
  dateGifted_3_2: Yup.string(),
  giftValue_3_2: Yup.string(),
  giftedAsset_4_2: Yup.string(),
  dateGifted_4_2: Yup.string(),
  giftValue_4_2: Yup.string(),
  // income
  pensionType_1: Yup.string().required(),
  agePension_1: Yup.string().required(),
  veteranPension_1: Yup.string().required(),
  overseasPension_1: Yup.string().required(),
  otherPensions_1: Yup.string().required(),
  incomeSupport_1: Yup.string().required(),
  superannuation_1: Yup.string().required(),
  propertyIncome_1: Yup.string().required(),
  pensionType_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  agePension_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  veteranPension_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  overseasPension_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  otherPensions_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  incomeSupport_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  superannuation_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  propertyIncome_2: Yup.string().when('combinedAssets', {
    is: 'no',
    then: Yup.string().required(),
  }),
  // Confirmation Step
  keepUpToDate: Yup.boolean(),
  termsAccepted: Yup.boolean().isTrue(),
  // inital token URI
  tokenUri: Yup.string(),
  emailTo: Yup.string().email(),
});

export const detailsSection = [
  'isCouple',
  'title_1',
  'firstName_1',
  'middleNames_1',
  'lastName_1',
  'dob_1',
  'gender_1',
  'maritalStatus_1',
  'spouseName',
  'isAboriginalOrTorres_1',
  'countryOfBirth_1',
  'nationality_1',
  'languageSpoken_1',
  'interpreterRequired_1',
  'email_1',
  'phone_1',
  'address_1',
  'covidStatus_1',
  'covidDoseDate_1_1',
  'covidDoseDate_2_1',
  'covidDoseDate_3_1',
  'title_2',
  'firstName_2',
  'middleNames_2',
  'lastName_2',
  'dob_2',
  'gender_2',
  'maritalStatus_2',
  'isAboriginalOrTorres_2',
  'countryOfBirth_2',
  'nationality_2',
  'languageSpoken_2',
  'interpreterRequired_2',
  'email_2',
  'phone_2',
  'address_2',
  'covidStatus_2',
  'covidDoseDate_1_2',
  'covidDoseDate_2_2',
  'covidDoseDate_3_2',
];

export const nextOfKinValues = [
  'title_nok',
  'firstName_nok',
  'middleNames_nok',
  'lastName_nok',
  'email_nok',
  'phone_nok',
  'address_nok',
  'relationship_nok',
  'primaryContact_nok',
];

export const accomodationValues = [
  'accomRequirements',
  'homePreference_1',
  'homePreference_2',
  'sharedRoom',
  'careTimeframe',
  'isInCare',
  'careFacilityName',
];

export const centerlinkValues = [
  'referralCode',
  'centerlinkId',
  'veteranAffairsId',
  'medicareId',
  'medicareRef',
  'medicareExpiry',
  'diabeticId',
  'policyId',
  'insurerId',
  'assetAssessment',
  'assetAssessmentDate',
];

export const homeOwnershipValues = [
  'ownedHome2years',
  'homeOccupied',
  'sellHomeOnEntry',
  'homeAddress',
  'rentalPerWeek',
];

export const financeValues = [
  'combinedAssets',
  'homeValue_1',
  'homeContentValue_1',
  'otherPropValue_1',
  'managedFundsValue_1',
  'termDepositsValue_1',
  'bankValue_1',
  'superAnnuationValue_1',
  'loansValue_1',
  'artValue_1',
  'vehicleValue_1',
  'otherAssetsValue_1',
  'funeralBondValue_1',
  'homeValue_2',
  'homeContentValue_2',
  'otherPropValue_2',
  'managedFundsValue_2',
  'termDepositsValue_2',
  'bankValue_2',
  'superAnnuationValue_2',
  'loansValue_2',
  'artValue_2',
  'vehicleValue_2',
  'otherAssetsValue_2',
  'funeralBondValue_2',
  'mortgage_1',
  'debtsOwed_1',
  'mortgage_2',
  'debtsOwed_2',
  'giftedAssets_1',
  'giftedAssets_2',
  'giftedAsset_1_1',
  'dateGifted_1_1',
  'giftValue_1_1',
  'giftedAsset_2_1',
  'dateGifted_2_1',
  'giftValue_2_1',
  'giftedAsset_3_1',
  'dateGifted_3_1',
  'giftValue_3_1',
  'giftedAsset_4_1',
  'dateGifted_4_1',
  'giftValue_4_1',
  'giftedAsset_1_2',
  'dateGifted_1_2',
  'giftValue_1_2',
  'giftedAsset_2_2',
  'dateGifted_2_2',
  'giftValue_2_2',
  'giftedAsset_3_2',
  'dateGifted_3_2',
  'giftValue_3_2',
  'giftedAsset_4_2',
  'dateGifted_4_2',
  'giftValue_4_2',
  'pensionType_1',
  'agePension_1',
  'veteranPension_1',
  'overseasPension_1',
  'otherPensions_1',
  'incomeSupport_1',
  'superannuation_1',
  'propertyIncome_1',
  'pensionType_2',
  'agePension_2',
  'veteranPension_2',
  'overseasPension_2',
  'otherPensions_2',
  'incomeSupport_2',
  'superannuation_2',
  'propertyIncome_2',
];

export const allFieldsBySection = [
  [],
  detailsSection,
  nextOfKinValues,
  ['powerOfAttorney_sameAs_nok'],
  ['powerOfGuardianship_sameAs_attorney'],
  accomodationValues,
  centerlinkValues,
  homeOwnershipValues,
  financeValues,
];
