import React, { useMemo, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useFormikContext } from 'formik';
import { range } from 'lodash';

import InputField from '../InputField';

import formStyles from '../../nursingHomeForm.module.scss';
import styles from './giftField.module.scss';

interface Props {
  financeId: string;
}

const GiftField = ({ financeId }: Props) => {
  const { values } = useFormikContext<NursingHomeApplicationInterface>();
  const [gifts, setGifts] = useState<number>(1);
  const maxGifts = 4;

  const total = useMemo(
    () =>
      range(gifts).reduce((acc, index) => acc + (values?.[`giftValue_${index + 1}_${financeId}`] || 0), 0) ||
      values?.[`giftValue_1_${financeId}`],
    [gifts, values, financeId],
  );

  const addGift = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    gifts < maxGifts && setGifts(gifts + 1);
  };

  const removeGift = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setGifts(Math.max(gifts - 1, 1));
  };

  useEffectOnce(() => {
    for (let y = 1; y < maxGifts; y++) {
      if (values?.[`giftedAsset_${y}_${financeId}`]) setGifts(y);
    }
  });

  return (
    <div className={`${formStyles.hide} ${values?.[`giftedAssets_${financeId}`] == 'yes' && formStyles.show}`}>
      {[...new Array(gifts)]?.map((_e, idx) =>
        idx == 0 ? (
          <GiftedAsset id={idx + 1} key={`Gift-${idx}`} keyValue={`Gift-${idx}`} financeId={financeId} />
        ) : (
          <span key={`Gift-${idx}`}>
            <div className={formStyles.thinDivider} />
            <GiftedAsset id={idx + 1} financeId={financeId} />
          </span>
        ),
      )}
      <button className={`${formStyles.activeBtn} ${styles.addBtn || ''}`} onClick={addGift}>
        Add Gift
      </button>
      <button className={`${formStyles.hollowBtn} ${styles.removeBtn || ''}`} onClick={removeGift}>
        Remove Gift
      </button>
      <div className={formStyles.mediumDivider} />
      <div className={styles.summary}>
        <p className={formStyles.headingS}>Total gifts</p>
        <p className={formStyles.headingS}>${total}</p>
      </div>
    </div>
  );
};

interface GiftedAssetProps {
  id: number;
  financeId: string;
  keyValue?: string;
}

const GiftedAsset = ({ id, financeId, keyValue }: GiftedAssetProps) => {
  return (
    <div className={`${styles.container} ${formStyles.fadeIn}`} key={keyValue}>
      <InputField label="What was the gifted asset" name={`giftedAsset_${id}_${financeId}`} type="text" />
      <InputField
        label="Date gifted"
        name={`dateGifted_${id}_${financeId}`}
        type="date"
        overrideContainer={styles.smallBtn}
      />
      <InputField
        label="Value of gift"
        name={`giftValue_${id}_${financeId}`}
        type="number"
        overrideContainer={styles.smallBtn}
      />
    </div>
  );
};

export default GiftField;
