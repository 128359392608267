import React from 'react';
import { FormikErrors, FormikTouched, useFormikContext } from 'formik';

import formStyles from '../../nursingHomeForm.module.scss';

interface Props {
  setStep: (step: number) => void;
  stepNum: number;
  fieldsToCheck?: Array<string>;
}

// Used in Form Progress Components
export const handleRequiredFields = (
  e: React.MouseEvent<HTMLButtonElement> | undefined,
  stepNum: number,
  currentStep: number | undefined,
  fieldsToCheck: Array<string> | undefined,
  errors: FormikErrors<NursingHomeApplicationInterface>,
  touched: FormikTouched<NursingHomeApplicationInterface>,
  setStep: (step: number) => void,
  validateField: (field: string) => void,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  setTouched: (touched: FormikTouched<NursingHomeApplicationInterface>, shouldValidate?: boolean | undefined) => void,
) => {
  e && e.preventDefault();
  if (fieldsToCheck !== [] && !fieldsToCheck) return;
  const requiredErrors = {};
  let valid = true;
  fieldsToCheck &&
    fieldsToCheck?.forEach((field) => {
      validateField(field);
      if (errors?.[field]) {
        requiredErrors[field] = true;
        valid = false;
      }
    });
  setTouched({ ...touched, ...requiredErrors });
  // makes errors only appear in section its relative to
  if (currentStep) {
    valid
      ? setFieldValue(`sectionStatus_${currentStep}`, 'done')
      : setFieldValue(`sectionStatus_${currentStep}`, 'error');
  } else {
    valid
      ? setFieldValue(`sectionStatus_${stepNum - 1}`, 'done')
      : setFieldValue(`sectionStatus_${stepNum - 1}`, 'error');
  }
  setStep(stepNum);
};

const NextStepField = ({ setStep, stepNum, fieldsToCheck }: Props) => {
  const {
    validateField,
    setTouched,
    setFieldValue,
    touched,
    errors,
  } = useFormikContext<NursingHomeApplicationInterface>();

  return (
    <button
      className={formStyles.nextStep}
      onClick={(e) =>
        handleRequiredFields(
          e,
          stepNum,
          undefined,
          fieldsToCheck,
          errors,
          touched,
          setStep,
          validateField,
          setFieldValue,
          setTouched,
        )
      }
    >
      {stepNum == 1 ? 'Begin application' : 'Next Step'}
    </button>
  );
};

export default NextStepField;
