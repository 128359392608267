import React from 'react';
import cc from 'classcat';
import slugify from 'slugify';

import {
  AccordionBlock,
  Articles,
  ButtonBlock,
  ChecklistBlock,
  CtaPanel,
  DropdownItem,
  EmailButton,
  FAQs,
  FeedbackButton,
  HighlightBlock,
  HomeCareServicesBlock,
  IconBlock,
  ImageBlock,
  InformationGuides,
  MainHomeCareServicesBlock,
  PhoneButton,
  SearchBlock,
  StepBlock,
  Testimonials,
  TextBlock,
  Videos,
  SalesFormCta
} from '../components';
import { isExternal, urlFor } from './Link';

import styles from './ContentRenderer.module.scss';

type PageContentBlock =
  | RowFragment
  | ArticlesBlockFragment
  | InformationGuidesBlockFragment
  | TestimonialBlockFragment
  | FaqsBlockFragment
  | HomeCareServicesFragment
  | HighlightFragment
  | VideosFragment
  | SalesFormCtaFragment;

export function renderMainMenu(
  item: MainMenuCategory,
  menuPosition: string,
  isActive: boolean,
): JSX.Element {
  if (item.linkField?.[0]) {
    const props = isExternal(item.linkField[0])
      ? {
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      : {};

    if (!!item?.dropdownLinks?.length) {
      return (
        <DropdownItem
          key={`${menuPosition}-${item.title}`}
          item={item}
          isActive={isActive}
          extras={props}
          menuPosition={menuPosition}
        />
      );
    }

    return (
      <React.Fragment key={`${menuPosition}-${item.title}`}>
        <a href={urlFor(item.linkField[0])} className={cc({ desktopOnly: item.title === 'Jobs', isActive })} {...props}>
          {item.title}
        </a>
        {item.title === 'Jobs' && (
          <a
            href={urlFor(item.linkField[0]).replace('jobs.amanaliving.com.au', 'jobs.amanaliving.com.au/mob')}
            className={cc({ mobileOnly: item.title === 'Jobs', isActive })}
            {...props}
          >
            {item.title}
          </a>
        )}
      </React.Fragment>
    );
  }

  return <span key={item.title}>{item.title}</span>;
}

export function renderCategoryLink(item: FooterLinksCategory | MainMenuCategory | TopNavigationCategory): JSX.Element {
  if (item.linkField?.[0]) {
    const props = isExternal(item.linkField[0])
      ? {
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      : {};

    return (
      <React.Fragment key={item.title}>
        <a href={urlFor(item.linkField[0])} className={cc({ desktopOnly: item.title === 'Jobs' })} {...props}>
          {item.title}
        </a>
        {item.title === 'Jobs' && (
          <a
            href={urlFor(item.linkField[0]).replace('jobs.amanaliving.com.au', 'jobs.amanaliving.com.au/mob')}
            className={cc({ mobileOnly: true })}
            {...props}
          >
            {item.title}
          </a>
        )}
      </React.Fragment>
    );
  }

  return <span key={item.title}>{item.title}</span>;
}

function renderSectionHeading(sectionHeading: SectionHeadingFragment, nested = false) {
  const slug = slugify(sectionHeading.title!).toLowerCase();
  return (
    <div
      key={sectionHeading.uid!}
      id={`row-section-${slug}`}
      className={cc([
        'sectionHeading',
        { 'col-12': !nested, asTitle: sectionHeading.useAsTitle },
        { whiteText: sectionHeading.whiteText },
      ])}
    >
      {sectionHeading.title}
    </div>
  );
}

export function renderColumnItem(item: Unboxed<ColumnFragment['children']> | RowFragment, childAsColumnClass = '') {
  if (!item) {
    return null;
  }

  switch (item.__typename) {
    case 'pageContent_row_BlockType':
      return renderRow(item as RowFragment, true);
    case 'pageContent_sectionHeading_BlockType':
      return renderSectionHeading(item, true);
    case 'pageContent_text_BlockType':
      return <TextBlock key={item.uid!} item={item} />;
    case 'pageContent_image_BlockType':
      return <ImageBlock key={item.uid!} item={item} />;
    case 'pageContent_icon_BlockType':
      return <IconBlock key={item.uid!} item={item} />;
    case 'pageContent_button_BlockType':
      return <ButtonBlock key={item.uid!} item={item} />;
    case 'pageContent_emailButton_BlockType':
      return <EmailButton key={item.uid!} overrideEmail={item.email!} />;
    case 'pageContent_phoneButton_BlockType':
      return <PhoneButton key={item.uid!} customPhone={item.phone!} />;
    case 'pageContent_feedbackButton_BlockType':
      return <FeedbackButton key={item.uid!} />;
    case 'pageContent_card_BlockType':
      // TODO
      return null;
    case 'pageContent_accordion_BlockType':
      return <AccordionBlock key={item.uid!} fragment={item} />;
    case 'pageContent_steps_BlockType':
      return (
        <div key={item.uid!}>
          {item.children?.map(
            (block, stepIndex: number) =>
              block && <StepBlock key={block.uid!} item={block} stepNumber={stepIndex + 1} />,
          )}
        </div>
      );
    case 'pageContent_checklist_BlockType':
      return (
        <React.Fragment key={item.uid!}>
          {item.children?.map(
            (checklist) =>
              checklist && (
                <ChecklistBlock key={checklist.uid!} text={checklist.description!} columnClass={childAsColumnClass} />
              ),
          )}
        </React.Fragment>
      );
    case 'pageContent_searchBox_BlockType':
      return <SearchBlock key={item.uid!} />;
    case 'pageContent_homeCareServices_BlockType':
      return <HomeCareServicesBlock key={item.uid!} fillColumn={!!item.fillColumn} />;
    case 'pageContent_ctaPanel_BlockType':
      return <CtaPanel key={item.uid!} headline={item.headline!} description={item.description!} inColumn />;
    default:
      // Should never happen
      console.warn(`failed to render column item with typename ${item['__typename']} - no render case exists`);
      return null;
  }
}

export function renderRowItem(item: Unboxed<RowFragment['children']>, columnClass: string, ctaPanelIncluded = false) {
  if (!item) {
    return null;
  }

  let isChecklistColumn = false;
  if (item.__typename === 'pageContent_column_BlockType') {
    const checklistMatch = JSON.stringify(item).match(/pageContent_checklist_BlockType(.*?)\"displayAsColumn\":true/);
    if (checklistMatch) {
      isChecklistColumn = true;
    }
  }

  switch (item.__typename) {
    case 'pageContent_sectionHeading_BlockType':
      return renderSectionHeading(item);
    case 'pageContent_column_BlockType':
      if (isChecklistColumn) {
        return (
          <React.Fragment key={item.uid!}>
            {item.children?.map((child: Unboxed<ColumnFragment['children']> | RowFragment) =>
              child ? renderColumnItem(child, columnClass) : null,
            )}
          </React.Fragment>
        );
      }

      // TODO: Remove explicit typing
      return (
        <div key={item.uid!} className={columnClass}>
          {item.children?.map((child: Unboxed<ColumnFragment['children']> | RowFragment) =>
            child ? renderColumnItem(child, '') : null,
          )}
        </div>
      );
    case 'pageContent_text_BlockType':
      return (
        <div key={item.uid!} className={ctaPanelIncluded ? 'col-lg-8 col-cta-included' : 'col-12'}>
          <TextBlock item={item} fullColumn />
        </div>
      );
    case 'pageContent_image_BlockType':
      return (
        <div key={item.uid!} className="col-12">
          <ImageBlock key={item.uid!} item={item} />
        </div>
      );
    case 'pageContent_ctaPanel_BlockType':
      return item.headline ? (
        <div key={item.uid!} className="col-lg-4">
          <CtaPanel headline={item.headline} description={item.description!} />
        </div>
      ) : null;
    default:
      // Should never happen
      console.warn(`failed to render row item with typename ${item['__typename']} - no render case exists`);
      return null;
  }
}

function renderRow(row: RowFragment, nested = false) {
  const children = row.children;
  if (!children) {
    return null;
  }

  const classNames = [styles.row];
  if ('align' in row && row.align) {
    classNames.push(styles[row.align]);
  }

  const background = {
    backgroundColor: 'transparent',
    backgroundImage: 'none',
    minHeight: '',
  };
  const backgroundWithHeight = {
    backgroundColor: 'transparent',
    backgroundImage: 'none',
    minHeight: 'inherit',
  };
  if ('backgroundColour' in row && row.backgroundColour) {
    background.backgroundColor = row.backgroundColour;
    backgroundWithHeight.backgroundColor = row.backgroundColour;
  }
  if ('backgroundImage' in row && row.backgroundImage?.[0]?.url) {
    classNames.push(styles.withBackground);
    if (row.dontTileOnMobile) {
      classNames.push(styles.dontTile);
    }
    if (row.backgroundHalfRow) {
      classNames.push(styles.halfRow);
    }
    const image = row.backgroundImage?.[0]?.url || 'none';
    background.backgroundImage = `url(${image})`;
    backgroundWithHeight.backgroundImage = `url(${image})`;
  }

  let mobileBackgroundClass = 'mobileBackground';
  if ('backgroundHeightDesktop' in row && row.backgroundHeightDesktop) {
    mobileBackgroundClass = 'mobileBackgroundFixed';
    classNames.push(styles.withBackgroundFixedHeight);
    backgroundWithHeight.minHeight = `${Number(row.backgroundHeightDesktop)}px`;
  }

  if ('backgroundMinHeight' in row && row.backgroundMinHeight) {
    background.minHeight = `${Number(row.backgroundMinHeight)}px`;
  }

  const columnClassNames: string[] = [];
  if ('maxColumns' in row && row.maxColumns && Number(row.maxColumns)) {
    columnClassNames.push(`col-lg-${12 / Number(row.maxColumns)}`);
    switch (Number(row.maxColumns)) {
      case 3:
        columnClassNames.push('pb-1');
        break;
      case 4:
        columnClassNames.push('col-md-6 mb-2 mb-lg-0');
        break;
      default:
        break;
    }
  } else {
    columnClassNames.push('col-12');
  }
  if ('minimumColumns' in row && row.minimumColumns && Number(row.minimumColumns) > 1) {
    columnClassNames.push(`col-${Math.ceil(12 / Number(row.minimumColumns))}`);
  }

  if ('columnBorders' in row && !!row.columnBorders) {
    columnClassNames.push('column-borders');
  }

  let ctaPanelIncluded = false;
  for (let i = 0; i < children.length; i++) {
    if (children[i]?.__typename === 'pageContent_ctaPanel_BlockType') {
      ctaPanelIncluded = true;
    }
  }

  const matches = JSON.stringify(children).match(/pageContent_homeCareServices_BlockType(.*?)\"fillColumn\":true}/);
  if (matches) {
    classNames.push(styles.homeCareServices);
    columnClassNames.push('fillColumn');
  }

  if ('flexibleColumns' in row && !!row.flexibleColumns) {
    return (
      <div key={row.uid!} className={cc(classNames)} style={background}>
        <div className="container">
          <div className="flexibleColumns">{children.map((child) => renderRowItem(child, '', ctaPanelIncluded))}</div>
        </div>
      </div>
    );
  }

  if (nested) {
    const nestedRowClasses = ['row', styles.row, styles.nested];
    if ('align' in row && row.align) {
      nestedRowClasses.push(styles[row.align]);
    }
    if ('childrenAsSlider' in row && row.childrenAsSlider) {
      nestedRowClasses.push(styles.childrenAsSlider);
    }
    if (row.fixedItemHeight) {
      nestedRowClasses.push(styles.fixedItemHeight);
    }

    return (
      <div key={row.uid!} className={cc(nestedRowClasses)} style={background}>
        {children.map((child) => renderRowItem(child, cc(columnClassNames), ctaPanelIncluded))}
      </div>
    );
  }

  if (row.copyrightAndCredit) {
    classNames.push(styles.copyrightAndCredit);
  }

  return (
    <div
      key={row.uid!}
      className={cc(classNames)}
      style={mobileBackgroundClass === 'mobileBackground' ? background : backgroundWithHeight}
      {...(row.shortText && { id: row.shortText })}
    >
      <div className="container">
        <div className="row rowRoot">
          {children.map((child) => renderRowItem(child, cc(columnClassNames), ctaPanelIncluded))}
        </div>
        {row.copyrightAndCredit && (
          <div className={styles.copyrightAndCredit}>
            <span>Copyright Amana Living 2021</span>
          </div>
        )}
      </div>
      {background.backgroundImage !== 'none' && !row.dontTileOnMobile && (
        <div className={mobileBackgroundClass} style={background}>
          <img src={row.backgroundImage?.[0]?.url || 'none'} alt="" />
        </div>
      )}
    </div>
  );
}

export function renderPageContent(item: PageContentBlock, isVillagePage = false, pageEntry: any) {
  if (!item) {
    return null;
  }

  switch (item.__typename) {
    case 'pageContent_row_BlockType':
      return isVillagePage ? null : renderRow((item as any) as RowFragment);
    case 'pageContent_highlightBlock_BlockType':
      return isVillagePage ? null : <HighlightBlock key={item.uid} fragment={item} />;
    case 'pageContent_videosBlock_BlockType':
      return <Videos key={item.uid} fragment={item} />;
    case 'pageContent_articlesBlock_BlockType':
      return <Articles key={item.uid} />;
    case 'pageContent_informationGuidesBlock_BlockType':
      return <InformationGuides key={item.uid} fragment={item} />;
    case 'pageContent_testimonialsBlock_BlockType':
      return <Testimonials key={item.uid} fragment={item} />;
    case 'pageContent_faqsBlock_BlockType':
      return <FAQs key={item.uid} fragment={item} />;
    case 'pageContent_homeCareServices_BlockType':
      return <MainHomeCareServicesBlock key={item.uid!} />;
    case 'pageContent_salesFormCta_BlockType':
      return <SalesFormCta key={item.uid!} title={item.customTitle!} shortText={item.shortText!} formTitle={pageEntry.title!} />;
    default:
      // TODO: should never happen
      console.warn(`failed to render element`);
      return null;
  }
}

export function renderCoursePageContent(item: PageContentBlock) {
  if (!item) {
    return null;
  }

  switch (item.__typename) {
    case 'pageContent_row_BlockType':
      return renderCourseRow((item as any) as RowFragment);
    default:
      // TODO: should never happen
      console.warn(`course page only render row`);
      return null;
  }
}

function renderCourseRow(row: RowFragment) {
  const children = row.children;
  if (!children) {
    return null;
  }

  return <div key={row.uid}>{children.map((child) => renderRowItem(child, ''))}</div>;
}

export function renderRowContent(item: PageContentBlock) {
  if (item?.__typename === 'pageContent_row_BlockType') {
    return renderRow((item as any) as RowFragment, false);
  }

  if (item?.__typename === 'pageContent_highlightBlock_BlockType') {
    return <HighlightBlock key={item.uid} fragment={item} />;
  }

  return null;
}

export function getHeadings(
  pageContent: Maybe<PageContentBlock>[] | null | undefined,
  homeCareCategories: Maybe<HomeCareCategoriesFragment>[] | null | undefined,
) {
  let headings: HeadingAnchor[] = [];
  if (pageContent) {
    for (let i = 0; i < pageContent.length; i++) {
      const row = pageContent[i];

      if (row?.__typename) {
        switch (row.__typename) {
          case 'pageContent_row_BlockType':
            const matches = JSON.stringify(row).match(/pageContent_sectionHeading_BlockType(.*?)\"title\":\"(.*?)\",/);
            if (matches && matches.length === 3) {
              const slug = slugify(matches[2]).toLowerCase();
              headings.push({ title: matches[2], slug: `row-section-${slug}` });
            }
            break;
          case 'pageContent_articlesBlock_BlockType':
            headings.push({ title: 'News and Events', slug: `news-and-events` });
            break;
          case 'pageContent_informationGuidesBlock_BlockType':
            const topic = row?.topic?.[0]?.title;
            headings.push({ title: `${topic} Information Guides`, slug: `information-guides` });
            break;
          case 'pageContent_faqsBlock_BlockType':
            headings.push({ title: 'Frequently Asked Questions', slug: `faqs` });
            break;
          case 'pageContent_homeCareServices_BlockType':
            if (homeCareCategories) {
              const categories = homeCareCategories.map((cate) => {
                return { title: cate?.title || '', slug: `${slugify(cate?.title || '').toLowerCase()}` };
              });
              headings = headings.concat(categories);
            }
            break;
          default:
            // TODO: should never happen
            break;
        }
      }
    }
  }

  return headings;
}

export function getBioHeadings(bios: Maybe<BioFragment>[] | null | undefined) {
  const headings: HeadingAnchor[] = [];
  if (bios) {
    for (let i = 0; i < bios.length; i++) {
      headings.push({
        title: `${bios[i]?.name || ''} ${bios[i]?.title || ''}`,
        slug: `bio-${slugify(bios[i]?.name || '').toLowerCase()}`,
      });
    }
  }

  return headings;
}

export function renderActivityItem(activity: ActivitiesFragment) {
  const title = activity?.title || undefined;
  const icon = activity?.icon?.[0]?.url;

  return (
    <div key={title} className="col-4 mb-2 mb-lg-4">
      <div className="iconBlock">{icon && <img loading="lazy" src={icon} alt={title} />}</div>
      <p className="service-label">{title}</p>
    </div>
  );
}

export function renderAmenityItem(amenity: AmenitiesFragment) {
  const title = amenity?.amenity?.[0]?.title || undefined;
  const icon = amenity?.amenity?.[0]?.icon?.[0]?.url;
  const distance = amenity?.distance;

  return (
    <div key={title} className="col-4 mb-2">
      <div className="iconBlock">{icon && <img loading="lazy" src={icon} alt={title} />}</div>
      <p className="service-label">{title}</p>
      <p className="service-label-light">{distance} km</p>
    </div>
  );
}
