import React from 'react';
import cc from 'classcat';

import { getUnitPriceFormat } from '../../../lib/FunctionLib';

import BathIcon from '../../../images/icons/bath.svg';
import BedIcon from '../../../images/icons/bed.svg';
import CarIcon from '../../../images/icons/car.svg';

import styles from './styles.module.scss';

interface Props {
  unit: UnitFragment;
  inline?: boolean;
}

const UnitProperties = ({ unit, inline }: Props) => {
  if (unit) {
    return (
      <div className={cc([styles.unit, { [styles.inline]: inline }])}>
        <div className={styles.properties}>
          {!!unit.numberOfBeds! && (
            <div>
              <span>{unit.numberOfBeds!}</span> <BedIcon />
            </div>
          )}
          {!!unit.numberOfBaths! && (
            <div>
              <span>{unit.numberOfBaths!}</span> <BathIcon />
            </div>
          )}
          {!!unit.numberOfCarBays && (
            <div>
              <span>{unit.numberOfCarBays!}</span> <CarIcon />
            </div>
          )}
        </div>
        <span className={styles.price}>{getUnitPriceFormat(unit.price!)}</span>
      </div>
    );
  }
  return null;
};

export default UnitProperties;
