import React from 'react';
import cc from 'classcat';

import { EmailButton, PhoneButton } from '../index';

import PhoneIcon from '../../images/icons/phone.svg';

import styles from './styles.module.scss';

interface Props {
  desktop?: boolean;
  mobile?: boolean;
  children?: React.ReactNode;
  customPhoneNumber?: string;
  customEmailDesktop?: JSX.Element;
  customEmailMobile?: JSX.Element;
}

const ContactButtons = ({
  desktop,
  mobile,
  children,
  customPhoneNumber,
  customEmailDesktop,
  customEmailMobile,
}: Props) => (
  <>
    <div
      className={cc([
        'row',
        'desktopOnly',
        { [styles.desktopButtons]: true, [styles.hideDesktop]: !desktop && mobile },
      ])}
    >
      {children && <div className="container">{children}</div>}
      <div className="col-6 pl-0">
        <PhoneButton customPhone={customPhoneNumber} className={'text-nowrap'} />
      </div>
      <div className="col-6 pr-0">{customEmailDesktop ? customEmailDesktop : <EmailButton />}</div>
    </div>
    <div
      className={cc(['row', 'mobileOnly', { [styles.mobileButtons]: true, [styles.hideMobile]: !mobile && desktop }])}
    >
      {children && <div className="container">{children}</div>}
      <div className="col-6">
        <PhoneButton customPhone={customPhoneNumber}>
          <PhoneIcon /> Call us
        </PhoneButton>
      </div>
      <div className="col-6">{customEmailMobile ? customEmailMobile : <EmailButton text="Email us" />}</div>
    </div>
  </>
);

export default ContactButtons;
