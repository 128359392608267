import React, { useCallback, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useWindowSize } from 'react-use';

import useStore from '../../hooks/useStore';

import styles from './styles.module.scss';

interface Props {
  category: string;
  testimonials: Maybe<Array<Maybe<Pick<TestimonialsTestimonialsEntry, 'uid' | 'title' | 'description'>>>>;
}

const TestimonialsMobile = ({ category, testimonials }: Props) => {
  const { siteName } = useStore('globals');

  const [commentExpanded, setCommentExpanded] = useState({});
  const [commentHeights, setCommentHeights] = useState({});
  const windowSize = useWindowSize();

  const commentRef = useCallback((node: Nullable<HTMLDivElement>) => {
    if (node) {
      setCommentHeights((current) => ({ ...current, [node.dataset.id || 'no-id']: node.clientHeight }));
    }
  }, []);

  const commentHeight = windowSize.width < 450 ? 200 : 120;

  if (testimonials) {
    return (
      <div className="container">
        <div className={styles.carouselMobile}>
          {testimonials.map((t, i) => (
            <React.Fragment key={t?.uid}>
              <div className={styles.testimonial}>
                <AnimateHeight duration={200} height={commentExpanded[i] ? 'auto' : commentHeight}>
                  <div data-id={i} ref={commentRef}>
                    {t?.description}
                  </div>
                </AnimateHeight>
                {commentHeights[i] > commentHeight && (
                  <button onClick={() => setCommentExpanded({ ...commentExpanded, [i]: !commentExpanded[i] })}>
                    {commentExpanded[i] ? 'collapse' : 'read more'}
                  </button>
                )}

                <div className={styles.attribution}>
                  <strong>{t?.title}</strong>
                  <div>
                    On {siteName.replace('Amana Living Aged Care Services', 'Amana Living')}’s {category}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

export default TestimonialsMobile;
