import React from 'react';

type Props = Maybe<Partial<AssetFragment>> & {
  mobileWebp?: Maybe<string>;
  alt?: string;
  'data-testid'?: string;
};

const Image = (props: Props) => {
  /* istanbul ignore next */
  if (!props) {
    /* istanbul ignore next */
    return null;
  }

  const { webp, mobileWebp, jpg, width, height, title, alt, 'data-testid': testid } = props;
  return (
    <picture data-testid={testid}>
      {mobileWebp && <source srcSet={mobileWebp} type="image/webp" media="(max-width: 768px)" />}
      {webp && <source srcSet={webp} type="image/webp" />}
      <img loading="lazy" src={jpg!} alt={alt || title!} width={width!} height={height!} className="image" />
    </picture>
  );
};

export default Image;
