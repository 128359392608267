import React, { useState } from 'react';

import {Button, Modal, Questionnaire} from '../index';
import {getQuestionnaireId, isQuestionnaire, LinkField, urlFor} from '../../lib/Link';

import styles from './styles.module.scss';
import ContactForm from "../forms/ContactForm";

interface Props {
  cta: HeroCtaFragment;
  theme?: string;
  name: string;
}

const HeroCTA = ({ cta, theme, name }: Props) => {
  const linkField = cta?.linkField?.[0] as LinkField;
  const linkToSalesForm = cta?.linkToSalesForm;
  const iconUrl = cta.icon?.[0]?.url;
  const [questionnaireFormOpen, setQuestionnaireForm] = useState(false);
  const ctaTheme = theme == 'orange' ? styles.ctaOrange : styles.cta;
  const [enquireModal, setEnquireModal] = useState<boolean>(false);

  const toggleQuestionnaireForm = (nextValue = !questionnaireFormOpen) => {
    setQuestionnaireForm(nextValue);
  };


  if (linkField) {
    if (linkToSalesForm) {
        return (
            <>
                <Button onClick={() => setEnquireModal(!enquireModal)} className={ctaTheme}>
                    <div className={styles.icon}>{iconUrl && <img loading="lazy" src={iconUrl} alt={cta.customTitle!} />}</div>
                    {cta.customTitle}
                </Button>
                <Modal isOpen={enquireModal} onRequestClose={() => setEnquireModal(false)} title={name}>
                    <ContactForm section={'Sales Form'} title={name}/>
                </Modal>
            </>
        );
    }

    if (isQuestionnaire(linkField)) {
      return (
        <>
          <button onClick={() => toggleQuestionnaireForm(true)} className={ctaTheme}>
            <div className={styles.icon}>{iconUrl && <img loading="lazy" src={iconUrl} alt={cta.customTitle!} />}</div>
            <p>{cta.customTitle}</p>
          </button>
          <Questionnaire
            questionId={getQuestionnaireId(linkField)}
            isOpen={questionnaireFormOpen}
            onClose={() => toggleQuestionnaireForm(false)}
            initValue={cta.customTitle!}
          />
        </>
      );
    }

    return (
      <a href={urlFor(linkField)} className={ctaTheme}>
        <div className={styles.icon}>{iconUrl && <img loading="lazy" src={iconUrl} alt={cta.customTitle!} />}</div>
        <p>{cta.customTitle}</p>
      </a>
    );
  }

  return null;
};

export default HeroCTA;
