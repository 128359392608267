import React from 'react';
import { captureException } from '@sentry/browser';
import { useQuery } from 'urql';

import query from '../../../graphql/HomeCareServices.graphql';

import Services from './services';

const MainHomeCareServicesBlock = () => {
  const [{ data, error }] = useQuery<HomeCareServicesQuery>({ query });

  if (error) {
    captureException(error);
    return null;
  }

  return (
    <div>
      {data?.entries?.map((cate, idx) => cate && <Services key={cate?.title} entry={cate as HomeCareServicesHomeCareServicesEntry} odd={!!(idx % 2)} />)}
    </div>
  );
};

export default MainHomeCareServicesBlock;
