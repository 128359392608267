import React from 'react';

import styles from './styles.module.scss';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  name?: string;
  type?: string;
  pattern?: string;
  title?: string;
  initialValue?: string | number;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
}

interface State {
  value: string | number;
}

export default class Input extends React.Component<Props, State> {
  ref: { current: null | HTMLInputElement };

  static defaultProps = {
    initialValue: '',
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      value: props.initialValue || '',
    };

    this.ref = React.createRef();
  }

  componentDidMount() {
    if (this.props.type === 'number') {
      document.addEventListener('keydown', (e: KeyboardEvent) => this.closeKeyboard(e));
    }
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const toState = e.currentTarget.value;
    this.setState({ value: toState });

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  closeKeyboard = (e: KeyboardEvent) => {
    if (e.keyCode === 13 && this.ref.current) {
      this.ref.current.blur();
    }
  };

  autocompleteValue = () => {
    let autocomplete = undefined;
    if (this.props.type && ['tel', 'email'].includes(this.props.type)) {
      autocomplete = this.props.type;
    } else if (this.props.name === 'name') {
      autocomplete = 'name';
    }

    return autocomplete;
  };

  pattern = () => {
    let { pattern } = this.props;

    if (!pattern) {
      if (this.props.type === 'tel') {
        pattern = '\\d{8,32}';
      }
    }

    return pattern;
  };

  render() {
    const {
      label,
      name,
      type,
      pattern,
      title,
      initialValue,
      children,
      onChange,
      value,
      ...filteredProps
    } = this.props;

    return (
      <label htmlFor={name} className={styles.input}>
        <p>{label}</p>
        {type ? (
          <input
            type={type}
            name={name}
            id={name}
            value={value || initialValue || ''}
            onChange={onChange}
            autoComplete={this.autocompleteValue()}
            pattern={this.pattern()}
            title={title}
            ref={this.ref}
            {...filteredProps}
            data-hj-whitelist
          />
        ) : null}
        {children}
      </label>
    );
  }
}
