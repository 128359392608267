import React, { useState } from 'react';
import { useFormikContext } from 'formik';

import { useIsMobile } from '../../../../../hooks';
import { centerlinkValues } from '../../../../../lib/NursingFormikValues';

import DropdownToggle from '../../../../../images/icons/dropdown-toggle.svg';

import { InputField, NextStepField, RadioField } from '../../Fields';

import formStyles from '../../nursingHomeForm.module.scss';

interface Props {
  setStep: (step: number) => void;
}

const Centerlink = ({ setStep }: Props) => {
  const { values } = useFormikContext<NursingHomeApplicationInterface>();
  const [isMobile] = useIsMobile(1000);
  const [dropdownsToggled, setDropdownsToggled] = useState<Array<boolean>>([false, false, false, false, false, false]);

  const handleDropdownToggle = (dropdownIndex: number) => {
    const dropDownToggeledInternal = [...dropdownsToggled];
    dropDownToggeledInternal[dropdownIndex] = !dropDownToggeledInternal[dropdownIndex];
    setDropdownsToggled(dropDownToggeledInternal);
  };

  return (
    <>
      <div>
        {!isMobile && <p className={formStyles.headingL}>Centrelink & Health Fund Details</p>}
        <div className={formStyles.toggleDropdown} onClick={() => handleDropdownToggle(0)} aria-hidden>
          <p className={formStyles.headingS}>Residential Permanent Referral Code:</p>
          {isMobile && (
            <DropdownToggle
              className={`${formStyles.dropdownSVG} ${dropdownsToggled[0] ? formStyles.dropdownSvgToggled : ''}`}
            />
          )}
        </div>
        {(!isMobile || dropdownsToggled[0]) && (
          <div className={formStyles.inputSection}>
            <InputField label="Residential Permanent Referral Code" name="referralCode" type="text" />
          </div>
        )}
        <div className={formStyles.divider} />
        <div className={formStyles.toggleDropdown} onClick={() => handleDropdownToggle(1)} aria-hidden>
          <p className={formStyles.headingS}>Centrelink Customer Reference Number (CRN)</p>
          {isMobile && (
            <DropdownToggle
              className={`${formStyles.dropdownSVG} ${dropdownsToggled[1] ? formStyles.dropdownSvgToggled : ''}`}
            />
          )}
        </div>
        {(!isMobile || dropdownsToggled[1]) && (
          <>
            <p className={formStyles.descriptions}>
              A Customer Reference Number (CRN) helps us identify your Centrelink record.
            </p>
            <div className={formStyles.inputSection}>
              <InputField label="Centerlink number" name="centerlinkId" type="text" />
            </div>
          </>
        )}
        <div className={formStyles.divider} />
        <div className={formStyles.toggleDropdown} onClick={() => handleDropdownToggle(2)} aria-hidden>
          <p className={formStyles.headingS}>Veteran Card Number (formerly Dept of Veterans Affairs)</p>
          {isMobile && (
            <DropdownToggle
              className={`${formStyles.dropdownSVG} ${dropdownsToggled[2] ? formStyles.dropdownSvgToggled : ''}`}
            />
          )}
        </div>
        {(!isMobile || dropdownsToggled[2]) && (
          <>
            <p className={formStyles.descriptions}>
              A unique personal identifier issued to a veteran by the Department of Veterans&apos; Affairs.
            </p>
            <div className={formStyles.inputSection}>
              <InputField label="Veterans Affairs Number" name="veteranAffairsId" type="text" />
            </div>
          </>
        )}
        <div className={formStyles.divider} />
        <div className={formStyles.toggleDropdown} onClick={() => handleDropdownToggle(3)} aria-hidden>
          <p className={formStyles.headingS}>Medicare card details</p>
          {isMobile && (
            <DropdownToggle
              className={`${formStyles.dropdownSVG} ${dropdownsToggled[3] ? formStyles.dropdownSvgToggled : ''}`}
            />
          )}
        </div>
        {(!isMobile || dropdownsToggled[3]) && (
          <>
            <p className={formStyles.descriptions}>What is the number on your card?</p>
            <div className={formStyles.inputSection}>
              <InputField label="Medicare No:" name="medicareId" type="text" />
            </div>
            <p className={formStyles.descriptions}>Where does the resident appear on the card?</p>
            <div className={formStyles.inputSection}>
              <InputField label="Reference number" name="medicareRef" type="text" />
            </div>
            <p className={formStyles.descriptions}>What date does the card expire?</p>
            <div className={formStyles.inputSection}>
              <InputField label="Expiry Date" name="medicareExpiry" type="date" min="now" />
            </div>
          </>
        )}
        <div className={formStyles.divider} />
        <div className={formStyles.toggleDropdown} onClick={() => handleDropdownToggle(4)} aria-hidden>
          <p className={formStyles.headingS}>
            National Diabetes Service Scheme (NDSS) and the National Gestational Diabetes Register
          </p>
          {isMobile && (
            <DropdownToggle
              className={`${formStyles.dropdownSVG} ${dropdownsToggled[4] ? formStyles.dropdownSvgToggled : ''}`}
            />
          )}
        </div>
        {(!isMobile || dropdownsToggled[4]) && (
          <span>
            <p className={formStyles.descriptions}>NDSS card number</p>
            <div className={formStyles.inputSection}>
              <InputField label="Diabetic number" name="diabeticId" type="text" />
            </div>
          </span>
        )}
        <div className={formStyles.divider} />
        <div className={formStyles.toggleDropdown} onClick={() => handleDropdownToggle(5)} aria-hidden>
          <p className={formStyles.headingS}>Health Fund</p>
          {isMobile && (
            <DropdownToggle
              className={`${formStyles.dropdownSVG} ${dropdownsToggled[5] ? formStyles.dropdownSvgToggled : ''}`}
            />
          )}
        </div>
        {(!isMobile || dropdownsToggled[5]) && (
          <>
            <p className={formStyles.descriptions}>
              If you have private health insurance, tell us the health fund and policy number
            </p>
            <div className={formStyles.inputSection}>
              <InputField label="Policy number" name="policyId" type="text" />
            </div>
            <div className={formStyles.inputSection}>
              <InputField label="Insurer" name="insurerId" type="text" />
            </div>
          </>
        )}
        <div className={formStyles.divider} />
        <div className={formStyles.toggleDropdown} onClick={() => handleDropdownToggle(6)} aria-hidden>
          <p className={formStyles.headingS}>Have you completed a Centrelink/DVA Asset Assessment application?</p>
          {isMobile && (
            <DropdownToggle
              className={`${formStyles.dropdownSVG} ${dropdownsToggled[6] ? formStyles.dropdownSvgToggled : ''}`}
            />
          )}
        </div>
        {(!isMobile || dropdownsToggled[6]) && (
          <>
            <RadioField label="" name="assetAssessment" values={['yes', 'no']} clearBg />
            <div className={`${formStyles.hide} ${values.assetAssessment == 'yes' && formStyles.show}`}>
              <p className={formStyles.descriptions}>What date was the assessment completed?</p>
              <InputField label="Date of assessment" name="assetAssessmentDate" type="date" />
            </div>
          </>
        )}
      </div>
      <NextStepField setStep={setStep} stepNum={7} fieldsToCheck={centerlinkValues} />
    </>
  );
};

export default Centerlink;
