import {getServerAddress} from "../lib/isBrowser";

export default class Autocomplete {
  static async getSuggestions(value: string) {
    try {
      const query = value.toLowerCase().trim().replace(/\s\s+/g, ' ');
      const params = `search=${encodeURIComponent(query)}`;
      const json = await fetch(`${getServerAddress()}/api/search.json?${params}`).then((response) => response.json());
      if (json.data) {
        return json.data;
      }
      return [];
    } catch (e) {
      // Raven.captureException(e);
      return [];
    }
  }
}
