import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import Carousel from 'react-multi-carousel';

import placeholderImage from '../../images/placeholder.gif';

import styles from './styles.module.scss';

interface Props {
  photos: AssetFragment[];
}

const PhotoCarousel = ({ photos }: Props) => {
  const mains = useMemo(
    () =>
      photos.map((p: AssetFragment) => (
        <div key={`photo-${p.url}`}>
          <img src={p.url!} alt={p.title!} />
        </div>
      )),
    [photos],
  );

  const responsive = {
    all: {
      breakpoint: { min: 0, max: 10000 },
      items: 1,
    },
  };

  const carouselProps = {
    infinite: true,
    responsive,
    autoPlay: false,
    shouldResetAutoplay: false,
  };

  if (!photos.length) {
    return <img src={placeholderImage.src} height={565} width={835} alt="" />;
  }

  if (photos.length === 1) {
    return <img src={photos[0].url!} height={565} width={835} alt={photos[0].url!} />;
  }

  return (
    <div className={styles.carouselWrapper}>
      <div className={styles.carousel}>
        <div>
          <div className={styles.slider}>
            <Carousel {...carouselProps}>{mains}</Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(PhotoCarousel);
