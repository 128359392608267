import React from 'react';
import { useFormikContext } from 'formik';

import { useIsMobile } from '../../../../../hooks';
import { detailsSection } from '../../../../../lib/NursingFormikValues';

import { NextStepField, RadioField } from '../../Fields';
import SectionContent from './sectionContent';

import formStyles from '../../nursingHomeForm.module.scss';

interface Props {
  setStep: (step: number) => void;
}

const PersonalDetails = ({ setStep }: Props) => {
  const { values } = useFormikContext<NursingHomeApplicationInterface>();
  const [isMobile] = useIsMobile(1000);

  return (
    <>
      {!isMobile && <p className={formStyles.headingL}>Resident/s Personal Details</p>}
      <RadioField label="Is this application for a couple?" name="isCouple" />
      {values.isCouple != '' && values.isCouple == 'yes' ? (
        <>
          <SectionContent id={1} />
          <SectionContent id={2} />
        </>
      ) : (
        <SectionContent id={1} />
      )}
      <NextStepField setStep={setStep} stepNum={2} fieldsToCheck={detailsSection} />
    </>
  );
};

export default PersonalDetails;
