import React, { useState } from 'react';
import cc from 'classcat';

import {LinkField, urlFor} from '../../../../lib/Link';

import styles from './dropdownItem.module.scss';

interface Props {
  item: MainMenuCategory;
  isActive: boolean;
  extras?: {
    target?: string | undefined;
    rel?: string | undefined;
  };
  menuPosition: string;
}

const DropdownItem = ({ item, isActive, extras, menuPosition }: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div
      className={styles.customDropdownWrapper}
      onMouseOver={() => setDropdownOpen(true)}
      onFocus={() => setDropdownOpen(true)}
      onMouseLeave={() => setDropdownOpen(false)}
      {...extras}
    >
      <a href={urlFor(item?.linkField?.[0] as LinkField)} className={cc({ isActive })}>
        {item.title}
      </a>
      <div className={styles.customDropdown} style={{ display: dropdownOpen ? 'flex' : 'none' }}>
        {item?.dropdownLinks?.map((link) => (
          <a key={`${menuPosition}-${link?.customTitle}`} href={urlFor(link?.linkField?.[0])} {...extras}>
            {link?.customTitle}
          </a>
        ))}
      </div>
    </div>
  );
};

export default DropdownItem;
