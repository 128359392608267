import React from 'react';
import cc from 'classcat';

import DateIcon from '../../../images/icons/date.svg';

import styles from './styles.module.scss';

const mapObj = {
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',
  Sunday: 'Sun',
};
const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');

interface Props {
  time: string;
  className?: string;
}

const TourTimes = ({ time, className }: Props) => {
  const timeShort = time.replace(re, (matched) => mapObj[matched]);
  if (time) {
    return (
      <p className={cc([styles.tourTimes, className])}>
        <DateIcon />
        <span className="desktopOnly">{`Tour times: ${time}`}</span>
        <span className="mobileOnly">{`Tour times: ${timeShort}`}</span>
      </p>
    );
  }
  return null;
};

export default TourTimes;
