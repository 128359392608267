import React, { useState } from 'react';
import { useFormikContext } from 'formik';

import { allFieldsBySection } from '../../../../lib/NursingFormikValues';

import DownArrow from '../../../../images/icons/chevron-down.svg';
import FinalStep from '../../../../images/icons/complete-step.svg';
import ErrorIcon from '../../../../images/icons/error-circle.svg';
import GreenTick from '../../../../images/icons/greenTick.svg';

import { handleRequiredFields } from '../Fields/NextStepField';

import styles from './formProgressMobile.module.scss';

interface Props {
  steps: Array<string>;
  setStep: (step: number) => void;
  currentStep: number;
}

const FormProgress = ({ steps, setStep, currentStep }: Props) => {
  const {
    values,
    errors,
    touched,
    validateField,
    setTouched,
    setFieldValue,
  } = useFormikContext<NursingHomeApplicationInterface>();
  const [isOpen, setIsOpen] = useState(false);

  const handleStepChange = (stepIdx: number, currentIdx: number) => {
    const fieldsToCheck = currentIdx === 9 ? [] : allFieldsBySection[currentIdx];
    if (!fieldsToCheck || !isAllowed(stepIdx)) return;
    handleRequiredFields(
      undefined,
      stepIdx,
      currentIdx,
      fieldsToCheck,
      errors,
      touched,
      setStep,
      validateField,
      setFieldValue,
      setTouched,
    );
    setIsOpen(false);
  };

  const getIcon = (stepIdx: number) => {
    const icon =
      stepIdx === currentStep ? (
        <div className={styles.dotActive}>
          <div className={styles.dotActiveInner} />
        </div>
      ) : values[`sectionStatus_${stepIdx}`] == 'error' ? (
        <ErrorIcon />
      ) : values[`sectionStatus_${stepIdx}`] === 'done' ? (
        <GreenTick />
      ) : (
        <div className={styles.dot} />
      );
    return icon;
  };

  const isAllowed = (stepIdx: number) => !!values[`sectionStatus_${stepIdx}`] || stepIdx <= currentStep;

  return (
    <div className={styles.container}>
      <div className={styles.selectMock} onClick={() => setIsOpen(!isOpen)} aria-hidden>
        <p className={styles.sectionTitle}>{steps[currentStep] ? steps[currentStep] : 'Summary & Terms'}</p>
        <p className={styles.stepTracker}>
          Step {currentStep + 1} of {steps.length}
        </p>
        <DownArrow className={`${styles.arrow} ${isOpen ? styles.arrowToggled : ''}`} />
      </div>
      <div className={`${styles.dropdown} ${isOpen ? styles.dropdownToggled : ''}`}>
        <div className={styles.innerDropdown}>
          {steps?.map((step, stepIdx) => {
            return (
              <div
                className={styles.optionWrapper}
                key={`step-${step}-${stepIdx}`}
                onClick={() => handleStepChange(stepIdx, currentStep)}
                aria-hidden
              >
                {getIcon(stepIdx)}
                <p className={`${styles.option} ${!isAllowed(stepIdx) ? styles.disabled : ''}`} data-index={stepIdx}>
                  {step}
                </p>
              </div>
            );
          })}
          <div className={styles.optionWrapper}>
            <div className={styles.dotDefault}>
              <FinalStep />
            </div>
            <p className={`${styles.option} ${styles.disabled}`} data-index={steps.length}>
              Summary & Confirmation
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormProgress;
