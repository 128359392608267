import React from 'react';
import cc from 'classcat';

import { Button, EmailLink, PhoneLink } from '../index';
import Image from '../layout/Image';
import { useIsMobile, useStore } from '../../hooks';

import HelpIcon from '../../images/icons/help.svg';
import PhoneIcon from '../../images/icons/phone.svg';

import HeroCTA from './cta';
import HeroWithoutCta from './heroWithoutCta';

import styles from './styles.module.scss';

interface Props {
  heroArea: HeroAreaFragment;
  parent?: string;
  headings?: HeadingAnchor[];
  children?: React.ReactNode;
  promoBanner?: PromoBannerFragment;
}

const Hero = ({ heroArea, parent, headings, children, promoBanner }: Props) => {
  const { heroText, heroMobileText } = useStore('globals');
  const [isMobile] = useIsMobile(768);
  const heroImage = heroArea.heroImage;
  const headline = heroArea.headline!;
  const retainSbsLayout = heroArea.retainSbsLayout;
  let ctasPresent = false;

  if (!heroImage) {
    return (
      <div className={cc([styles.hero, styles.heroWithoutImage, 'sectionWrapper'])}>
        <div className={cc(['container', styles.heroContainer])}>
          <div className={styles.heroSimpleInner}>{heroArea.headline && <h1>{heroArea.headline}</h1>}</div>
        </div>
      </div>
    );
  }

  if (heroArea?.children?.length) {
    {heroArea.children.map(
        (child) => child && child['__typename'] == 'hero_cta_BlockType' ? ctasPresent = true : null,
    )}
  }

  if (!ctasPresent || (ctasPresent && retainSbsLayout)) {
    return (
      <HeroWithoutCta heroArea={heroArea} parent={parent} headings={headings}>
        {children}
      </HeroWithoutCta>
    );
  }

  return (
    <>
      <div
        className={cc({
          [styles.hero]: true,
          sectionWrapper: true,
          [styles.withPromoBox]: promoBanner,
        })}
      >
        <div className={styles.heroImage}>{heroImage && <Image {...heroImage[0]} alt={headline} />}</div>
        <div className={cc(['container', styles.heroContainer])}>
          <div className={styles.heroContent}>
            <div className={styles.heroInner}>
              <div className={styles.left}>
                {heroArea.customTitle && <p data-test-id="customTitle">{heroArea.customTitle}</p>}
                {heroArea.headline && <h1 data-test-id="headline">{heroArea.headline}</h1>}
                <div className={styles.ctas}>
                  {heroArea?.children?.map(
                    (child) => child && <HeroCTA key={child.customTitle} cta={child} data-test-id={child.customTitle} name={parent ?? ''} />,
                  )}
                </div>
              </div>
              <div className={styles.right}>
                <h3>Not sure what you need?</h3>
                {isMobile ? <p>{heroMobileText}</p> : <p>{heroText}</p>}

                <div className={styles.links}>
                  {isMobile ? (
                    <PhoneLink type="white">
                      <PhoneIcon />
                      Call us
                    </PhoneLink>
                  ) : (
                    <PhoneLink type="white" />
                  )}
                  <EmailLink type="white" text={isMobile ? 'Email us' : 'Send us an email'} />
                  <Button type="linkWhite" href="/help-centre">
                    <HelpIcon />
                    View our Help Centre
                  </Button>
                  {isMobile && (
                    <p className={styles.linkHelp}>
                      <HelpIcon />
                      Or find answers in our&nbsp;
                      <a href="/help-centre">Help Centre</a>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {promoBanner && (
        <div className={styles.promoBanner}>
          <div className={styles.promoBannerContainer}>
            <div className={styles.promoBannerContent}>
              <h2 className={(promoBanner.lockup?.[0]?.url ? styles.srOnly : '')}>
                {promoBanner.heading}
              </h2>
              {promoBanner.lockup?.[0]?.url && (
                  <img loading="lazy" src={promoBanner.lockup?.[0]?.url} alt={promoBanner.heading ? promoBanner.heading : ''} />
              )}
              <div dangerouslySetInnerHTML={{ __html: (promoBanner.paragraph != null ? promoBanner.paragraph : '') }} />
              {promoBanner.buttonDestination?.[0] != null && (
                  <Button type="solidOrange" className={styles.campignButton} href={(promoBanner.buttonDestination?.[0].url != null ? promoBanner.buttonDestination?.[0].url : '')}>
                    {promoBanner.buttonTitle}
                  </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Hero;
