import React from 'react';
import { Field, useFormikContext } from 'formik';

import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
} & React.SelectHTMLAttributes<HTMLSelectElement>;

const FormikSelect = ({ children, ...props }: Props) => {
  const formik = useFormikContext();

  return (
    <div className={styles.select}>
      <Field
        as="select"
        {...props}
        invalid={formik && formik.errors[props.name!] && formik.touched[props.name!] && 'true'}
      >
        {children}
      </Field>
    </div>
  );
};

export default FormikSelect;
