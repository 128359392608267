export type LinkField = LinkEntryFragment | LinkProtocolFragment | LinkUrlFragment;

export function urlFor(link: LinkField | null | undefined) {
  if (!link) {
    return '';
  }

  if (link && 'entryLink' in link && link.entryLink?.[0]) {
    if (link.entryLink[0].uri === '__home__') {
      return '/';
    }
    return link.entryLink[0].uri ? `/${link.entryLink[0].uri}` : '';
  }

  if ('urlLink' in link && link.urlLink) {
    return link.urlLink;
  }

  if ('protocolLink' in link && link?.protocolLink) {
    return link.protocolLink;
  }

  return '';
}

export function isExternal(link: LinkField | null | undefined) {
  if (!link) {
    return false;
  }

  if ('urlLink' in link && link.urlLink) {
    return /^http/.test(link.urlLink);
  }

  return false;
}

export function isQuestionnaire(link: LinkField | null | undefined) {
  if (link && 'entryLink' in link && link.entryLink?.[0]) {
    return link.entryLink?.[0]?.sectionHandle === 'questionnaire';
  }

  return false;
}

export function getQuestionnaireId(link: LinkField) {
  if (link && 'entryLink' in link && link.entryLink?.[0]?.sectionHandle === 'questionnaire') {
    return parseInt(link.entryLink[0].id || '0');
  }

  return 0;
}
