import React from 'react';

import { renderActivityItem } from '../../../lib/ContentRenderer';

interface Props {
  activities: Maybe<ActivitiesFragment>[] | null | undefined;
}

const Activities = ({ activities }: Props) => {
  if (activities?.length) {
    return (
      <div className="section section-white">
        <div className="container">
          <div id="lifestyle-services" className="sectionHeading">
            Lifestyle & Services
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="max-width">
                <h3>
                  However you choose to fill your day, there are range of activities and services for you to enjoy.
                </h3>
                <p>These are the lifestyle activities and services available at this Village.</p>
                <p>&nbsp;</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">{activities?.map((activity) => activity && renderActivityItem(activity))}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default Activities;
