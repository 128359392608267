import React from 'react';

import PromptModal from '../PromptModal';

import styles from './helpModal.module.scss';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  setClosed: () => void;
  title?: string;
}

const HelpModal = ({ isOpen, title, onRequestClose, setClosed }: Props) => {
  return (
    <PromptModal isOpen={isOpen} onRequestClose={onRequestClose} title={title}>
      <div>
        <p className={styles.description}>
          The files are not required within the form however all other fields are required.
          <br />
          <br />
          If you wish to come back to your form later you can click the save & exit button in the top right
        </p>
        <div className={styles.btnOptions}>
          <button className={styles.activeBtn} onClick={setClosed}>
            Close
          </button>
        </div>
      </div>
    </PromptModal>
  );
};

export default HelpModal;
