import React, { useCallback, useState } from 'react';
import cc from 'classcat';
import { capitalize, isEqual } from 'lodash';

import styles from './styles.module.scss';

interface Props<T> {
  items: Array<MultiSwitchItem<T>>;
  defaultActiveItem?: MultiSwitchItem<T>;
  onClick: (item: MultiSwitchItem<T>) => void;
  transparent?: boolean;
  dark?: boolean;
  light?: boolean;
}

export default function MultiSwitch<T = string | number | number[] | boolean | unknown>({
  items,
  defaultActiveItem,
  onClick,
  transparent,
  dark,
  light,
}: Props<T>) {
  const [activeItem, setActiveItem] = useState<MultiSwitchItem<T> | null | undefined>(defaultActiveItem);

  const handleClick = useCallback(
    (item: MultiSwitchItem<T>, e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      setActiveItem(item);
      onClick(item);
    },
    [onClick],
  );

  const isActive = useCallback(
    (item: MultiSwitchItem<T>) => {
      return isEqual(activeItem, item);
    },
    [activeItem],
  );

  return (
    <div
      className={cc([
        styles.multiSwitch,
        { [styles.transparent]: transparent, [styles.dark]: dark, [styles.light]: light },
      ])}
    >
      {items.map((item) => (
        <button
          type="button"
          className={cc({ [styles.active]: isActive(item) })}
          key={item.title}
          onClickCapture={(e) => handleClick(item, e)}
        >
          {capitalize(item.title)}
        </button>
      ))}
    </div>
  );
}
