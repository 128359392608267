export const countries = [
  { value: 'Afghanistan' },
  { value: 'Albania' },
  { value: 'Algeria' },
  { value: 'Andorra' },
  { value: 'Angola' },
  { value: 'Antigua and Barbuda' },
  { value: 'Argentina' },
  { value: 'Armenia' },
  { value: 'Australia' },
  { value: 'Austria' },
  { value: 'Azerbaijan' },
  { value: 'The Bahamas' },
  { value: 'Bahrain' },
  { value: 'Bangladesh' },
  { value: 'Barbados' },
  { value: 'Belarus' },
  { value: 'Belgium' },
  { value: 'Belize' },
  { value: 'Benin' },
  { value: 'Bhutan' },
  { value: 'Bolivia' },
  { value: 'Bosnia and Herzegovina' },
  { value: 'Botswana' },
  { value: 'Brazil' },
  { value: 'Brunei' },
  { value: 'Bulgaria' },
  { value: 'Burkina Faso' },
  { value: 'Burundi' },
  { value: 'Cabo Verde' },
  { value: 'Cambodia' },
  { value: 'Cameroon' },
  { value: 'Canada' },
  { value: 'Central African Republic' },
  { value: 'Chad' },
  { value: 'Chile' },
  { value: 'China' },
  { value: 'Colombia' },
  { value: 'Comoros' },
  { value: 'Congo, Democratic Republic of the' },
  { value: 'Congo, Republic of the' },
  { value: 'Costa Rica' },
  { value: 'Côte d’Ivoire' },
  { value: 'Croatia' },
  { value: 'Cuba' },
  { value: 'Cyprus' },
  { value: 'Czech Republic' },
  { value: 'Denmark' },
  { value: 'Djibouti' },
  { value: 'Dominica' },
  { value: 'Dominican Republic' },
  { value: 'East Timor (Timor-Leste)' },
  { value: 'Ecuador' },
  { value: 'Egypt' },
  { value: 'El Salvador' },
  { value: 'Equatorial Guinea' },
  { value: 'Eritrea' },
  { value: 'Estonia' },
  { value: 'Eswatini' },
  { value: 'Ethiopia' },
  { value: 'Fiji' },
  { value: 'Finland' },
  { value: 'France' },
  { value: 'Gabon' },
  { value: 'The Gambia' },
  { value: 'Georgia' },
  { value: 'Germany' },
  { value: 'Ghana' },
  { value: 'Greece' },
  { value: 'Grenada' },
  { value: 'Guatemala' },
  { value: 'Guinea' },
  { value: 'Guinea-Bissau' },
  { value: 'Guyana' },
  { value: 'Haiti' },
  { value: 'Honduras' },
  { value: 'Hungary' },
  { value: 'Iceland' },
  { value: 'India' },
  { value: 'Indonesia' },
  { value: 'Iran' },
  { value: 'Iraq' },
  { value: 'Ireland' },
  { value: 'Israel' },
  { value: 'Italy' },
  { value: 'Jamaica' },
  { value: 'Japan' },
  { value: 'Jordan' },
  { value: 'Kazakhstan' },
  { value: 'Kenya' },
  { value: 'Kiribati' },
  { value: 'Korea, North' },
  { value: 'Korea, South' },
  { value: 'Kosovo' },
  { value: 'Kuwait' },
  { value: 'Kyrgyzstan' },
  { value: 'Laos' },
  { value: 'Latvia' },
  { value: 'Lebanon' },
  { value: 'Lesotho' },
  { value: 'Liberia' },
  { value: 'Libya' },
  { value: 'Liechtenstein' },
  { value: 'Lithuania' },
  { value: 'Luxembourg' },
  { value: 'Madagascar' },
  { value: 'Malawi' },
  { value: 'Malaysia' },
  { value: 'Maldives' },
  { value: 'Mali' },
  { value: 'Malta' },
  { value: 'Marshall Islands' },
  { value: 'Mauritania' },
  { value: 'Mauritius' },
  { value: 'Mexico' },
  { value: 'Micronesia, Federated States of' },
  { value: 'Moldova' },
  { value: 'Monaco' },
  { value: 'Mongolia' },
  { value: 'Montenegro' },
  { value: 'Morocco' },
  { value: 'Mozambique' },
  { value: 'Myanmar (Burma)' },
  { value: 'Namibia' },
  { value: 'Nauru' },
  { value: 'Nepal' },
  { value: 'Netherlands' },
  { value: 'New Zealand' },
  { value: 'Nicaragua' },
  { value: 'Niger' },
  { value: 'Nigeria' },
  { value: 'North Macedonia' },
  { value: 'Norway' },
  { value: 'Oman' },
  { value: 'Pakistan' },
  { value: 'Palau' },
  { value: 'Panama' },
  { value: 'Papua New Guinea' },
  { value: 'Paraguay' },
  { value: 'Peru' },
  { value: 'Philippines' },
  { value: 'Poland' },
  { value: 'Portugal' },
  { value: 'Qatar' },
  { value: 'Romania' },
  { value: 'Russia' },
  { value: 'Rwanda' },
  { value: 'Saint Kitts and Nevis' },
  { value: 'Saint Lucia' },
  { value: 'Saint Vincent and the Grenadines' },
  { value: 'Samoa' },
  { value: 'San Marino' },
  { value: 'Sao Tome and Principe' },
  { value: 'Saudi Arabia' },
  { value: 'Senegal' },
  { value: 'Serbia' },
  { value: 'Seychelles' },
  { value: 'Sierra Leone' },
  { value: 'Singapore' },
  { value: 'Slovakia' },
  { value: 'Slovenia' },
  { value: 'Solomon Islands' },
  { value: 'Somalia' },
  { value: 'South Africa' },
  { value: 'Spain' },
  { value: 'Sri Lanka' },
  { value: 'Sudan' },
  { value: 'Sudan, South' },
  { value: 'Suriname' },
  { value: 'Sweden' },
  { value: 'Switzerland' },
  { value: 'Syria' },
  { value: 'Taiwan' },
  { value: 'Tajikistan' },
  { value: 'Tanzania' },
  { value: 'Thailand' },
  { value: 'Togo' },
  { value: 'Tonga' },
  { value: 'Trinidad and Tobago' },
  { value: 'Tunisia' },
  { value: 'Turkey' },
  { value: 'Turkmenistan' },
  { value: 'Tuvalu' },
  { value: 'Uganda' },
  { value: 'Ukraine' },
  { value: 'United Arab Emirates' },
  { value: 'United Kingdom' },
  { value: 'United States' },
  { value: 'Uruguay' },
  { value: 'Uzbekistan' },
  { value: 'Vanuatu' },
  { value: 'Vatican City' },
  { value: 'Venezuela' },
  { value: 'Vietnam' },
  { value: 'Yemen' },
  { value: 'Zambia' },
  { value: 'Zimbabwe' },
];

export const nationalities = [
  { value: 'Afghan' },
  { value: 'Albanian' },
  { value: 'Algerian' },
  { value: 'American' },
  { value: 'Andorran' },
  { value: 'Angolan' },
  { value: 'Anguillan' },
  { value: 'Citizen of Antigua and Barbuda' },
  { value: 'Argentine' },
  { value: 'Armenian' },
  { value: 'Australian' },
  { value: 'Austrian' },
  { value: 'Azerbaijani' },
  { value: 'Bahamian' },
  { value: 'Bahraini' },
  { value: 'Bangladeshi' },
  { value: 'Barbadian' },
  { value: 'Belarusian' },
  { value: 'Belgian' },
  { value: 'Belizean' },
  { value: 'Beninese' },
  { value: 'Bermudian' },
  { value: 'Bhutanese' },
  { value: 'Bolivian' },
  { value: 'Citizen of Bosnia and Herzegovina' },
  { value: 'Botswanan' },
  { value: 'Brazilian' },
  { value: 'British' },
  { value: 'British Virgin Islander' },
  { value: 'Bruneian' },
  { value: 'Bulgarian' },
  { value: 'Burkinan' },
  { value: 'Burmese' },
  { value: 'Burundian' },
  { value: 'Cambodian' },
  { value: 'Cameroonian' },
  { value: 'Canadian' },
  { value: 'Cape Verdean' },
  { value: 'Cayman Islander' },
  { value: 'Central African' },
  { value: 'Chadian' },
  { value: 'Chilean' },
  { value: 'Chinese' },
  { value: 'Colombian' },
  { value: 'Comoran' },
  { value: 'Congolese (Congo)' },
  { value: 'Congolese (DRC)' },
  { value: 'Cook Islander' },
  { value: 'Costa Rican' },
  { value: 'Croatian' },
  { value: 'Cuban' },
  { value: 'Cymraes' },
  { value: 'Cymro' },
  { value: 'Cypriot' },
  { value: 'Czech' },
  { value: 'Danish' },
  { value: 'Djiboutian' },
  { value: 'Dominican' },
  { value: 'Citizen of the Dominican Republic' },
  { value: 'Dutch' },
  { value: 'East Timorese' },
  { value: 'Ecuadorean' },
  { value: 'Egyptian' },
  { value: 'Emirati' },
  { value: 'English' },
  { value: 'Equatorial Guinean' },
  { value: 'Eritrean' },
  { value: 'Estonian' },
  { value: 'Ethiopian' },
  { value: 'Faroese' },
  { value: 'Fijian' },
  { value: 'Filipino' },
  { value: 'Finnish' },
  { value: 'French' },
  { value: 'Gabonese' },
  { value: 'Gambian' },
  { value: 'Georgian' },
  { value: 'German' },
  { value: 'Ghanaian' },
  { value: 'Gibraltarian' },
  { value: 'Greek' },
  { value: 'Greenlandic' },
  { value: 'Grenadian' },
  { value: 'Guamanian' },
  { value: 'Guatemalan' },
  { value: 'Citizen of Guinea-Bissau' },
  { value: 'Guinean' },
  { value: 'Guyanese' },
  { value: 'Haitian' },
  { value: 'Honduran' },
  { value: 'Hong Konger' },
  { value: 'Hungarian' },
  { value: 'Icelandic' },
  { value: 'Indian' },
  { value: 'Indonesian' },
  { value: 'Iranian' },
  { value: 'Iraqi' },
  { value: 'Irish' },
  { value: 'Israeli' },
  { value: 'Italian' },
  { value: 'Ivorian' },
  { value: 'Jamaican' },
  { value: 'Japanese' },
  { value: 'Jordanian' },
  { value: 'Kazakh' },
  { value: 'Kenyan' },
  { value: 'Kittitian' },
  { value: 'Citizen of Kiribati' },
  { value: 'Kosovan' },
  { value: 'Kuwaiti' },
  { value: 'Kyrgyz' },
  { value: 'Lao' },
  { value: 'Latvian' },
  { value: 'Lebanese' },
  { value: 'Liberian' },
  { value: 'Libyan' },
  { value: 'Liechtenstein citizen' },
  { value: 'Lithuanian' },
  { value: 'Luxembourger' },
  { value: 'Macanese' },
  { value: 'Macedonian' },
  { value: 'Malagasy' },
  { value: 'Malawian' },
  { value: 'Malaysian' },
  { value: 'Maldivian' },
  { value: 'Malian' },
  { value: 'Maltese' },
  { value: 'Marshallese' },
  { value: 'Martiniquais' },
  { value: 'Mauritanian' },
  { value: 'Mauritian' },
  { value: 'Mexican' },
  { value: 'Micronesian' },
  { value: 'Moldovan' },
  { value: 'Monegasque' },
  { value: 'Mongolian' },
  { value: 'Montenegrin' },
  { value: 'Montserratian' },
  { value: 'Moroccan' },
  { value: 'Mosotho' },
  { value: 'Mozambican' },
  { value: 'Namibian' },
  { value: 'Nauruan' },
  { value: 'Nepalese' },
  { value: 'New Zealander' },
  { value: 'Nicaraguan' },
  { value: 'Nigerian' },
  { value: 'Nigerien' },
  { value: 'Niuean' },
  { value: 'North Korean' },
  { value: 'Northern Irish' },
  { value: 'Norwegian' },
  { value: 'Omani' },
  { value: 'Pakistani' },
  { value: 'Palauan' },
  { value: 'Palestinian' },
  { value: 'Panamanian' },
  { value: 'Papua New Guinean' },
  { value: 'Paraguayan' },
  { value: 'Peruvian' },
  { value: 'Pitcairn Islander' },
  { value: 'Polish' },
  { value: 'Portuguese' },
  { value: 'Prydeinig' },
  { value: 'Puerto Rican' },
  { value: 'Qatari' },
  { value: 'Romanian' },
  { value: 'Russian' },
  { value: 'Rwandan' },
  { value: 'Salvadorean' },
  { value: 'Sammarinese' },
  { value: 'Samoan' },
  { value: 'Sao Tomean' },
  { value: 'Saudi Arabian' },
  { value: 'Scottish' },
  { value: 'Senegalese' },
  { value: 'Serbian' },
  { value: 'Citizen of Seychelles' },
  { value: 'Sierra Leonean' },
  { value: 'Singaporean' },
  { value: 'Slovak' },
  { value: 'Slovenian' },
  { value: 'Solomon Islander' },
  { value: 'Somali' },
  { value: 'South African' },
  { value: 'South Korean' },
  { value: 'South Sudanese' },
  { value: 'Spanish' },
  { value: 'Sri Lankan' },
  { value: 'St Helenian' },
  { value: 'St Lucian' },
  { value: 'Stateless' },
  { value: 'Sudanese' },
  { value: 'Surinamese' },
  { value: 'Swazi' },
  { value: 'Swedish' },
  { value: 'Swiss' },
  { value: 'Syrian' },
  { value: 'Taiwanese' },
  { value: 'Tajik' },
  { value: 'Tanzanian' },
  { value: 'Thai' },
  { value: 'Togolese' },
  { value: 'Tongan' },
  { value: 'Trinidadian' },
  { value: 'Tristanian' },
  { value: 'Tunisian' },
  { value: 'Turkish' },
  { value: 'Turkmen' },
  { value: 'Turks and Caicos Islander' },
  { value: 'Tuvaluan' },
  { value: 'Ugandan' },
  { value: 'Ukrainian' },
  { value: 'Uruguayan' },
  { value: 'Uzbek' },
  { value: 'Vatican citizen' },
  { value: 'Citizen of Vanuatu' },
  { value: 'Venezuelan' },
  { value: 'Vietnamese' },
  { value: 'Vincentian' },
  { value: 'Wallisian' },
  { value: 'Welsh' },
  { value: 'Yemeni' },
  { value: 'Zambian' },
  { value: 'Zimbabwean' },
];
