import React, { useMemo, useRef } from 'react';
import { Severity, captureMessage } from 'isomorphic-sentry';

import Wizard from '../Wizard';
import { trackEvent } from '../../../lib/GA';
import FreeformHelper from '../../../lib/FreeformHelper';
import { QuestionnaireFormValues } from '../../../typings';
import { useStore } from '../../../hooks';

import { ConfirmationStep, ContactDetailsStep, LocationStep, QuestionStep } from './steps';

type Props = {
  onClose: () => void;
  initValue?: string;
} & QuestionnaireContextValue;

interface QuestionnaireContextValue {
  questionnaire: QuestionnaireFragment;
  questions: QuestionFragment[];
}

const QuestionnaireFormContext = React.createContext<QuestionnaireContextValue>({
  questionnaire: undefined as any,
  questions: [],
});

const QuestionnaireForm = ({ questionnaire, questions, onClose, initValue }: Props) => {
  const globals = useStore('globals');
  const context = useMemo(() => ({ questionnaire, questions }), [questionnaire, questions]);
  const ref = useRef<HTMLDivElement>(null);

  let formHandle = 'questionnaire';
  let form = globals?.freeform?.find((form) => form.handle === formHandle);
  let honeyPot = form?.honeypot;

  const firstAnswer = initValue || '';
  const initialValues: QuestionnaireFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    postcode: '',
    address: '',
    termsAndConditions: false,
    answers: [firstAnswer, '', '', '', ''], // TODO: need to dynamic belong to question number
    freeform_form_handle: ''
  };

  const steps: any[] = [];
  const stepProps: any[] = [];
  for (let i = 0; i < questions.length; i++) {
    steps.push(QuestionStep);
    stepProps.push({ question: questions[i], questionIndex: i });
  }
  if (questionnaire.toggle) {
    steps.push(LocationStep);
  }
  steps.push(ContactDetailsStep);

  async function onSubmit(values: QuestionnaireFormValues) {
    let success = false;

    const answers: string[] = [];
    for (let i = 0; i < questions.length; i++) {
      if (values.answers[i]) {
        answers.push(`Question: ${questions[i].title}\nAnswer: ${values.answers[i]}`);
      }
    }

    const formData = new FormData();
    // istanbul ignore next
    Object.keys(values).forEach((key) => {
      if (['firstName', 'lastName', 'phone', 'email', 'postcode', 'address', 'freeform_form_handle'].includes(key)) {
        formData.append(key, values[key] == null ? '' : values[key]);
      }
    });

    formData.append('handle', formHandle);
    formData.append('questionnaireType', questionnaire.title!);
    formData.append('questionnaireAnswer', answers.join('\n\n'));

    const response = await FreeformHelper.sendForm(formData, form);
    if (response.success) {
      success = true;
      trackEvent('Questionnaire', 'Submitted', questionnaire.title!);
    } else {
      captureMessage(`Questionnaire submission failed with status - ${response}`, Severity.Warning);
    }

    return success;
  }

  return (
    <div ref={ref}>
      <QuestionnaireFormContext.Provider value={context}>
        <Wizard<QuestionnaireFormValues>
          initialValues={initialValues}
          onClose={onClose}
          onSubmit={onSubmit}
          title={questionnaire.title!}
          steps={steps}
          stepProps={stepProps}
          confirmationStep={ConfirmationStep}
          confirmationStepProps={{
            content: questionnaire.thankyou!,
            closeText: questionnaire.closeText,
            onClose,
          }}
          honeyPot={honeyPot}
        />
      </QuestionnaireFormContext.Provider>
    </div>
  );
};

export default QuestionnaireForm;
export { QuestionnaireFormContext };
