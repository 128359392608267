import React, { useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import { Field, useFormikContext } from 'formik';

import { uploadImage } from '../../../../../lib/AssetUtils';

import styles from './fileField.module.scss';

interface Props {
  name: string;
  label: string;
  showNotAttached?: boolean;
}

const FileField = ({ name, label, showNotAttached }: Props) => {
  const [fileName, setFileName] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const { values, setFieldValue } = useFormikContext<NursingHomeApplicationInterface>();
  const [loading, setLoading] = useState(false);

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.[0]) return;
    setLoading(true);
    const tempName = e.target.files[0]?.name;
    const uploaded = await uploadImage(e.target.files[0]);

    if (uploaded.success) {
      setFileName(tempName);
      setFieldValue(`${name}_url`, uploaded?.url);
      setLoading(false);
    }
  };

  const clearUpload = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFileName('');
    setFieldValue(`${name}_url`, '');
    values[name] = '';
    values[`${name}_url`] = '';
  };

  // For loading in values from formik tokenUri loaded values
  useEffect(() => {
    if (fileName) return;
    if (values?.[`${name}_url`]) {
      setFileName('Last Uploaded File');
      setFileUrl(values?.[`${name}_url`]);
    }
  }, [fileName, name, values]);

  return (
    <>
      <div className={!fileName || loading ? styles.hide : ''}>
        <p className={styles.text}>
          <strong>Attached file:</strong>{' '}
          <a href={fileUrl} target="_blank" rel="noopener noreferrer">
            {fileName}
          </a>
        </p>
        <button className={styles.textButton} onClick={clearUpload}>
          <strong className={styles.highlightText}>Remove</strong>
        </button>
      </div>
      <div className={fileName || loading ? styles.hide : ''}>
        {showNotAttached && (
          <p className={styles.text}>
            <strong>No File Attached</strong>
          </p>
        )}
        <label htmlFor={name} className={styles.uploadBtn}>
          Upload {label}
          <Field type="file" id={name} name={name} className={styles.fileInput} onChange={handleUpload} />
        </label>
      </div>
      {loading && <Spinner />}
    </>
  );
};

export default FileField;
