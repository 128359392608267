import React from 'react';
import cc from 'classcat';

import { Address, Map } from '../../index';
import { renderAmenityItem } from '../../../lib/ContentRenderer';

import styles from './styles.module.scss';

interface Props {
  title: string;
  address: Maybe<AddressFragment> | null | undefined;
  amenities: Maybe<AmenitiesFragment>[] | null | undefined;
}

const Amenities = ({ title, address, amenities }: Props) => (
  <div className="section section-white no-padding">
    <div className="container">
      <div className={cc(['row', styles.amenities])}>
        <div className="col-lg-6">
          <div className="section-padding max-width">
            <div id="location" className="sectionHeading">
              {amenities && amenities?.length ? 'Location & Amenities' : 'Location'}
            </div>
            <h3>{title} is located:</h3>
            {address! && <Address address={address} />}
            {amenities && amenities?.length > 0 && (
              <>
                <h4 className={styles.nearby}>Nearby Amenities</h4>
                <div className="row">{amenities?.map((amenity) => amenity && renderAmenityItem(amenity))}</div>
              </>
            )}
          </div>
        </div>
        <div className="col-lg-6 pl-0 pr-0">
          <div className={styles.map}>
            <Map
              address={`${title}, ${address?.address}`}
              lat={address?.lat}
              lng={address?.lng}
              width={720}
              height={740}
              alt={`Map of ${title}`}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Amenities;
