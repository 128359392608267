import React from 'react';

import { useSiteQuery } from '../../../hooks';
import { getServicesTypes } from '../../../lib/FunctionLib';

import query from './servicesGlobals.graphql';

import styles from './styles.module.scss';

interface Props {
  isMain?: boolean;
  customTitle?: string;
  isNursingHome?: boolean;
}

const renderServiceItemByType = (service: Maybe<ServicesCategoryFragment>, type: string) => {
  if (service?.serviceType?.[0]?.title === type) {
    const title = service?.title || undefined;
    const icon = service?.icon?.[0]?.url;

    return (
      <div key={title} className="col-4 pb-1">
        <div className="iconBlock">{icon && <img src={icon} alt={title} />}</div>
        <p className="service-label">{title}</p>
      </div>
    );
  }
  return null;
};

const ServicesGlobals = ({ isMain, customTitle, isNursingHome }: Props) => {
  const [{ data }] = useSiteQuery<ServicesGlobalsQuery>({ query });
  const services = data?.[isNursingHome ? 'nursingHomes' : 'retirementVillages']?.servicesCategories;

  if (services) {
    const serviceTypes = getServicesTypes(services);
    const disclaimer = serviceTypes
      .map((serviceType) => serviceType?.disclaimer)
      .filter((item) => item)
      .join('<br />');
    return (
      <div id="additional-services">
        <div className={styles.serviceGlobals}>
          <div className="container">
            {isMain ? (
              <h2>{customTitle || 'What support do we offer?'}</h2>
            ) : (
              <h3>{customTitle || 'Additional services for purchase'}</h3>
            )}
            <p>&nbsp;</p>
            <div className="row rowRoot">
              <div className="col-12 column-borders column-without-padding">
                <div className="row">
                  {serviceTypes.map(
                    (serviceType) =>
                      serviceType && (
                        <div key={serviceType.title} className="col-lg-6">
                          <div className="row">
                            <div className="col-lg-6">
                              <p className="column-level">
                                <strong>{serviceType.title}</strong>
                              </p>
                              <p className="service-description">{serviceType.description}</p>
                            </div>
                            <div className="col-lg-6">
                              <div className="row">
                                {services.map((service) => renderServiceItemByType(service, serviceType.title!))}
                              </div>
                            </div>
                          </div>
                        </div>
                      ),
                  )}
                </div>
                <p className={styles.disclaimer} dangerouslySetInnerHTML={{ __html: disclaimer }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default ServicesGlobals;
