import React, { useState } from 'react';

import { Button, Modal } from '../../index';
import EventForm from '../../forms/EventForm';

interface Props {
  title: string;
  className?: string;
}

const EventButton = ({ title, className }: Props) => {
  const [enquireModal, setEnquireModal] = useState<boolean>(false);

  return (
    <>
      <Button onClick={() => setEnquireModal(!enquireModal)} className={className} type="solidOrange" large>
        Register for the event
      </Button>
      <Modal isOpen={enquireModal} onRequestClose={() => setEnquireModal(false)} title={title}>
        <EventForm eventName={title} />
      </Modal>
    </>
  );
};

export default EventButton;
