import React, { useEffect, useRef } from 'react';
import cc from 'classcat';
import { observer } from 'mobx-react';
import { useWindowScroll, useWindowSize } from 'react-use';
import Spinner from '@atlaskit/spinner';
import { throttle } from 'lodash';

import { useStore } from '../../../hooks';

import CourseItem from './item';

import styles from './styles.module.scss';

const CoursesList = () => {
  const store = useStore('coursesStore');
  const ref = useRef<HTMLDivElement>(null);

  // Load more results on scroll
  const { y: scrollTop } = useWindowScroll();
  const { height: windowHeight } = useWindowSize();
  useEffect(
    throttle(() => {
      if (ref.current) {
        const triggerPoint = ref.current!.clientHeight - windowHeight * 2;
        if (scrollTop > triggerPoint) {
          if (!store.loading && !store.allResultsLoaded && store.hydrated) {
            store.loadMoreCourses();
          }
        }
      }
    }, 500),
    [scrollTop, store, windowHeight],
  );

  return (
    <div ref={ref}>
      {store.results.map((course) => course && <CourseItem key={course.uid} item={course} />)}
      <div className={cc({ [styles.loading]: true, [styles.active]: store.loading })}>
        <Spinner size="xlarge" isCompleting={!store.loading} />
      </div>
    </div>
  );
};

export default observer(CoursesList);
