
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Amenities"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"amenities_amenity_BlockType"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"uid"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"amenity"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AmenitiesCategory"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"distance"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":172}};
    doc.loc.source = {"body":"#import \"@/graphql/AmenitiesCategory.graphql\"\n\nfragment Amenities on amenities_amenity_BlockType {\n  __typename\n  uid\n  amenity {\n    ...AmenitiesCategory\n  }\n  distance\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("@/graphql/AmenitiesCategory.graphql").definitions));


      module.exports = doc;
    
