import React from 'react';

import { Readmore } from '../index';
import Image from '../layout/Image';
import { isExternal, urlFor } from '../../lib/Link';
import { postDate } from '../../lib/PostDate';

import thumbImage from '../../images/placeholder.gif';

import styles from './styles.module.scss';

interface Props {
  item: AnnualReportsFragment;
}

const ReportCard = ({ item }: Props) => {
  const title = item.title!;
  const image = item.heroImage;

  const linkField = item?.linkField?.[0];
  const link = urlFor(linkField);
  const props = isExternal(linkField)
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {};

  return (
    <div className={styles.cardItem}>
      <div>
        <div className={styles.info}>
          <span>Annual Reports</span>
          <h3>
            <a href={link} {...props}>
              {title}
            </a>
          </h3>
          <span className="postDate">{postDate(item.postDate)}</span>
        </div>
        <a className={styles.thumbImage} href={link} {...props} aria-label={title}>
          <div>{image ? <Image {...image[0]} alt={title} /> : <img loading="lazy" src={thumbImage} alt={title} />}</div>
        </a>
        <div className={styles.bottom}>
          <Readmore href={link} {...props} />
        </div>
      </div>
    </div>
  );
};

export default ReportCard;
