import React from 'react';
import cc from 'classcat';
import PlacesAutocomplete, { Suggestion } from 'react-places-autocomplete';
import { Field, useFormikContext } from 'formik';

import fieldStyles from '../fields.module.scss';
import styles from './addressField.module.scss';

interface Props {
  label: string;
  name: string;
  placeholder?: string;
  required?: boolean;
}

const AddressField = ({ label, name, placeholder, required }: Props) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<NursingHomeApplicationInterface>();

  return (
    <div className={styles.wrapper}>
      <PlacesAutocomplete
        value={values?.[name]}
        onChange={(value) => setFieldValue(name, value)}
        onSelect={(value) => setFieldValue(name, value)}
        searchOptions={AddressField.searchOptions}
        highlightFirstSuggestion
        googleCallbackName="__googleMapsCallback"
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }: any) => (
          <div
            className={`${styles.container} ${(touched?.[name] && errors?.[name] && fieldStyles.errorInput) || ''}`}
            key={`container-${name}`}
          >
            <label className={styles.label}>{label}</label>
            <div className={styles.inputContainer}>
              <Field
                {...getInputProps({
                  placeholder,
                  className: styles.addressInput,
                })}
                name={name}
                required={required}
                className={styles.addressInput}
                disabled={false}
                title="Please enter and select your address."
              />
              <div
                className={cc({ [styles.dropDown]: !!suggestions.length, [styles.dropDownActive]: suggestions.length })}
              >
                {suggestions.map((suggestion: Suggestion) => {
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className: suggestion.active ? styles.dropDownItemActive : styles.dropDownItem,
                      })}
                      key={suggestion.description}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {touched?.[name] && errors?.[name] && (
        <div className={fieldStyles.errorBox}>
          <p className={fieldStyles.errorMsg}>{label} is a required field</p>
        </div>
      )}
    </div>
  );
};

AddressField.searchOptions = {
  types: ['address'],
  componentRestrictions: { country: 'AU' },
};

export default AddressField;
