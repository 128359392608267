import { action, extendObservable } from 'mobx';
import { throttle } from 'lodash';

const isBrowser = typeof window !== 'undefined';

const Device = {
  iOS: isBrowser && !!window.navigator.userAgent?.match(/(iPhone|iPad|iPod)/),
  Android: isBrowser && !!window.navigator.userAgent.match(/Android/),
  isMobileOS: false,
  isMobile: false,
  isDesktop: true,
  navBreakpoint: false,
  largeDesktop: false,
};

Device.isMobileOS = Device.iOS || Device.Android;

const isMobile = () => isBrowser && window.innerWidth <= 768;
const isDesktop = () => (isBrowser ? window.innerWidth > 1024 : true);
const navBreakpoint = () => isBrowser && window.innerWidth <= 950;
const largeDesktop = () => isBrowser && window.innerWidth >= 1180;
extendObservable(Device, {
  isMobile: isMobile(),
  isDesktop: isDesktop(),
  navBreakpoint: navBreakpoint(),
  largeDesktop: largeDesktop(),
});

const resizeHandler = throttle(
  action(() => {
    Device.isMobile = isMobile();
    Device.isDesktop = isDesktop();
    Device.navBreakpoint = navBreakpoint();
    Device.largeDesktop = largeDesktop();
  }),
  100,
);

if (isBrowser) {
  window.addEventListener('resize', resizeHandler);
}

export default Device;
export { isBrowser };
