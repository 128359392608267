import React from 'react';
import cc from 'classcat';

import CourseButtons from './buttons';
import InfoTable from './table';

import styles from './styles.module.scss';

interface Props {
  sticky?: boolean;
  course: CourseItemFragment;
}

const CourseInformation = ({ sticky, course }: Props) => {
  if (course) {
    const title = course.title!;
    const applicationLink = course.bookeoCategoryIdentifier!;
    const info = course.courseInfo?.[0];
    const showForm = course.toggle!;

    return (
      <div className={cc({ [styles.sumary]: true, [styles.sticky]: sticky })}>
        <h5>{title}</h5>
        <p className={styles.courseID}>{course.courseID}</p>
        {info && <InfoTable info={info} />}
        <CourseButtons title={title} applicationLink={applicationLink} showForm={showForm} />
      </div>
    );
  }

  return null;
};

export default CourseInformation;
