import React from 'react';
import { Field, useFormikContext } from 'formik';
import { capitalize } from 'lodash';

import fieldStyles from '../fields.module.scss';
import styles from './radioField.module.scss';

interface Props {
  label?: string;
  name: string;
  values?: Array<string>;
  stretch?: boolean;
  clearBg?: boolean;
  className?: string;
}

const RadioField = ({ label = '', name, values, stretch = false, clearBg = false, className = '' }: Props) => {
  const { errors, touched } = useFormikContext<NursingHomeApplicationInterface>();
  const booleanRadio = values && values[0] == 'yes' && values[1] == 'no';

  return (
    <div className={`${styles.wrapper}  ${className}`}>
      <div
        className={`${styles.container} ${stretch ? styles.stretch : ''} ${
          (touched?.[name] && errors?.[name] && fieldStyles.errorInputAlt) || ''
        } ${clearBg ? styles.clearBg : ''}`}
      >
        <label className={styles.labelText}>{label}</label>
        {values && !booleanRadio ? (
          <div className={label ? styles.fields : styles.onlyFields}>
            {values.map((value, idx) => (
              <span className={styles.mappedField} key={`${value}-${idx}`}>
                <Field className={styles.radio} type="radio" name={name} id={name} value={value} />
                <p className={styles.inputText}>{capitalize(value)}</p>
              </span>
            ))}
          </div>
        ) : (
          <div className={styles.fields}>
            <Field className={styles.radio} type="radio" name={name} id={name} value="no" />
            <p className={styles.inputText}>No</p>
            <Field className={styles.radio} type="radio" name={name} id={name} value="yes" />
            <p className={styles.inputText}>Yes</p>
          </div>
        )}
      </div>
      {touched?.[name] && errors?.[name] && (
        <div className={`${fieldStyles.errorBox} ${styles.errorMargin}`}>
          <p className={fieldStyles.errorMsg}>{label} is a required field</p>
        </div>
      )}
    </div>
  );
};

export default RadioField;
