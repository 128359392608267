import React from 'react';
import { Field, useFormikContext } from 'formik';

import fieldStyles from '../fields.module.scss';
import styles from './selectField.module.scss';

interface SelectOption {
  value: Maybe<string> | undefined;
  text?: Maybe<string> | undefined;
  disabled?: boolean;
}

interface Props {
  label: string;
  name: string;
  options: Array<SelectOption | undefined>;
  placeholder?: string;
}

const SelectField = ({ label, name, placeholder, options }: Props) => {
  const { values, errors, touched } = useFormikContext<NursingHomeApplicationInterface>();

  return (
    <div>
      <div className={`${styles.container} ${(touched?.[name] && errors?.[name] && fieldStyles.errorInput) || ''}`}>
        <label className={styles.label}>{label}</label>
        <Field
          as="select"
          className={`${styles.select} ${!values?.[name] && styles.greyText}`}
          name={name}
          placeholder={placeholder}
        >
          {options?.map(
            (option) =>
              option &&
              (option?.value || option?.value == '') && (
                <option
                  value={option.value}
                  disabled={option.disabled || false}
                  className={styles.option}
                  key={`${option.text} ${option.value}`}
                >
                  {option?.text || option?.value}
                </option>
              ),
          )}
        </Field>
      </div>
      {touched?.[name] && errors?.[name] && (
        <div className={fieldStyles.errorBox}>
          <p className={fieldStyles.errorMsg}>{label} is a required field</p>
        </div>
      )}
    </div>
  );
};

export default SelectField;
