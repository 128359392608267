import React from 'react';
import { Field, useFormikContext } from 'formik';

import fieldStyles from '../fields.module.scss';
import styles from './priceField.module.scss';

interface Props {
  name: string;
  label?: string;
  stretch?: boolean;
}

const PriceField = ({ label, name, stretch = false }: Props) => {
  const { errors, touched } = useFormikContext<NursingHomeApplicationInterface>();

  return (
    <div className={styles.wrapper}>
      <div>
        <div
          className={`${styles.container} ${stretch ? styles.stretch : ''} ${
            (touched?.[name] && errors?.[name] && fieldStyles.errorInput) || ''
          }`}
        >
          {label && <label className={styles.label}>{label}</label>}
          <div className={styles.innerContainer}>
            <span className={styles.priceTag}>$</span>
            <Field className={styles.input} type="number" name={name} min={0} />
          </div>
        </div>
        {touched?.[name] && errors?.[name] && (
          <div className={fieldStyles.errorBox}>
            <p className={fieldStyles.errorMsg}>required</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PriceField;
