import React, { useState } from 'react';
import cc from 'classcat';
import scrollIntoView from 'scroll-into-view';

import { Button, Modal } from '../../index';
import EnquireForm from '../../forms/EnquireForm';
import { useIsMobile } from '../../../hooks';
import { trackEvent } from '../../../lib/GA';
import { isExternal, urlFor } from '../../../lib/Link';

import styles from './styles.module.scss';

export type ButtonBlockType = 'solidOrange' | 'orangeBorder' | 'solidPurple' | 'purpleBorder' | 'link';

interface Props {
  item: ButtonFragment | FeesButtonFragment;
}

const ButtonBlock = ({ item }: Props) => {
  const [isMobile] = useIsMobile(768);
  const linkField = item?.linkField?.[0];
  const iconUrl = item.icon?.[0]?.url;
  const [enquireModal, setEnquireModal] = useState<boolean>(false);

  const trackDownload = (type: string, url: string) => {
    switch (type) {
      case 'homeCare':
        trackEvent('PDF', 'Download', 'Home Care');
        break;
      case 'rentals':
        trackEvent('PDF', 'Download', 'Rentals');
        break;
      case 'nursingHomesApplicationBegin':
        trackEvent('Form', 'Begin', 'Nursing Homes');
        break;
      default:
        break;
    }
    window.open(url, '_blank');
  };

  const scrollToSection = (slug: string) => {
    const isRowSection = slug.startsWith('row-section');
    const sectionPadding = isRowSection ? (isMobile ? 40 : 160) : isMobile ? 0 : 80;

    if (document.getElementById(slug)) {
      scrollIntoView(document.getElementById(slug)!, { align: { top: 0, topOffset: sectionPadding } });
    }
  };

  const { openEnquireModal } = item as ButtonFragment;

  const props = item.tracking
    ? {
        onClick: () => trackDownload(item.tracking!, urlFor(linkField)),
      }
    : urlFor(linkField).indexOf('/#') === 0
    ? {
        onClick: () => scrollToSection(urlFor(linkField).replace('/#', '')),
      }
    : isExternal(linkField) || item.toggle
    ? {
        href: urlFor(linkField),
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : openEnquireModal
    ? {
        as: 'button',
        onClick: () => setEnquireModal(true),
      }
    : {
        href: urlFor(linkField),
      };
  return (
    <>
      <Button
        includeArrow={!!item.includeArrow}
        large
        type={(item.buttonStyle as any) as ButtonBlockType}
        roundCorners={(item as ButtonFragment).roundCorners!}
        className={cc([
          styles.buttonBlock,
          { [styles.includeArrow]: item.includeArrow, [styles.isLink]: item.buttonStyle === 'link' },
        ])}
        {...props}
      >
        {iconUrl && <img loading="lazy" src={iconUrl} alt={item?.customTitle || undefined} />}
        <span className={styles.customTitle}>{item.customTitle}</span>
        <span className={styles.shortText}>{item.shortText || item.customTitle}</span>
      </Button>
      {openEnquireModal && (
        <Modal isOpen={enquireModal} onRequestClose={() => setEnquireModal(false)} solutionsModal>
          <EnquireForm handleClose={() => setEnquireModal(false)} phoneSelected={item.customTitle?.includes('call')} />
        </Modal>
      )}
    </>
  );
};

export default ButtonBlock;
