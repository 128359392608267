import React from 'react';
import cc from 'classcat';

import { ArrowButton, CardItem } from '../../index';
import { useSiteQuery } from '../../../hooks';

import query from './articles.graphql';

import styles from './styles.module.scss';

const Articles = () => {
  const [{ data }] = useSiteQuery<ArticlesQuery>({ query });
  const items = data?.items;

  if (items) {
    const cards = items?.slice(0, 2);
    const titles = items?.slice(2);

    return (
      <div id="news-and-events" className={styles.articles}>
        <div className="container">
          <div className="sectionHeading">News and Updates</div>
          <h2 className="sectionTitle">What’s going on?</h2>
          <div className={cc(['row', styles.list])}>
            {cards.map((article) => article && <CardItem key={article.uid!} item={article} />)}
            <div className={styles.carousel}>
              <div className="childrenAsSlider">
                {cards.map((article) => article && <CardItem key={article.uid!} item={article} />)}
              </div>
            </div>
            <div className={styles.listMore}>
              <div className={styles.topColumn}>
                <div>
                  <h4>Other News & Events</h4>
                  <div className={styles.whiteBox}>
                    {titles.map(
                      (article) =>
                        article && (
                          <div key={article.uid}>
                            <h5>
                              <a href={`/${article.uri}`}>{article.title}</a>
                            </h5>
                          </div>
                        ),
                    )}
                  </div>
                  <ArrowButton type="next" href="/about-us/news-and-events">
                    See all News & Events
                  </ArrowButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default Articles;
