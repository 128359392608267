import React from 'react';

import styles from './styles.module.scss';

interface Props {
  item: CourseItemFragment;
}

const CourseItem = ({ item }: Props) => {
  if (item) {
    return (
      <div className={styles.course}>
        <div>
          <h3 className={styles.name}>
            <a href={`/${item.uri}`}>{item.title!}</a>
          </h3>
        </div>
        {item.intro && <div className={styles.intro} dangerouslySetInnerHTML={{ __html: item.intro }} />}
      </div>
    );
  }
  return null;
};

export default CourseItem;
