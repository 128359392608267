import React, { useState } from 'react';
import cc from 'classcat';

import { MultiSwitch } from '../../index';

import styles from './styles.module.scss';

interface Props {
  floorplans: Maybe<FloorplanFragment>[];
}

const Floorplans = ({ floorplans }: Props) => {
  const [activeItem, setActiveItem] = useState(floorplans[0]?.title);
  const filterItems: Array<MultiSwitchItem> = floorplans
    .filter((f): f is FloorplanFragment => f !== null && f !== undefined)
    .map((floorplan) => ({
      title: floorplan.title!,
      value: floorplan.title!,
    }));

  if (floorplans) {
    return (
      <div className={styles.floorplans}>
        {floorplans?.map(
          (floorplan) =>
            floorplan && (
              <div
                key={floorplan.title!}
                className={cc([styles.floorplan, { [styles.active]: activeItem === floorplan.title! }])}
              >
                <div title={floorplan!.title!}>
                  <img key={floorplan.title!} src={floorplan.url!} alt={floorplan.title!} />
                </div>
              </div>
            ),
        )}
        <MultiSwitch
          items={filterItems}
          defaultActiveItem={filterItems[0]}
          onClick={(item: MultiSwitchItem) => item && setActiveItem(item.value as string)}
          transparent
        />
      </div>
    );
  }
  return null;
};

export default Floorplans;
