import React from 'react';

import { HeroLocation } from '../../index';
import { useSiteQuery } from '../../../hooks';

import query from './index.graphql';

interface Props {
  title: string;
  headline: string;
  photos: AssetFragment[];
  onGalleryClick: (e: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>) => void;
  location: string;
  activities?: boolean;
}

const Headings = ({ title, headline, photos, onGalleryClick, location, activities }: Props) => {
  const [{ data }] = useSiteQuery<UnitsQuery>({ query, variables: { location } });
  const entries = data?.entries;

  const headings = [];

  if (entries && entries.length) {
    headings.push({ title: 'Available Units', slug: 'available-units' });
  }

  headings.push({ title: 'Village Overview', slug: 'village-overview' });

  if (activities) {
    headings.push({ title: 'Lifestyle & Services', slug: 'lifestyle-services' });
  }

  headings.push({ title: 'Additional Services', slug: 'additional-services' });
  headings.push({ title: 'Location', slug: 'location' });

  return (
    <HeroLocation
      sectionHeading="Retirement Village Locations"
      title={title}
      headline={headline}
      headings={headings}
      photos={photos}
      onGalleryClick={onGalleryClick}
    />
  );
};

export default Headings;
