import React from 'react';
import cc from 'classcat';
import scrollIntoView from 'scroll-into-view';

import {useIsMobile} from '../../hooks';

import styles from './styles.module.scss';

interface Props {
    children?: React.ReactNode;
    headings?: HeadingAnchor[];
}

const HeroCourse = ({children, headings}: Props) => {
    const [isMobile] = useIsMobile(768);

    const scrollToSection = (slug: string) => {
        const isRowSection = slug.startsWith('row-section');
        const sectionPadding = isRowSection ? (isMobile ? 40 : 160) : isMobile ? 0 : 80;

        if (document.getElementById(slug)) {
            scrollIntoView(document.getElementById(slug)!, {align: {top: 0, topOffset: sectionPadding}});
        }
    };

    return (
        <div className={cc([styles.hero, styles.heroWithoutCta, styles.heroStatic, styles.withoutImage])}>
            <div className={cc(['container', styles.heroContainer])}>
                <div className="row">
                    <div className="col-lg-12">{children}</div>
                </div>
                {headings && headings.length ?
                    <>
                        <div className={styles.diviver}/>
                        <div className={styles.toggle}>
                            <p>On this page</p>
                            {headings?.map((heading) => (
                                <button key={heading.title} type="button"
                                        onClickCapture={() => scrollToSection(heading.slug)}>
                                    {heading.title}
                                </button>
                            ))}
                        </div>
                    </>
                    : null}
            </div>
        </div>
    );
};

export default HeroCourse;
