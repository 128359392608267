import React, { memo } from 'react';
import cc from 'classcat';
import { Slider as BaseSlider, Handles, Rail, SliderProps, Tracks } from 'react-compound-slider';
import { isEqual } from 'lodash';

import Handle from './Handle';

import styles from './styles.module.scss';

type Props = Omit<SliderProps, 'children'>;

const Slider = ({ ...props }: Props) => {
  return (
    <div>
      <BaseSlider {...props} className={cc({ [styles.slider]: true })}>
        <Rail>
          {({ getRailProps }) => (
            <>
              <div className="outerRail" {...getRailProps()} />
              <div className="innerRail" />
            </>
          )}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className={styles.handles} style={{ position: 'relative' }}>
              {handles.map((handle) => (
                <Handle key={handle.id} handle={handle} domain={props.domain} getHandleProps={getHandleProps} />
              ))}
            </div>
          )}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className={styles.tracks}>
              <svg viewBox="-6 -6 12 12">
                <circle r="4" />
              </svg>
              {tracks.map(({ id, source, target }) => (
                <div
                  key={id}
                  className={cc({ [styles.track]: true })}
                  style={{
                    left: `${source.percent}%`,
                    width: `${target.percent - source.percent}%`,
                  }}
                  {...getTrackProps()}
                />
              ))}
              <svg viewBox="-6 -6 12 12">
                <circle r="4" />
              </svg>
            </div>
          )}
        </Tracks>
      </BaseSlider>
    </div>
  );
};

export default memo(Slider, (prev, next) => isEqual(prev, next));
