import React from 'react';

import { Accordion } from '../../index';
import { useSiteQuery } from '../../../hooks';

import HelpIcon from '../../../images/icons/help.svg';

import query from './faqs.graphql';
import FAQsForm from './form';

import styles from './styles.module.scss';

interface Props {
  fragment?: FaqsBlockFragment;
  topic?: string;
}

const FAQs = ({ fragment, topic }: Props) => {
  const [{ data }] = useSiteQuery<FaqsQuery>({
    query,
    variables: { search: `topic:${fragment?.topic?.[0]?.slug || topic}` },
  });
  const items = data?.items;

  if (items) {
    return (
      <div id="faqs" className={styles.faqs}>
        <div className="container">
          <div className={styles.faqsContent}>
            <div className={styles.left}>
              <div className="sectionHeading">
                <HelpIcon />
                FREQUENTLY ASKED QUESTIONS
              </div>
              <h2 className="sectionTitle">Have a question?</h2>
              <FAQsForm />
              <p>
                Find more answers on our <a href="/help-centre">FAQs</a> page
              </p>
            </div>
            <div className={styles.right}>
              {items?.map(
                (item) =>
                  item && (
                    <Accordion key={item!.title!} title={item!.title!}>
                      <div dangerouslySetInnerHTML={{ __html: item!.body! }} />
                    </Accordion>
                  ),
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default FAQs;
