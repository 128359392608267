import React, { useEffect, useState } from 'react';
import cc from 'classcat';
import { observer } from 'mobx-react';
import { useDebounce } from 'react-use';
import { useRouter } from 'next/router';

import Input from '../../forms/Input';
import { useStore } from '../../../hooks';

import styles from './styles.module.scss';

const CoursesSearch = () => {
  const store = useStore('coursesStore');
  const router = useRouter();
  const initQuery = (router.query?.q as string) || '';
  const [value, setValue] = useState<string>(initQuery);
  const [query, setQuery] = useState(value);

  useDebounce(
    () => {
      setQuery(value);
    },
    250,
    [value],
  );

  useEffect(() => {
    store.setQuery(query);
  }, [store, query]);

  return (
    <div className={styles.coursesSearch}>
      <h5>Keywords</h5>
      <div className={cc(['searchForm', styles.search])}>
        <Input
          type="text"
          name="search"
          placeholder="e.g. Carer, Perth"
          onChange={(e) => setValue(e.currentTarget.value)}
          value={value}
        />
      </div>
    </div>
  );
};

export default observer(CoursesSearch);
