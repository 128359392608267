import React from 'react';

import styles from './styles.module.scss';

interface Props {
  headline: string;
  description: string;
  children: React.ReactNode;
}

const CtaButtons = ({ headline, description, children }: Props) => (
  <div className={styles.ctaButtons}>
    <h3>{headline}</h3>
    <p dangerouslySetInnerHTML={{ __html: description }} />
    <div className={styles.buttons}>{children}</div>
  </div>
);

export default CtaButtons;
