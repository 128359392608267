import React from 'react';
import ReactPlayer from 'react-player/lazy';

import { Readmore } from '../index';
import { isExternal, urlFor } from '../../lib/Link';

import thumbImage from '../../images/placeholder.gif';

import styles from './styles.module.scss';

interface Props {
  item: VideoItemFragment;
}

const VideoCard = ({ item }: Props) => {
  const title = item.title!;
  const topic = item.topic?.[0]?.title || '';

  const linkField = item?.linkField?.[0];
  const link = urlFor(linkField);
  const props = isExternal(linkField)
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {};

  return (
    <div className={styles.cardItem}>
      <div>
        <div className={styles.info}>
          <span>{topic}</span>
          <h3>
            <a href={link} {...props}>
              {title}
            </a>
          </h3>
        </div>
        <div className={styles.iframeVideo} aria-label={title}>
          <div>
            {item.videoUrl ? (
              <ReactPlayer url={item.videoUrl} width="100%" height="100%" />
            ) : (
              <img loading="lazy" src={thumbImage} alt={title} />
            )}
          </div>
        </div>
        <div className={styles.bottom}>
          <Readmore href={link} text={'Learn More'} {...props} />
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
