import React from 'react';

import { Button } from '../../../../index';

import styles from './styles.module.scss';

interface Props {
  content: string;
  closeText: string;
  onClose: () => void;
}

const ConfirmationStep = ({ content, closeText, onClose }: Props) => {
  return (
    <div className={styles.confirmationStep}>
      <div className={styles.body} dangerouslySetInnerHTML={{ __html: content || '' }} />
      <Button className={styles.close} onClick={onClose} type="orangeBorder" large>
        {closeText || 'Back to website'}
      </Button>
    </div>
  );
};

export default ConfirmationStep;
