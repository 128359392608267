import React from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

import CloseModalIcon from '../../images/icons/close-modal.svg';

import styles from './styles.module.scss';

interface Props {
  video: string;
  isOpen: boolean;
  onRequestClose: (e: React.MouseEvent | React.KeyboardEvent<Element>) => void;
}

const VideoModal = ({ video, isOpen, onRequestClose }: Props) => {
  return (
    <Modal
      className={styles.videoModal}
      isOpen={isOpen}
      style={VideoModal.style}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={onRequestClose}
    >
      <button className={styles.close} onClick={onRequestClose}>
        <CloseModalIcon />
      </button>
      <div className={styles.content}>
        <div className={styles.playerWrapper}>
          <ReactPlayer className={styles.player} url={video} playing={isOpen} controls width="100%" height="100%" />
        </div>
      </div>
    </Modal>
  );
};

VideoModal.style = {
  overlay: {
    backgroundColor: 'transparent',
  },
};

export default VideoModal;
