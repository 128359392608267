import React from 'react';

import query from '../../../../../graphql/NursingHomeApplication.graphql';
import { useSiteQuery } from '../../../../../hooks';

import { PrereqField } from '../index';

import formStyles from '../../nursingHomeForm.module.scss';

interface Props {
  showBg?: boolean;
  showDescription?: boolean;
}

// Used for displaying the 4 main file inputs required for the nursing home form
const MainFiles = ({ showBg = false, showDescription = false }: Props) => {
  const [{ data }] = useSiteQuery<NursingHomeApplicationQuery>({ query });
  const aboutBodyData = data?.entry?.__typename == 'pages_pages_Entry' && data?.entry?.pageContent?.[1]?.children;

  return (
    <div className={showBg ? formStyles.greyBoxMain : ''}>
      <PrereqField
        label="Letter from myagedcare"
        name="myAgedCare"
        text={showDescription ? aboutBodyData?.[0]?.body : ''}
      />
      <div className={formStyles.divider} />
      <PrereqField
        label="Letter from Enduring Power of Attorney and/or Guardianship"
        name="powerOfAttorney"
        text={showDescription ? aboutBodyData?.[1]?.body : ''}
        uploadText="Enduring Power of Attorney and/or Guardianship"
      />
      <div className={formStyles.divider} />
      <PrereqField
        label="Centrelink Assets & Income Assessment"
        name="incomeAssessment"
        text={showDescription ? aboutBodyData?.[2]?.body : ''}
      />
      <div className={formStyles.divider} />
      <PrereqField
        label="COVID-19 Vaccination Access Condition"
        name="vaccinationAccessCondition"
        text={showDescription ? aboutBodyData?.[3]?.body : ''}
      />
    </div>
  );
};

export default MainFiles;
