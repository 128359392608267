import React from 'react';
import cc from 'classcat';
import slugify from 'slugify';

import styles from './styles.module.scss';

interface Props {
  item: BioFragment;
  index: number;
}

const Bio = ({ item, index }: Props) => {
  const name = item?.name || '';
  const image = item?.photo?.[0]?.url;
  const slug = slugify(name!).toLowerCase();

  if (item) {
    return (
      <div id={`bio-${slug}`} className={cc([styles.bio, { [styles.even]: index % 2 === 0 }])}>
        <div className="row">
          <div className="col-lg-5">{image && <img loading="lazy" src={image} alt={name} />}</div>
          <div className="col-lg-7">
            <h2 className={styles.name}>
              {name} <span>{item.title!}</span>
            </h2>
            <p className="sectionHeading">{item.position!}</p>
            {item.content && <div className={styles.content} dangerouslySetInnerHTML={{ __html: item.content }} />}
          </div>
          <div className="col-lg-5">{image && <img loading="lazy" src={image} alt={name} />}</div>
        </div>
      </div>
    );
  }
  return null;
};

export default Bio;
