import React, { useState } from 'react';
import cc from 'classcat';

import { Button, Modal } from '../../index';
import ContactForm from '../../forms/ContactForm';

import EmailIcon from '../../../images/icons/email.svg';

import { ButtonType } from '../Button';

import styles from './styles.module.scss';

interface Props {
  overrideEmail?: string;
  text?: string;
  section?: string;
  name?: string;
  className?: string;
  type?: ButtonType;
}

const EmailButton = ({ overrideEmail, text, section, name, type = 'solidOrange', className }: Props) => {
  const [enquireModal, setEnquireModal] = useState<boolean>(false);
  const classNames = cc([styles.emailLink, className]);

  if (overrideEmail) {
    return (
      <Button href={`mailto: ${overrideEmail}`} className={classNames} type="link" large>
        {`Email: ${overrideEmail}`}
      </Button>
    );
  }

  return (
    <>
      <Button onClick={() => setEnquireModal(!enquireModal)} className={className} type={type} large>
        <EmailIcon />
        {text ? text : 'Send us an email'}
      </Button>
      <Modal isOpen={enquireModal} onRequestClose={() => setEnquireModal(false)} title={name}>
        <ContactForm section={section} title={name} />
      </Modal>
    </>
  );
};

export default EmailButton;
