import React from 'react';
import { observer } from 'mobx-react';

import { useSiteQuery, useStore } from '../../../hooks';

import query from './overview.graphql';

import styles from './styles.module.scss';

const CoursesOverview = () => {
  const store = useStore('coursesStore');
  const [{ data }] = useSiteQuery<OverviewQuery>({ query });

  if (store.category === '') {
    const overviewText = data?.overview?.simpleText || '';
    return <div className={styles.overview} dangerouslySetInnerHTML={{ __html: overviewText }} />;
  } else {
    const categories = data?.categories?.filter((category) => category?.slug === store.category) || [];
    if (categories.length) {
      const text = categories?.[0]?.simpleText || '';
      return <div className={styles.overview} dangerouslySetInnerHTML={{ __html: text }} />;
    }
  }

  return null;
};

export default observer(CoursesOverview);
