import React, { useCallback, useState } from 'react';
import cc from 'classcat';
import AnimateHeight from 'react-animate-height';
import Carousel from 'react-multi-carousel';
import { useWindowSize } from 'react-use';

import useStore from '../../hooks/useStore';

import Arrow from '../../images/icons/arrow-next.svg';
import TestimonialPlaceholder from '../../images/icons/testimonial-placeholder.svg';

import styles from './styles.module.scss';

interface Props {
  category: string;
  testimonials: Maybe<Array<Maybe<Pick<TestimonialsTestimonialsEntry, 'uid' | 'title' | 'description'>>>>;
}

const TestimonialsCarousel = ({ category, testimonials }: Props) => {
  const { siteName } = useStore('globals');

  const [commentExpanded, setCommentExpanded] = useState({});
  const [slider, setSlider] = useState<Nullable<Carousel>>(null);
  const [nextSlide, setNextSlide] = useState(0);
  const [commentHeights, setCommentHeights] = useState({});
  const windowSize = useWindowSize();

  const sliderRef = useCallback((node) => {
    setSlider(node);
  }, []);

  const commentRef = useCallback((node: Nullable<HTMLDivElement>) => {
    if (node) {
      setCommentHeights((current) => ({ ...current, [node.dataset.id || 'no-id']: node.clientHeight }));
    }
  }, []);

  const commentHeight = windowSize.width < 450 ? 200 : 120;

  const hasMultipleTestimonials = !!(testimonials && testimonials?.length > 1);
  const carouselProps = {
    ref: sliderRef,
    beforeChange: setNextSlide,
    infinite: hasMultipleTestimonials,
    centerMode: windowSize.width < 1024 ? false : hasMultipleTestimonials,
    draggable: hasMultipleTestimonials,
    swipeable: hasMultipleTestimonials,
    arrows: false,
    ssr: true,
    autoplay: false,
    shouldResetAutoplay: false,
    responsive: {
      all: {
        breakpoint: { min: 0, max: 10000 },
        items: 1,
        partialVisibilityGutter: 0,
      },
    },
  };

  function gotoNextSlide() {
    // Skip the placeholder slide if necessary
    if (testimonials && testimonials.length == 2 && nextSlide == 3) {
      slider!.goToSlide(nextSlide + 2);
    } else {
      slider!.next(0);
    }
  }

  if (testimonials) {
    return (
      <div className={cc(['container', 'testimonialsContainer'])}>
        <div className={styles.carousel}>
          <Carousel {...carouselProps}>
            {testimonials.map((t, i) => (
              <React.Fragment key={t?.uid}>
                <div
                  className={cc({
                    [styles.testimonial]: true,
                    [styles.single]: !hasMultipleTestimonials,
                  })}
                >
                  <div className={styles.photo}>
                    <TestimonialPlaceholder className={styles.placeholder} />
                  </div>

                  <AnimateHeight duration={200} height={commentExpanded[i] ? 'auto' : commentHeight}>
                    <div data-id={i} ref={commentRef}>
                      {t?.description}
                    </div>
                  </AnimateHeight>
                  {commentHeights[i] > commentHeight && (
                    <button onClick={() => setCommentExpanded({ ...commentExpanded, [i]: !commentExpanded[i] })}>
                      {commentExpanded[i] ? 'collapse' : 'read more'}
                    </button>
                  )}

                  <div className={styles.attribution}>
                    <strong>{t?.title}</strong>
                    <div>
                      On {siteName.replace('Amana Living Aged Care Services', 'Amana Living')}’s {category}
                    </div>
                  </div>
                </div>

                {testimonials.length > 1 ? (
                  <div
                    className={styles.next}
                    onClick={gotoNextSlide}
                    onKeyPress={gotoNextSlide}
                    role="button"
                    tabIndex={0}
                  >
                    Next <Arrow />
                  </div>
                ) : null}
              </React.Fragment>
            ))}
            {testimonials.length === 2 && <div />}
          </Carousel>
        </div>
      </div>
    );
  }
  return null;
};

export default TestimonialsCarousel;
