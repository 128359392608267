import React, { useState } from 'react';
import cc from 'classcat';

import { Modal } from '../../index';
import ContactForm from '../../forms/ContactForm';

import EmailIcon from '../../../images/icons/email.svg';

import styles from './styles.module.scss';

export type EmailType = 'white' | 'button' | 'buttonWhite' | 'buttonTransparent';

interface Props {
  text?: string;
  type?: EmailType;
  hideIcon?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const EmailLink = ({ text, type, hideIcon, className, children }: Props) => {
  const [enquireModal, setEnquireModal] = useState<boolean>(false);
  const displayedText = typeof text === 'undefined' ? 'Send us an email' : text;

  let classNames = cc({ [styles.emailLink]: true });
  // istanbul ignore else
  if (type && styles[type]) {
    classNames = cc([classNames, styles[type]]);
  }
  // istanbul ignore else
  if (className) {
    classNames = cc([classNames, className]);
  }

  return (
    <>
      <button onClick={() => setEnquireModal(!enquireModal)} className={classNames}>
        {children}
        {!children && (
          <>
            {!hideIcon && <EmailIcon />}
            {displayedText}
          </>
        )}
      </button>
      <Modal isOpen={enquireModal} onRequestClose={() => setEnquireModal(false)}>
        <ContactForm />
      </Modal>
    </>
  );
};

export default EmailLink;
