import React from 'react';
import cc from 'classcat';

import Image from '../../layout/Image';

import styles from './styles.module.scss';

interface Props {
  item: ImageFragment;
}

const ImageBlock = ({ item }: Props) => {
  const fillColumn = item.fillColumn;
  const images = item.image;
  const title = item.image?.[0]?.title || undefined;

  if (images) {
    return (
      <div className={cc([styles.imageBlock, { [styles.fillColumn]: fillColumn, [styles.noMargin]: item.noMargin }])}>
        <Image {...images[0]} alt={title} />
      </div>
    );
  }
  return null;
};

export default ImageBlock;
