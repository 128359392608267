import React from 'react';
import cc from 'classcat';
import { Field, FieldAttributes, useFormikContext } from 'formik';

import styles from './styles.module.scss';

interface Props {
  [key: string]: any;
  isInvalid?: boolean;
  required?: boolean;
  children?: React.ReactNode;
}

const FormikField = ({ isInvalid, required, className, children, ...props }: FieldAttributes<Props>) => {
  const { errors, touched, values } = useFormikContext<Record<string, unknown>>();

  const invalid = isInvalid || (errors[props.name] && touched[props.name]);
  return (
    <div
      className={cc({
        [styles.field]: true,
        [styles.invalid]: invalid,
        [className || '']: className,
      })}
    >
      <Field value={values[props.name] || ''} {...props} invalid={invalid && 'true'} required={required} />
      {children}
    </div>
  );
};

export default FormikField;
