import React, { useMemo } from 'react';
import Spinner from '@atlaskit/spinner';
import { useFormikContext } from 'formik';

import { useStore } from '../../../../../hooks';

import Tick from '../../../../../images/icons/tick.svg';

import FakePageModal from '../FakePageModal';
import InputField from '../InputField';

import styles from './saveModal.module.scss';

interface Props {
  isOpen: boolean;
  isSaving: 'saving' | 'saved' | 'failed' | 'exit' | '';
  continueLink?: string;
  onRequestClose: () => void;
  onConfirmSave: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  setClosed: () => void;
  setHelpOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SaveModal = ({
  isOpen,
  isSaving,
  continueLink,
  onRequestClose,
  onConfirmSave,
  setClosed,
  setHelpOpen,
}: Props) => {
  const { values, errors } = useFormikContext<NursingHomeApplicationInterface>();
  const { siteName, invertedLogo } = useStore('globals');

  const title = useMemo(() => {
    switch (isSaving) {
      case 'saving':
        return 'Saving Form...';
      case 'saved':
        return 'Form Successfully Saved';
      case 'failed':
        return 'Form Save Failed';
      default:
        return 'Save your progress and exit?';
    }
  }, [isSaving]);

  const closeSaved = () => {
    setClosed();
    if (typeof window !== 'undefined' && continueLink) {
      return window.location.assign(continueLink);
    }
  };

  return (
    <FakePageModal isOpen={isOpen} onRequestClose={onRequestClose} title={title}>
      <>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <a href="/" className={styles.logo}>
              <img src={invertedLogo} alt={siteName} />
            </a>
            <h1 className={styles.title}>Nursing Home Application</h1>
          </div>
          <div className={styles.headerRight}>
            <button className={styles.hollowBtn} onClick={() => setHelpOpen(true)}>
              Help
            </button>
            <button className={styles.activeBtn} onClick={() => closeSaved()}>
              Back to Application
            </button>
          </div>
        </div>
        {!isSaving && (
          <>
            <p className={styles.info}>
              We can send you a unique link to your email that will allow you finish your order when you feel like it.
            </p>
            <InputField label="Email" name="emailTo" type="email" placeholder="email@domain.com" stretch />
            <div className={styles.btnOptions}>
              <button
                className={`${styles.activeBtn} ${((!!errors.emailTo || !values.emailTo) && styles.disabled) || ''}`}
                onClick={onConfirmSave}
                disabled={!!errors.emailTo || !values.emailTo}
              >
                Send unique link and quit
              </button>
            </div>
          </>
        )}
        {isSaving == 'saved' && (
          <div className={styles.center}>
            <Tick className={styles.tick} />
            <p className={styles.success}>
              Your form has been successfully saved! <br /> You can now close the window
            </p>
            {continueLink && (
              <button
                className={`${styles.activeBtn} ${styles.removeMargin}`}
                onClick={() => typeof window !== 'undefined' && window.location.assign(continueLink)}
              >
                Continue Editing?
              </button>
            )}
          </div>
        )}
        {isSaving == 'failed' && (
          <div>
            <p>Unfortunately your form hasn&apos;t had an error while saving, we have contacted our developers</p>
          </div>
        )}
        {isSaving == 'saving' && (
          <div className={styles.spinnerWrapper}>
            <Spinner />
          </div>
        )}
      </>
    </FakePageModal>
  );
};

export default SaveModal;
