import { Severity, captureMessage, setExtras } from 'isomorphic-sentry';

import CSRF from './Csrf';
import {getServerAddress} from "../lib/isBrowser";

export const uploadImage = async (file: File) => {
  try {
    const formData = new FormData();
    const csrf = await CSRF.getCsrf();
    formData.append('CRAFT_CSRF_TOKEN', csrf);
    formData.append('image', file);
    const response = await fetch(`${getServerAddress()}/api/asset/uploadOne`, {
      method: 'POST',
      credentials: 'include',
      body: formData,
      headers: {
        'X-CSRF-TOKEN': csrf || '',
        'origin': window?.location?.host || 'server',
      },
    }).then((r) => r.json());

    return response;
  } catch (error) {
    setExtras({ error });
    captureMessage('Failed to init vehicles in progress', Severity.Warning);
    return { error: 'Failed to upload file' };
  }
};
