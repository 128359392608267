import React, { useCallback } from 'react';
import cc from 'classcat';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';

import { Heading } from '../../../Wizard';
import { QuestionnaireFormValues, StepComponent } from '../../../../../typings';

import styles from './styles.module.scss';

interface Props {
  question: QuestionFragment;
  questionIndex: number;
}

const QuestionStep: StepComponent = ({ question, questionIndex }: Props) => {
  const { setFieldValue, values } = useFormikContext<QuestionnaireFormValues>();

  const updateAnswer = useCallback(
    (value: string, idx: number) => {
      setFieldValue(`answers[${idx}]`, value);
    },
    [setFieldValue],
  );

  if (question && question.children) {
    return (
      <>
        <Heading>{question.title}</Heading>
        <fieldset className={styles.step}>
          <div className={cc({ [styles.multi]: question.children.length > 4 })}>
            {question.children.map(
              (answer, ansIdx) =>
                answer && (
                  <div key={answer.uid} className={cc({ [styles.radio]: true, [styles.location]: answer.subText })}>
                    <input
                      id={`answer-${ansIdx}`}
                      name={`answer-${question.uid}`}
                      type="radio"
                      value={answer.title!}
                      onChange={(e) => updateAnswer(e.target.value, questionIndex)}
                      checked={values.answers[questionIndex] == answer.title!}
                      required
                    />
                    <label htmlFor={`answer-${ansIdx}`}>
                      {answer.title!}
                      {answer.subText && <span>{answer.subText}</span>}
                    </label>
                  </div>
                ),
            )}
          </div>
        </fieldset>
      </>
    );
  }
  return (
    <fieldset className={styles.step}>
      <Heading>{question.title}</Heading>
    </fieldset>
  );
};

QuestionStep.label = 'Question Step';
QuestionStep.validationSchema = Yup.object().shape({});

export default QuestionStep;
