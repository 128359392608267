import React from 'react';

import { CardItem } from '../../index';
import { useSiteQuery } from '../../../hooks';

import query from './information.graphql';

import styles from './styles.module.scss';

interface Props {
  fragment: InformationGuidesBlockFragment;
}

const InformationGuides = ({ fragment }: Props) => {
  const title = fragment?.topic?.[0]?.title;
  const [{ data }] = useSiteQuery<InformationQuery>({
    query,
    variables: {
      search: title ? `topic:${title}` : '',
    },
  });
  const items = data?.items;

  if (items) {
    return (
      <div id="information-guides" className={styles.information}>
        <div className="container">
          <h2 className="sectionTitle">{title} Information Guides</h2>
          <div className="row childrenAsSlider">
            {items.map((article) => article && <CardItem key={article.uid!} item={article} />)}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default InformationGuides;
