import React, { useCallback, useState } from 'react';
import cc from 'classcat';
import AnimateHeight from 'react-animate-height';

import ArrowDownIcon from '../../images/icons/arrow-down.svg';
import ArrowUpIcon from '../../images/icons/arrow-up.svg';
import ExploreIcon from '../../images/icons/explore-connect.svg';

import styles from './styles.module.scss';

interface Props {
  customTitle?: string;
  current:
    | RetirementVillageLocationFragment
    | NursingHomeLocationFragment
    | OtherServiceLocationFragment
    | null
    | undefined;
  all:
    | Maybe<RetirementVillageLocationFragment | NursingHomeLocationFragment | OtherServiceLocationFragment>[]
    | null
    | undefined;
}

const LocationsInSection = ({ customTitle, current, all }: Props) => {
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => {
    setActive(!active);
  }, [active]);

  if (all?.length) {
    return (
      <div className={styles.pageInSection}>
        <div className="container">
          <div className={styles.inner}>
            <ExploreIcon className={styles.connect} />
            <div className={styles.toggle} role="button" tabIndex={0} onClick={toggleActive} onKeyPress={toggleActive}>
              {customTitle ? customTitle : 'EXPLORE THIS SECTION'}
              {active ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </div>
            <AnimateHeight duration={200} height={active ? 'auto' : 0}>
              <div className={cc([styles.sections, { [styles.active]: active }])}>
                {all.map(
                  (item) =>
                    item?.uri !== current?.uri && (
                      <div key={item?.title} className={styles.link}>
                        <a href={`/${item?.uri}`}>{item?.title}</a>
                        <p>{item?.address?.parts?.city}</p>
                      </div>
                    ),
                )}
              </div>
            </AnimateHeight>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default LocationsInSection;
