import React, { useEffect, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import scrollIntoView from 'scroll-into-view';
import slugify from 'slugify';

import { Team, TeamsCarousel } from '../index';
import { useSiteQuery } from '../../hooks';
import ErrorPage from '../../pages/_error';

import query from './teams.graphql';

const TeamsSection = () => {
  const [{ data, error }] = useSiteQuery<TeamsQuery>({ query });
  const [active, setActive] = useState<Nullable<TeamFragment>>(null);
  const ref = useRef<HTMLDivElement>(null);

  useEffectOnce(() => {
    if (process.browser && ref.current) {
      const hash = window.location.hash.substring(1);
      if (hash) {
        scrollIntoView(ref.current, { align: { top: 0, topOffset: 120 } });
      }
    }
  });

  useEffect(() => {
    if (data?.teams?.length) {
      const hash = window.location.hash.substring(1);
      let founded = false;
      if (hash) {
        for (let i = 0; i < data.teams.length; i++) {
          const team = data?.teams?.[i];
          if (team) {
            const slug = slugify(team?.title || '').toLowerCase();
            if (hash === slug) {
              founded = true;
              setActive(data.teams[i]);
            }
          }
        }
      }
      if (!founded) {
        setActive(data.teams[0]);
      }
    }
  }, [data]);

  if (error) {
    return <ErrorPage statusCode={500} err={error} />;
  }

  const teams = data?.teams;
  if (teams) {
    return (
      <div ref={ref} className="container">
        <TeamsCarousel items={teams} currentActive={active} onClick={(item: TeamFragment) => item && setActive(item)} />
        {active && <Team item={active} />}
      </div>
    );
  }

  return null;
};

export default TeamsSection;
