import React from 'react';
import { useFormikContext } from 'formik';

import { useIsMobile } from '../../../../../hooks';

import { FileField, NextStepField, RadioField } from '../../Fields';

import formStyles from '../../nursingHomeForm.module.scss';

interface Props {
  setStep: (step: number) => void;
}

const Attorney = ({ setStep }: Props) => {
  const { values } = useFormikContext<NursingHomeApplicationInterface>();
  const [isMobile] = useIsMobile(1000);

  return (
    <>
      <div>
        {!isMobile && <p className={formStyles.headingL}>Enduring Power of Attorney</p>}
        <RadioField label="Same as next of kin/nominate representative" name="powerOfAttorney_sameAs_nok" stretch />
        {values.powerOfAttorney_sameAs_nok == 'no' && (
          <FileField name="powerOfAttorney" label="Enduring Power of Attorney" />
        )}
      </div>
      <NextStepField setStep={setStep} stepNum={4} fieldsToCheck={['powerOfAttorney_sameAs_nok']} />
    </>
  );
};

export default Attorney;
