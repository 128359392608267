import React from 'react';

interface Props {
  item: IconFragment;
}

const IconBlock = ({ item }: Props) => {
  const url = item.icon?.[0]?.url;
  const title = item.icon?.[0]?.title || undefined;

  if (url) {
    return (
      <div className="iconBlock">
        <img src={url} alt={title} />
      </div>
    );
  }
  return null;
};

export default IconBlock;
