import React, { useRef, useState } from 'react';

import { Button } from '../../index';
import { useIsMobile } from '../../../hooks';
import { getAllPathways, getNextNodes, getPathwayDescription, getPreviousNodes, getTeamLink } from '../../../lib/FunctionLib';

import PathwayItem from './item';

import styles from './styles.module.scss';

interface Props {
  entry: PathwayFragment;
}

const Pathway = ({ entry }: Props) => {
  // active first node on start
  const [activeItem, setActiveItem] = useState<string>('1-1');
  const [isMobile] = useIsMobile(768);
  const ref = useRef<HTMLDivElement>(null);
  const pathways = entry?.pathways || [];

  const onChange = (level: number, node: string) => {
    setActiveItem(node);

    // scroll to element
    if (isMobile && ref.current) {
      const elmWidth = 150;
      const clientWidth = ref.current.clientWidth;
      const offset = (level - 1) * elmWidth - (clientWidth - elmWidth) / 2;
      if (offset < 0) {
        ref.current.scrollLeft = 0;
      } else if (offset > pathways.length * elmWidth - clientWidth) {
        ref.current.scrollLeft = pathways.length * elmWidth - clientWidth;
      } else {
        ref.current.scrollLeft = offset;
      }
    }
  };

  if (pathways) {
    const allPaths: string[] = getAllPathways(entry);
    const nexts: string[] = getNextNodes(allPaths, activeItem);
    const previous: string[] = getPreviousNodes(allPaths, activeItem);

    return (
      <div id={entry.uid!} className="container">
        <div className={styles.pathway}>
          <h2>{entry.title}</h2>
          <div className={styles.pathwayWrapper}>
            <div ref={ref} className={styles.pathwayContainer}>
              {pathways.map(
                (p, level) =>
                  p && (
                    <PathwayItem
                      key={p.uid}
                      item={p}
                      level={level + 1}
                      currentActive={activeItem}
                      nexts={nexts}
                      previous={previous}
                      onClick={(level: number, node: string) => level && node && onChange(level, node)}
                    />
                  ),
              )}
            </div>
          </div>
        </div>
        <div className={styles.description}>
          <div className="teamBody">
            <div dangerouslySetInnerHTML={{ __html: getPathwayDescription(entry, activeItem) }} />
            {getTeamLink(entry, activeItem) && (
              <Button type="link" href={getTeamLink(entry, activeItem)} includeArrow>
                Learn more
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default Pathway;
