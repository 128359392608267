export function getServicesTypes(services: Maybe<ServicesCategoryFragment>[] | null | undefined) {
  const res: ServicesTypesFragment[] = [];
  if (services) {
    for (let i = 0; i < services.length; i++) {
      if (services[i]?.serviceType?.[0]) {
        const serviceType = services[i]?.serviceType?.[0];
        if (serviceType && res.filter((elem) => elem.title === serviceType.title!).length === 0) {
          res.push(serviceType);
        }
      }
    }
  }
  return res;
}

export function getServicesByType(services: Maybe<ServicesCategoryFragment>[] | null | undefined, type: string) {
  if (services) {
    return services.filter((service) => service?.serviceType?.[0]?.title === type);
  }
  return [];
}

export function getLocationTitle(
  entry: RetirementVillageLocationFragment | NursingHomeLocationFragment | OtherServiceLocationFragment,
) {
  return `${entry.title!}, ${entry.address?.parts?.city}`;
}

export function getRetirementVillageTitleByUnit(entry: UnitFragment) {
  const title = entry.retirementVillageLocation?.[0]?.title;
  const city = entry.retirementVillageLocation?.[0]?.address?.parts?.city;
  return `${title}, ${city}`;
}

export function getPriceFormat(price: number, fixed = 0) {
  if (price) {
    return '$' + price?.toFixed(fixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return '$0';
}

export function getUnitPriceFormat(price: number, fixed = 0) {
  if (price) {
    return '$' + price?.toFixed(fixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return 'Coming Soon';
}

export function getAllPathways(entry: PathwayFragment) {
  if (entry && entry?.pathways?.length) {
    const pathways: PathwayItemFragment[] = [];
    for (let i = 0; i < entry?.pathways?.length; i++) {
      if (entry?.pathways[i]) {
        pathways.push(entry?.pathways[i] as PathwayItemFragment);
      }
    }

    const allPaths: string[] = [];
    for (let i = 0; i < pathways.length; i++) {
      const pathway = pathways?.[i]?.children || [];
      for (let j = 0; j < pathway.length; j++) {
        if (pathway?.[j]?.pathwaysChilds) {
          pathway?.[j]?.pathwaysChilds?.split(',').map((child) => allPaths.push(`${i + 1}-${j + 1},${i + 2}-${child}`));
        }
        if (pathway?.[j]?.forceNext) {
          allPaths.push(`${i + 1}-${j + 1},${i + 3}-1`);
        }
      }
    }
    return allPaths;
  }
  return [];
}

export function getNextNodes(allPaths: string[], currentActive: string) {
  if (currentActive) {
    return allPaths.filter((p) => p.startsWith(currentActive)).map((p) => p.replace(`${currentActive},`, ''));
  }

  return [];
}

export function getPreviousNodes(allPaths: string[], currentActive: string) {
  if (currentActive) {
    return allPaths.filter((p) => p.endsWith(currentActive)).map((p) => p.replace(`,${currentActive}`, ''));
  }

  return [];
}

export function getPathwayDescription(entry: PathwayFragment, currentActive: string) {
  if (entry && entry?.pathways?.length) {
    const actives = currentActive.split('-');
    if (actives.length === 2) {
      const level = Number(actives[0]) - 1;
      const index = Number(actives[1]) - 1;
      return entry?.pathways?.[level]?.children?.[index]?.description || '';
    }
  }

  return '';
}

export function getTeamLink(entry: PathwayFragment, currentActive: string) {
  if (entry && entry?.pathways?.length) {
    const actives = currentActive.split('-');
    if (actives.length === 2) {
      const level = Number(actives[0]) - 1;
      const index = Number(actives[1]) - 1;

      const node = entry?.pathways?.[level]?.children?.[index];
      if (node?.teamEntry?.[0]?.slug) {
        const slug = node?.teamEntry?.[0]?.slug;
        return `/teams#${slug}`;
      }
      return '';
    }
  }

  return '';
}