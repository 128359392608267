import React from 'react';
import cc from 'classcat';

import { useStore } from '../../../hooks';

import PhoneIcon from '../../../images/icons/phone.svg';

import styles from './styles.module.scss';

export type PhoneStyle = 'white' | 'button' | 'buttonWhite' | 'buttonTransparent';

interface Props {
  text?: string;
  number?: string;
  type?: PhoneStyle;
  hideIcon?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const PhoneLink = ({ text, number, type, hideIcon, className, children }: Props) => {
  const globals = useStore('globals');
  const phone = number || globals.phone || null;
  const displayedText = typeof text === 'undefined' ? 'Call us' : text;

  let classNames = cc({ [styles.phoneLink]: true });
  if (type && styles[type]) {
    classNames = cc([classNames, styles[type]]);
  }
  if (className) {
    classNames = cc([classNames, className]);
  }

  return phone ? (
    <a href={`tel:${phone.replace(/[^\d\+]/g, '')}`} className={classNames}>
      {children}
      {!children && (
        <>
          {!hideIcon && <PhoneIcon />}
          {displayedText}&nbsp;<strong>{phone}</strong>
        </>
      )}
    </a>
  ) : null;
};

export default PhoneLink;
