import React, { useCallback, useRef, useState } from 'react';
import cc from 'classcat';
import { observer } from 'mobx-react';
import Modal from 'react-modal';
import Spinner from '@atlaskit/spinner';
import { Formik, FormikHelpers } from 'formik';
import scrollIntoView from 'scroll-into-view';

import { Button } from '../../index';
import MicroHeader from '../../layout/Header/MicroHeader';
import { useIsMobile } from '../../../hooks';
import { isBrowser } from '../../../lib/Device';
import { StepComponent } from '../../../typings';

import Arrow from '../../../images/icons/arrow-next-short.svg';

import Error from './Error';
import Heading from './Heading';
import Step from './Step';

import styles from './styles.module.scss';

interface Props<T> {
  initialValues: T;
  onClose: () => void;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => Promise<boolean>;
  steps: StepComponent[];
  stepProps?: any[];
  hideSteps?: boolean;
  customFinalSubmit?: boolean;
  finalSubmitText?: string;
  confirmationStep?: (props: any) => JSX.Element;
  confirmationStepProps?: any;
  children?: React.ReactNode;
  title: string;
  [key: string]: unknown;
  honeyPot: FreeformHoneypotInterface | null | undefined;
}

function Wizard<T>({
  initialValues,
  onClose,
  onSubmit,
  steps,
  stepProps = [],
  confirmationStep,
  confirmationStepProps,
  customFinalSubmit,
  finalSubmitText,
  children,
  title,
  honeyPot
}: Props<T>) {
  const [page, setPage] = useState(0);
  const [isMobile] = useIsMobile(1024);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [error, setError] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const scrollToTop = useCallback(() => {
    if (isBrowser && ref.current) {
      scrollIntoView(ref.current, { time: 250, align: { top: 0, topOffset: isMobile ? 40 : 0 } });
    }
  }, [isMobile]);

  function next() {
    setPage(Math.min(page + 1, steps.length - 1));
    scrollToTop();
  }

  async function handleSubmit(currentValues: T, formikHelpers: FormikHelpers<T>) {
    const isLastPage = page == steps.length - 1;

    if (isLastPage) {
      const success = await onSubmit(currentValues, formikHelpers);
      if (success && confirmationStep) {
        setShowConfirmation(true);
      } else {
        setError(true);
      }

      formikHelpers.setSubmitting(false);
    } else {
      formikHelpers.setTouched({});
      formikHelpers.setSubmitting(false);
      next();
    }
  }

  const ActivePage = steps[page];
  const ConfirmationStep = confirmationStep;
  const isLastPage = page === steps.length - 1;
  const showStep = !error && !showConfirmation;

  return (
    <Modal className={styles.modal} overlayClassName={styles.modalOverlay} isOpen={true}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={false}
        validationSchema={ActivePage.validationSchema}
        onSubmit={handleSubmit}
        validateOnChange
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            <form onSubmit={handleSubmit} className={cc({ [styles.wizard]: true, [styles.submitting]: isSubmitting })}>
              <div className={styles.progress}>
                <MicroHeader onClick={onClose} title={title} />
                {showStep && (
                  <div className={cc({ container: true, [styles.stepRenderer]: true })}>
                    <p>
                      Question {page + 1} of {steps.length}
                    </p>
                  </div>
                )}

                {children}
              </div>

              <div className="container" ref={ref}>
                <div className={styles.stepContainer}>
                  {error ? (
                    <Error />
                  ) : showConfirmation && ConfirmationStep ? (
                    <ConfirmationStep {...confirmationStepProps} />
                  ) : (
                    <>
                      <ActivePage {...stepProps[page]} />
                      {(isLastPage && honeyPot) && (
                          <div>
                              <input type={'hidden'} name={honeyPot?.name || ''} value={honeyPot?.value || ''}/>
                          </div>
                      )}
                      {(!isLastPage || !customFinalSubmit) && (
                        <div className={styles.submit}>
                          <Button as="submit" disabled={isSubmitting} className={styles.next} type="orangeBorder" large>
                            {isLastPage && finalSubmitText ? finalSubmitText : 'Next'}
                            <Arrow />
                          </Button>
                        </div>
                      )}
                    </>
                  )}

                  <div className={styles.spinnerContainer}>
                    <div className={cc({ [styles.spinner]: true, [styles.visible]: isSubmitting })}>
                      <Spinner size="xlarge" isCompleting={!isSubmitting} />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </Modal>
  );
}

Wizard.Page = ({ children }: { children: JSX.Element }) => children;

export default observer(Wizard);

export { Heading, Step };
