export function trackEvent(eventCategory: string, eventAction: string, eventLabel = '') {
  const gWindow: (typeof window & { dataLayer?: Array<any> }) | undefined = window;
  if (gWindow && gWindow.dataLayer) {
    gWindow.dataLayer.push({
      event: 'GAEvent',
      eventCategory,
      eventAction,
      eventLabel,
    });
  }
}
