
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LinkEntry"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"linkField_entry_BlockType"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"typeHandle"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"entryLink"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"uri"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sectionHandle"},"arguments":[],"directives":[]}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LinkUrl"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"linkField_url_BlockType"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"typeHandle"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"urlLink"},"arguments":[],"directives":[]}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LinkProtocol"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"linkField_protocol_BlockType"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"typeHandle"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"protocolLink"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":274}};
    doc.loc.source = {"body":"fragment LinkEntry on linkField_entry_BlockType {\n  typeHandle\n  entryLink {\n    id\n    uri\n    sectionHandle\n  }\n}\n\nfragment LinkUrl on linkField_url_BlockType {\n  typeHandle\n  urlLink\n}\n\nfragment LinkProtocol on linkField_protocol_BlockType {\n  typeHandle\n  protocolLink\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
