import React from 'react';
import cc from 'classcat';

import Arrow from '../../../images/icons/arrow-next.svg';

import styles from './styles.module.scss';

export type ButtonType =
  | 'link'
  | 'linkWhite'
  | 'whiteBorder'
  | 'solidOrange'
  | 'orangeBorder'
  | 'solidPurple'
  | 'purpleBorder';

interface BaseProps {
  includeArrow?: boolean;
  large?: boolean;
  disabled?: boolean;
  submitting?: boolean;
  type?: ButtonType;
  children?: React.ReactNode;
  className?: string;
  roundCorners?: boolean;
}

interface AnchorProps
  extends Omit<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'type'> {
  href: string;
  onClick?: never;
  as?: never;
}

interface ButtonProps
  extends Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'type'> {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  href?: never;
  as?: string;
}

interface SubmitButtonProps
  extends Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'type'> {
  as: 'submit';
  onClick?: never;
  href?: never;
}

type Props = (AnchorProps | ButtonProps | SubmitButtonProps) & BaseProps;

const Button = ({
  includeArrow,
  large,
  disabled,
  submitting,
  type,
  as,
  children,
  className,
  roundCorners,
  ...props
}: Props) => {
  if (disabled || submitting) {
    delete props.onClick;
  }

  const Wrapper = props.href ? 'a' : 'button';

  let classNames = cc({
    [styles.button]: true,
    [styles.disabled]: disabled,
    [styles.large]: large,
    [styles.submitting]: submitting,
    [styles.roundCorners]: roundCorners,
  });
  if (type && styles[type]) {
    classNames = cc([classNames, styles[type]]);
  }
  if (className) {
    classNames = cc([classNames, className]);
  }

  return (
    <Wrapper
      type={as || 'button'}
      role="button"
      tabIndex={0}
      className={classNames}
      disabled={disabled}
      {...(props as any)}
    >
      {children}
      {includeArrow && <Arrow className={styles.nextArrow} />}
    </Wrapper>
  );
};

export default Button;
