import React from 'react';

import styles from './styles.module.scss';

const Error = () => (
  <div className={styles.error}>
    <h3>Something went wrong</h3>
    <p>please try again later</p>
  </div>
);

export default Error;
