import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-modal';
import Carousel from 'react-multi-carousel';
import { getOriginalCounterPart } from 'react-multi-carousel/lib/utils';

import CloseModalIcon from '../../images/icons/close-modal.svg';
import RotateIndicator from '../../images/icons/rotate-indicator.svg';

import Image from '../layout/Image';

import styles from './styles.module.scss';

interface Props {
  photos: AssetFragment[];
  handleClose: (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
}

const PhotoModal = ({ photos, handleClose }: Props) => {
  const mains = useMemo(
    () =>
      photos.slice(1).map((p: AssetFragment) => (
        <div key={`photo-${p.url}`}>
          <img src={p.url!} alt={p.title!} width={p.width!} height={p.height!} loading="lazy" />
        </div>
      )),
    [photos],
  );

  const thumbs = useMemo(() => {
    return photos.map((p) => <div key={`thumb-${p.url}`} style={{ backgroundImage: `url(${p.url})` }} />);
  }, [photos]);

  const [isFirstSlide, setFirstSlide] = useState(true);
  const [slider, setSlider] = useState<Nullable<Carousel>>(null);
  const [sliderOffset, setSliderOffset] = useState(0);
  const [thumbSlider, setThumbSlider] = useState<Nullable<Carousel>>(null);
  const [thumbOffset, setThumbOffset] = useState(0);

  const sliderRef = useCallback((node) => setSlider(node), []);
  const thumbsRef = useCallback((node) => setThumbSlider(node), []);

  const responsive = useMemo(
    () => ({
      all: {
        breakpoint: { min: 0, max: 10000 },
        items: 1,
      },
    }),
    [],
  );

  const responsiveThumbs = useMemo(
    () => ({
      super: {
        breakpoint: { min: 2048, max: 10000 },
        items: Math.min(photos.length, 9),
      },
      extra: {
        breakpoint: { min: 1728, max: 2047 },
        items: Math.min(photos.length, 8),
      },
      large: {
        breakpoint: { min: 1536, max: 1727 },
        items: Math.min(photos.length, 7),
      },
      desktop: {
        breakpoint: { min: 1024, max: 1535 },
        items: Math.min(photos.length, 6),
      },
      default: {
        breakpoint: { min: 1024, max: 1023 },
        items: Math.min(photos.length, 3),
      },
    }),
    [photos],
  );

  const setOffsets = useCallback(
    (carousel: Carousel, offset: number) => {
      if (isFirstSlide) {
        offset = carousel.state.currentSlide;
        setSliderOffset(slider!.state.currentSlide);
        setThumbOffset(thumbSlider!.state.currentSlide);
        setFirstSlide(false);
      }

      return offset;
    },
    [isFirstSlide, slider, thumbSlider],
  );

  const syncSlider = useCallback(
    (nextSlide: number) => {
      const offset = setOffsets(thumbSlider!, thumbOffset);

      const index = nextSlide - offset;
      const sliderIndex = getOriginalCounterPart(index, slider!.state, React.Children.toArray(slider!.props.children));

      if (slider!.state.currentSlide !== sliderIndex) {
        slider!.goToSlide(sliderIndex, true);
      }
    },
    [setOffsets, slider, thumbOffset, thumbSlider],
  );

  const syncThumbSlider = useCallback(
    (nextSlide: number) => {
      const offset = setOffsets(slider!, sliderOffset);

      const index = nextSlide - offset;
      const sliderIndex = getOriginalCounterPart(
        index,
        thumbSlider!.state,
        React.Children.toArray(thumbSlider!.props.children),
      );

      if (thumbSlider!.state.currentSlide !== sliderIndex) {
        thumbSlider!.goToSlide(sliderIndex, true);
      }
    },
    [setOffsets, slider, sliderOffset, thumbSlider],
  );

  const carouselProps = useMemo(
    () => ({
      ref: sliderRef,
      infinite: true,
      responsive,
      beforeChange: syncThumbSlider,
      containerClass: styles.list,
      sliderClass: styles.track,
      itemClass: styles.item,
      autoplay: false,
      shouldResetAutoplay: false,
    }),
    [responsive, sliderRef, syncThumbSlider],
  );

  const thumbCarouselProps = useMemo(
    () => ({
      ref: thumbsRef,
      infinite: true,
      responsive: responsiveThumbs,
      beforeChange: syncSlider,
      arrows: false,
      draggable: false,
      swipeable: false,
      keyBoardControl: false,
      partialVisible: true,
      focusOnSelect: true,
      autoplay: false,
      shouldResetAutoplay: false,
      containerClass: styles.list,
      sliderClass: styles.track,
      itemClass: styles.item,
    }),
    [responsiveThumbs, syncSlider, thumbsRef],
  );

  if (!photos.length) {
    return null;
  }

  return (
    <Modal
      className={styles.photoModal}
      isOpen={true}
      style={PhotoModal.style}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={handleClose}
    >
      <button className={styles.closeIcon} onClick={handleClose}>
        <CloseModalIcon />
      </button>
      <div>
        <div className={styles.slider}>
          <Carousel {...carouselProps}>
            <div>
              <Image {...photos[0]} />
            </div>
            {mains}
          </Carousel>
        </div>

        <div className={styles.thumbs}>
          <Carousel {...thumbCarouselProps}>{thumbs}</Carousel>
        </div>
      </div>
      <div className={styles.rotateIndicator}>
        <RotateIndicator />
      </div>
    </Modal>
  );
};

PhotoModal.style = {
  overlay: {
    backgroundColor: 'transparent',
  },
};

export default PhotoModal;
