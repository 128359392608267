import React from 'react';
import cc from 'classcat';

import Checklist from '../../../images/icons/checklist.svg';

import styles from './styles.module.scss';

interface Props {
  text: string;
  columnClass?: string;
}

const ChecklistBlock = ({ text, columnClass }: Props) => (
  <div className={columnClass}>
    <div className={cc([styles.checklist, { [styles.displayAsColumn]: columnClass }])}>
      <Checklist />
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  </div>
);

export default ChecklistBlock;
