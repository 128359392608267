import React from 'react';
import { GetHandleProps, SliderItem } from 'react-compound-slider';

import styles from './handle.module.scss';

interface HandleProps {
  domain: readonly number[] | undefined;
  handle: SliderItem;
  getHandleProps: GetHandleProps;
}

const Handle = ({ domain, handle: { id, value, percent }, getHandleProps }: HandleProps) => (
  <>
    <div
      className={styles.handleClickArea}
      style={{
        left: `${percent}%`,
      }}
      {...getHandleProps(id)}
    />
    <div
      role="slider"
      aria-valuemin={domain?.[0]}
      aria-valuemax={domain?.[1]}
      aria-valuenow={value}
      className={styles.handleKnob}
      style={{ left: `${percent}%` }}
    />
  </>
);

export default Handle;
