import React, { useCallback, useState } from 'react';
import { format, getDay, isFuture } from 'date-fns';

import { Button, Modal } from '../../index';
import ContactForm from '../../forms/ContactForm';
import InfoSessionsForm from '../../forms/InfoSessionsForm';
import { useSiteQuery } from '../../../hooks';

import DateIcon from '../../../images/icons/date.svg';
import PinIcon from '../../../images/icons/pin.svg';
import TimeIcon from '../../../images/icons/time.svg';

import query from './infoSessions.graphql';

import styles from './styles.module.scss';

interface Props {
  className?: string;
}

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const InfoSessionsButton = ({ className }: Props) => {
  const [{ data }] = useSiteQuery<InfoSessionsQuery>({ query });
  const [infoSessionModal, setInfoSessionModal] = useState<boolean>(false);
  const [enquireModal, setEnquireModal] = useState<boolean>(false);

  const emailClick = useCallback(() => {
    setInfoSessionModal(false);
    setEnquireModal(true);
  }, []);

  if (data?.entry?.infoSessionsDetails?.length) {
    const rows: InfoSessionsDetailsTableRow[] = [];
    const dates: string[] = [];
    data.entry.infoSessionsDetails.map((r) => {
      if (r && isFuture(new Date(r.date))) {
        rows.push(r);
        dates.push(format(new Date(r.date), 'do MMMM yyyy'));
      }
    });
    const info: InfoSessionsDetailsTableRow = rows?.[0];

    if (info) {
      const date = new Date(info.date);
      return (
        <div className={styles.event}>
          <p>Upcoming session date</p>
          {info?.time && (
            <div className={styles.infoTime}>
              <TimeIcon />
              {info?.time}
            </div>
          )}
          {info?.date && (
            <div className={styles.info}>
              <DateIcon />
              {`${days[getDay(date)]} ${format(date, 'do MMMM yyyy')}`}
            </div>
          )}
          {info?.location && (
            <div className={styles.info}>
              <PinIcon />
              {info?.location}
            </div>
          )}
          <Button onClick={() => setInfoSessionModal(!infoSessionModal)} className={className} type="solidOrange" large>
            Register for an event
          </Button>
          <Modal
            isOpen={infoSessionModal}
            onRequestClose={() => setInfoSessionModal(false)}
            title="Information Sessions"
          >
            <InfoSessionsForm dates={dates} emailClick={emailClick} />
          </Modal>
          <Modal isOpen={enquireModal} onRequestClose={() => setEnquireModal(false)} title="Information Sessions">
            <ContactForm title="Information Sessions" />
          </Modal>
        </div>
      );
    }
  }

  return null;
};

export default InfoSessionsButton;
