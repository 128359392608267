import React from 'react';
import cc from 'classcat';

import { EmailButton } from '../../../../index';
import ButtonBlock from '../../../../PageContent/ButtonBlock';

import styles from './styles.module.scss';

interface Props {
  desktop?: boolean;
  mobile?: boolean;
  children?: React.ReactNode;
  customEmailDesktop?: JSX.Element;
  customEmailMobile?: JSX.Element;
  button: ButtonFragment | FeesButtonFragment;
}

const ApplyButtons = ({ desktop, mobile, children, customEmailDesktop, customEmailMobile, button }: Props) => (
  <>
    <div
      className={cc([
        'row',
        'desktopOnly',
        { [styles.desktopButtons]: true, [styles.hideDesktop]: !desktop && mobile },
      ])}
    >
      {children && <div className="container">{children}</div>}
      <div className="col-6 pl-0">
        <ButtonBlock item={{ ...button, includeArrow: false, buttonStyle: 'orangeBorder' }} />
      </div>
      <div className="col-6 pr-0">{customEmailDesktop ? customEmailDesktop : <EmailButton type="orangeBorder" />}</div>
    </div>
    <div
      className={cc(['row', 'mobileOnly', { [styles.mobileButtons]: true, [styles.hideMobile]: !mobile && desktop }])}
    >
      {children && <div className="container">{children}</div>}
      <div className="col-6">
        <ButtonBlock item={{ ...button, includeArrow: false, buttonStyle: 'orangeBorder' }} />
      </div>
      <div className="col-6">
        {customEmailMobile ? customEmailMobile : <EmailButton text="Email us" type="orangeBorder" />}
      </div>
    </div>
  </>
);

export default ApplyButtons;
