import React from 'react';
import { Field, useFormikContext } from 'formik';

import fieldStyles from '../fields.module.scss';
import styles from './checkboxField.module.scss';

interface Props {
  label: string;
  name: string;
  placeholder?: string;
  stretch?: boolean;
}

const CheckboxField = ({ label, name, placeholder, stretch = false }: Props) => {
  const { errors, touched } = useFormikContext<NursingHomeApplicationInterface>();

  return (
    <div>
      <div className={`${styles.container} ${stretch ? styles.stretch : ''}`}>
        <Field className={styles.checkbox} type="checkbox" name={name} placeholder={placeholder} />
        <label className={styles.label}>{label}</label>
      </div>
      {touched?.[name] && errors?.[name] && (
        <div className={fieldStyles.errorBox}>
          <p className={fieldStyles.errorMsg}>Required field</p>
        </div>
      )}
    </div>
  );
};

export default CheckboxField;
