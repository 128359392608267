import React, { useState } from 'react';
import cc from 'classcat';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { captureMessage, setExtras } from '@sentry/core';
import { Severity } from '@sentry/types';

import styles from './styles.module.scss';

interface Suggestion {
  active: boolean;
  description: string;
  index: number;
}

interface Props {
  callback: (address: string, addressParts?: AddressParts) => void;
  required?: boolean;
  placeholder?: string;
  children?: React.ReactNode;
}

const AddressAutocomplete = ({ callback, required, placeholder = 'e.g. 6000 or Perth', children }: Props) => {
  const [address, setAddress] = useState('');

  const onChange = async (address: string) => {
    setAddress(address);
    callback(address);
  };

  async function onSelect(address: string) {
    setAddress(address);

    const results = await geocodeByAddress(address);
    if (results.length) {
      const result = results[0].address_components;
      const addressParts: AddressParts = {
        streetName: result.find((c) => c.types.includes('route'))?.long_name || '',
        streetNumber: result.find((c) => c.types.includes('street_number'))?.long_name || '',
        suburb: result.find((c) => c.types.includes('locality'))?.long_name || '',
        state: result.find((c) => c.types.includes('administrative_area_level_1'))?.long_name || '',
        postcode: result.find((c) => c.types.includes('postal_code'))?.long_name || '',
        unitNumber: result.find((c) => c.types.includes('subpremise'))?.long_name || '',
      };

      // Sometimes, Google will not respond with the street number.
      // So we will match it to everything preceeding the street name in the original address string.
      if (!addressParts.streetNumber) {
        const streetNumber = address.match(new RegExp(`(.*?) ${addressParts.streetName}`));
        if (streetNumber && streetNumber.length >= 2) {
          addressParts.streetNumber = streetNumber[1];
        } else {
          setExtras({
            address,
            geocodeResponse: results,
          });
          captureMessage(
            'Failed to get street number from Geocoding Service and could not extract it from the address string.',
            Severity.Warning,
          );
        }
      }

      callback(address, addressParts);
    } else {
      captureMessage('Failed to geocode address in AddressAutocomplete.', Severity.Warning);
      callback(address);
    }
  }

  return (
    <PlacesAutocomplete
      value={address}
      onChange={onChange}
      onSelect={onSelect}
      searchOptions={AddressAutocomplete.searchOptions}
      highlightFirstSuggestion
      googleCallbackName="__googleMapsCallback"
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }: any) => (
        <div className={cc({ [styles.iconPadding]: children })}>
          <input
            {...getInputProps({
              placeholder,
              className: styles.addressInput,
            })}
            required={required}
            title="Please enter and select your address."
            onBlur={() => {
              // Force onSelect to run on blur
              getInputProps().onBlur();
              onSelect(address);
            }}
          />
          {children && <span className={styles.icon}>{children}</span>}
          <div className={cc({ [styles.dropDown]: true, [styles.dropDownActive]: suggestions.length })}>
            {suggestions.map((suggestion: Suggestion) => {
              const className = suggestion.active ? styles.dropDownItemActive : styles.dropDownItem;
              return (
                <div key={suggestion.description} {...getSuggestionItemProps(suggestion, { className })}>
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

AddressAutocomplete.searchOptions = {
  types: ['(regions)'],
  componentRestrictions: { country: 'AU' },
};

export default AddressAutocomplete;
