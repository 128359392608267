import React from 'react';
import cc from 'classcat';

import styles from './styles.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Heading = ({ children, className }: Props) => <div className={cc([styles.heading, className])}>{children}</div>;

export default Heading;
