import React from 'react';

import { Accordion } from '../../index';

import AccordionLink from './link';

import styles from './styles.module.scss';

interface Props {
  fragment: AccordionFragment;
}

const AccordionBlock = ({ fragment }: Props) => {
  const items = fragment?.children;

  if (items) {
    return (
      <div className={styles.accordionBlock}>
        {items?.map(
          (item,index) =>
            item && (
              <Accordion key={item!.title! + index} title={item!.title!} blockStyle>
                <div dangerouslySetInnerHTML={{ __html: item!.text! }} />
                <AccordionLink item={item} />
              </Accordion>
            ),
        )}
      </div>
    );
  }
  return null;
};

export default AccordionBlock;
