import React from 'react';

export default class PageUp extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div
        id="jobs"
        dangerouslySetInnerHTML={{
          __html:
            '{javascript}{css}{form}<div class="jobs"><div class="filters"><a target="_blank" rel="noreferrer" href="https://secure.dc2.pageuppeople.com/apply/951/cw/applicationForm/" class="button">Applicant Login</a><div><div class="filtersHeading">Refine search</div>{search-filters button-text="Filter Results"}</div></div><div class="listing"><div class="search"><h2>Job Keyword search</h2>{search-keyword placeholder="e.g. Carer, Perth"}</div>{messages}{pages}</div></div>{/form}',
        }}
      />
    );
  }
}
