import React, { useState } from 'react';
import Spinner from '@atlaskit/spinner';
import { useFormikContext } from 'formik';
import { Severity, captureMessage, setExtras } from 'isomorphic-sentry';

import { useIsMobile, useStore } from '../../../../../hooks';
import { trackEvent } from '../../../../../lib/GA';

import { CheckboxField, MainFiles } from '../../Fields';

import formStyles from '../../nursingHomeForm.module.scss';

const ConfirmationStep = () => {
  const { values, touched, errors, submitForm, validateForm } = useFormikContext<NursingHomeApplicationInterface>();
  const [isMobile] = useIsMobile(1000);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const { phone, freeform } = useStore('globals');

  const formHandle = 'nursingHomeApplicationSubmit';
  const freeForm = freeform?.find((form) => form.handle === formHandle);

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      let valid = true;
      await validateForm();
      !values.termsAccepted && (touched.termsAccepted = true);
      !!Object.keys(errors).length && (valid = false);
      if (valid) {
        setErrorMsg('');
        trackEvent('Form', 'Submit', 'Nursing Homes');
        await submitForm();
        // Don't set loading as false if success
      } else {
        setErrorMsg(`Error please fill out all required fields`);
        setLoading(false);
      }
    } catch (error) {
      setErrorMsg('server');
      setExtras({ error });
      captureMessage('Failed submit nursing home application form', Severity.Warning);
      setLoading(false);
    }
  };

  return (
    <>
      {!isMobile && <p className={formStyles.headingL}>Summary</p>}
      <MainFiles />
      <div className={formStyles.divider} />
      <CheckboxField
        label="I would like to stay up to date with what is going on at Amana Living"
        name="keepUpToDate"
      />
      <CheckboxField
        label="I declare that all the information provided by me on this application is true and correct to my knowledge.
              I understand that my Assets and Income Assessment processed by Centrelink may change the fees charged by Amana Living.
              I understand that if I choose not to disclose my assets that I will be charged the maximum fees until my Assets and Income Assessment can be processed by Centrelink."
        name="termsAccepted"
      />

      {
        freeForm?.honeypot ? <input type={'hidden'} name={freeForm.honeypot?.name || ''} value={freeForm.honeypot?.value || ''}/> : null
      }

      {!!errorMsg && errorMsg === 'server' && (
        <p className={formStyles.internalError}>
          An internal error has occured while trying to submit your form.
          <br />
          Please try again, or{' '}
          <a className={formStyles.link} href={`tel:${phone}`}>
            call us
          </a>
        </p>
      )}
      {!!errorMsg && errorMsg !== 'server' && <p className={formStyles.internalError}>{errorMsg}</p>}
      {!loading ? (
        <button className={formStyles.nextStep} onClick={handleSubmit}>
          Submit Form
        </button>
      ) : (
        <div className={formStyles.spinnerWrapper}>
          <Spinner />
        </div>
      )}
    </>
  );
};

export default ConfirmationStep;
