import React, { useCallback } from 'react';
import cc from 'classcat';
import { observer } from 'mobx-react';

import query from '../../../graphql/CoursesCategories.graphql';
import { useSiteQuery } from '../../../hooks';
import { useStore } from '../../../hooks';

import styles from './styles.module.scss';

const CoursesMenu = () => {
  const store = useStore('coursesStore');
  const [{ data }] = useSiteQuery<CoursesCategoriesQuery>({ query });
  const menus = data?.coursesCategories;

  const isActive = useCallback(
    (item: string) => {
      return store.category === item;
    },
    [store],
  );

  if (menus) {
    return (
      <div className={styles.coursesMenu}>
        {menus.map(
          (m) =>
            m && (
              <a
                key={m.title!}
                type="button"
                className={cc({ [styles.item]: true, [styles.active]: isActive(m.slug!) })}
                href={`/courses?category=${m.slug}`}
              >
                {m.title}
              </a>
            ),
        )}
      </div>
    );
  }
  return null;
};

export default observer(CoursesMenu);
