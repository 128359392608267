import React, { useState } from 'react';
import { useFormikContext } from 'formik';

import { useIsMobile } from '../../../../../hooks';

import DropdownToggle from '../../../../../images/icons/dropdown-toggle.svg';

import { AddressField, InputField, RadioField, SelectField } from '../../Fields';
import { countries, nationalities } from './values';

import formStyles from '../../nursingHomeForm.module.scss';

interface Props {
  id: number;
}

const SectionContent = ({ id }: Props) => {
  const { values } = useFormikContext<NursingHomeApplicationInterface>();
  const [isMobile] = useIsMobile(1000);
  const residentNaming = values.isCouple == 'yes' ? (id == 1 ? 'first ' : 'second ') : '';
  const [dropdownsToggled, setDropdownsToggled] = useState<Array<boolean>>([false, false, false, false]);

  const handleDropdownToggle = (dropdownIndex: number) => {
    const dropDownToggeledInternal = [...dropdownsToggled];
    dropDownToggeledInternal[dropdownIndex] = !dropDownToggeledInternal[dropdownIndex];
    setDropdownsToggled(dropDownToggeledInternal);
  };

  return (
    <div className={`${formStyles.fadeIn} ${formStyles.hide} ${values.isCouple != '' && formStyles.show}`}>
      <div className={formStyles.divider} />
      <div className={formStyles.toggleDropdown} onClick={() => handleDropdownToggle(0)} aria-hidden>
        <p className={formStyles.headingS}>Please tell us about the {residentNaming}resident</p>
        {isMobile && (
          <DropdownToggle
            className={`${formStyles.dropdownSVG} ${dropdownsToggled[0] ? formStyles.dropdownSvgToggled : ''}`}
          />
        )}
      </div>
      {(!isMobile || dropdownsToggled[0]) && (
        <div className={`${formStyles.inputSection} ${isMobile ? formStyles.fadeIn : ''}`}>
          <SelectField
            label="Title"
            name={`title_${id}`}
            options={[
              { value: '', text: 'Select Title', disabled: true },
              { value: 'Mr' },
              { value: 'Mrs' },
              { value: 'Miss' },
              { value: 'Ms' },
              { value: 'Dr' },
              { value: 'Other' },
            ]}
          />
          <InputField label="First name" type="text" name={`firstName_${id}`} />
          <InputField label="Middle name/s" type="text" name={`middleNames_${id}`} />
          <InputField label="Surname" type="text" name={`lastName_${id}`} />
          <InputField label="Date of birth" type="date" name={`dob_${id}`} max="now" />
          <SelectField
            label="Gender"
            name={`gender_${id}`}
            options={[
              { value: '', text: 'Select gender', disabled: true },
              { value: 'Male' },
              { value: 'Female' },
              { value: 'Other' },
            ]}
          />
          <SelectField
            label="Martial Status"
            name={`maritalStatus_${id}`}
            options={[
              { value: '', text: 'Select status', disabled: true },
              { value: 'Married' },
              { value: 'De-facto' },
              { value: 'Single' },
            ]}
          />
          {id === 1 && values?.maritalStatus_1 === 'Married' && values?.isCouple === 'no' && (
            <InputField label="Spouse (Full Name)" type="text" name="spouseName" />
          )}
        </div>
      )}

      {isMobile && <div className={formStyles.thinDivider} />}
      <div className={formStyles.toggleDropdown} onClick={() => handleDropdownToggle(1)} aria-hidden>
        <p className={formStyles.headingS}>Tell us about the {residentNaming}resident’s background and languages</p>
        {isMobile && (
          <DropdownToggle
            className={`${formStyles.dropdownSVG} ${dropdownsToggled[1] ? formStyles.dropdownSvgToggled : ''}`}
          />
        )}
      </div>
      {(!isMobile || dropdownsToggled[1]) && (
        <div className={isMobile ? formStyles.fadeIn : ''}>
          <RadioField
            label="Is the resident of Aboriginal and/or Torres Strait Islander origin?"
            name={`isAboriginalOrTorres_${id}`}
          />
          <div className={formStyles.inputSection}>
            <SelectField
              label="Country of birth"
              name={`countryOfBirth_${id}`}
              options={[{ value: '', text: 'Select country', disabled: true }, ...countries]}
            />
            <SelectField
              label="Nationality"
              name={`nationality_${id}`}
              options={[{ value: '', text: 'Select nationality', disabled: true }, ...nationalities]}
            />
            <InputField label="Languages Spoken" type="text" name={`languageSpoken_${id}`} stretch />
          </div>
          <RadioField label="Is an interpreter required?" name={`interpreterRequired_${id}`} stretch />
        </div>
      )}

      {isMobile && <div className={formStyles.thinDivider} />}
      <div className={formStyles.toggleDropdown} onClick={() => handleDropdownToggle(2)} aria-hidden>
        <p className={formStyles.headingS}>Resident’s contact information</p>
        {isMobile && (
          <DropdownToggle
            className={`${formStyles.dropdownSVG} ${dropdownsToggled[2] ? formStyles.dropdownSvgToggled : ''}`}
          />
        )}
      </div>
      {(!isMobile || dropdownsToggled[2]) && (
        <div className={`${formStyles.inputSection} ${isMobile ? formStyles.fadeIn : ''}`}>
          <InputField label="Email" type="email" name={`email_${id}`} />
          <InputField label="Phone" type="tel" name={`phone_${id}`} />
          <AddressField label="Address" name={`address_${id}`} />
        </div>
      )}

      {isMobile && <div className={formStyles.thinDivider} />}
      <div className={formStyles.toggleDropdown} onClick={() => handleDropdownToggle(3)} aria-hidden>
        <p className={formStyles.headingS}>COVID Vaccination status</p>
        {isMobile && (
          <DropdownToggle
            className={`${formStyles.dropdownSVG} ${dropdownsToggled[3] ? formStyles.dropdownSvgToggled : ''}`}
          />
        )}
      </div>
      {(!isMobile || dropdownsToggled[3]) && (
        <div className={isMobile ? formStyles.fadeIn : ''}>
          <RadioField label="Have you had a COVID-19 vaccination?" name={`covidStatus_${id}`} stretch />

          <div
            className={`${formStyles.inputSection} ${formStyles.hide} ${
              values?.[`covidStatus_${id}`] == 'yes' && formStyles.show
            }`}
          >
            <InputField label="First dose date" type="date" name={`covidDoseDate_1_${id}`} max="now" />
            <InputField label="Second dose date" type="date" name={`covidDoseDate_2_${id}`} max="now" />
            <InputField label="Third dose date" type="date" name={`covidDoseDate_3_${id}`} max="now" />
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionContent;
