import React, { useCallback } from 'react';
import cc from 'classcat';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';

import { AddressAutocomplete } from '../../../../index';
import { Heading } from '../../../Wizard';
import { QuestionnaireFormValues, StepComponent } from '../../../../../typings';

import styles from './styles.module.scss';

const LocationStep: StepComponent = () => {
  const { setFieldValue } = useFormikContext<QuestionnaireFormValues>();

  const updateAddress = useCallback(
    (address: string, parts: AddressParts | undefined) => {
      setFieldValue('address', address);
      if (parts?.postcode) {
        setFieldValue('postcode', parts?.postcode);
      }
    },
    [setFieldValue],
  );

  return (
    <>
      <Heading>Where do you live?</Heading>
      <fieldset>
        <div className={cc([styles.location, 'customInput'])}>
          <label htmlFor="address">Postcode or suburb</label>
          <AddressAutocomplete callback={updateAddress} />
        </div>
      </fieldset>
    </>
  );
};

LocationStep.label = 'Your location';
LocationStep.validationSchema = Yup.object().shape({
  address: Yup.string(),
});

export default LocationStep;
