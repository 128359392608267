import React from 'react';
import cc from 'classcat';

import BaseActivePath from '../../../images/icons/paths/base-active-test.svg';
import BasePath from '../../../images/icons/paths/base.svg';
import EqualActivePath from '../../../images/icons/paths/equal-active.svg';
import EqualPath from '../../../images/icons/paths/equal.svg';
import HighActivePath from '../../../images/icons/paths/high-active.svg';
import HighPath from '../../../images/icons/paths/high.svg';
import HigherActivePath from '../../../images/icons/paths/higher-active.svg';
import HigherPath from '../../../images/icons/paths/higher.svg';
import LowActivePath from '../../../images/icons/paths/low-active.svg';
import LowPath from '../../../images/icons/paths/low.svg';
import LowerActivePath from '../../../images/icons/paths/lower-active.svg';
import LowerPath from '../../../images/icons/paths/lower.svg';
import NextActivePath from '../../../images/icons/paths/next-active.svg';
import NextPath from '../../../images/icons/paths/next.svg';

import styles from './styles.module.scss';

interface Props {
  node: PathwayNodeFragment;
  level: number;
  index: number;
  currentActive: string;
  isActive: boolean;
  isNext: boolean;
  isPrevious: boolean;
  onClick: (level: number, node: string) => void;
}

const drawPath = (
  index: number,
  childIndex: number,
  currentActive: string,
  duplicated: boolean,
  isPrevious: boolean,
  isActive: boolean,
) => {
  const pathType = index - childIndex;
  const pathActive = isActive || (isPrevious && currentActive.endsWith(`-${childIndex}`));

  switch (pathType) {
    case 0:
      return pathActive ? (
        <EqualActivePath
          key="path-equal"
          className={cc([styles.equal, { [styles.duplicated]: duplicated }])}
          style={{ zIndex: 2 }}
        />
      ) : (
        <EqualPath key="path-equal" className={cc([styles.equal, { [styles.duplicated]: duplicated }])} />
      );
      break;
    case -1:
      return pathActive ? (
        <LowActivePath
          key="path-low"
          className={cc([styles.low, { [styles.duplicated]: duplicated }])}
          style={{ zIndex: 2 }}
        />
      ) : (
        <LowPath key="path-low" className={cc([styles.low, { [styles.duplicated]: duplicated }])} />
      );
      break;
    case -2:
      return pathActive ? (
        <LowerActivePath
          key="path-lower"
          className={cc([styles.lower, { [styles.duplicated]: duplicated }])}
          style={{ zIndex: 2 }}
        />
      ) : (
        <LowerPath key="path-lower" className={cc([styles.lower, { [styles.duplicated]: duplicated }])} />
      );
      break;
    case 1:
      return pathActive ? (
        <HighActivePath key="path-high" className={styles.high} style={{ zIndex: 2 }} />
      ) : (
        <HighPath key="path-high" className={styles.high} />
      );
      break;
    case 2:
      return pathActive ? (
        <HigherActivePath key="path-higher" className={styles.higher} style={{ zIndex: 2 }} />
      ) : (
        <HigherPath key="path-higher" className={styles.higher} />
      );
      break;
    default:
      break;
  }
  return null;
};

const allPaths = (
  index: number,
  childs: string,
  currentActive: string,
  duplicated = false,
  isPrevious = false,
  isActive = false,
) => {
  if (childs) {
    const paths = childs.split(',').map((c) => parseInt(c));
    return (
      <>
        {isActive || isPrevious ? (
          <BaseActivePath className={cc([styles.base, { [styles.duplicated]: duplicated }])} />
        ) : (
          <BasePath className={cc([styles.base, { [styles.duplicated]: duplicated }])} />
        )}
        {paths.map((p) => drawPath(index, p, currentActive, duplicated, isPrevious, isActive))}
      </>
    );
  }
  return null;
};

const PathwayNode = ({ node, level, index, currentActive, isActive, isNext, isPrevious, onClick }: Props) => {
  if (node) {
    return (
      <div
        key={node.uid}
        className={cc([
          styles.node,
          { [styles.active]: isActive, [styles.isPrevious]: isPrevious, [styles.isNext]: isNext },
        ])}
      >
        <button className={styles.circle} onClick={() => onClick(level, `${level}-${index}`)} />
        <span>{node.title}</span>
        {node.pathwaysChilds &&
          allPaths(index, node.pathwaysChilds, currentActive, !!node.toggle, isPrevious, isActive)}
        {node.forceNext &&
          (isActive || isPrevious ? <NextActivePath className={styles.next} /> : <NextPath className={styles.next} />)}
      </div>
    );
  }
  return null;
};

export default PathwayNode;
