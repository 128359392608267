import React from 'react';

import { VideoCard } from '../../index';

import styles from './styles.module.scss';

interface Props {
  fragment: VideosFragment;
}

const Videos = ({ fragment }: Props) => {
  return (
    <div id="videos" className={styles.videos}>
      <div className="container">
        <div className="sectionHeading">{fragment.title}</div>
        <h2 className="sectionTitle">{fragment.text}</h2>
        <div className="row cardsWrapper">
          {fragment.videos?.map((item) => item && <VideoCard key={item.uid!} item={item} />)}
        </div>
      </div>
    </div>
  );
};

export default Videos;
