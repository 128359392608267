import React, { useCallback } from 'react';
import cc from 'classcat';
import { observer } from 'mobx-react';

import { useSiteQuery } from '../../../hooks';
import { useStore } from '../../../hooks';

import query from './coursesTopics.graphql';

import styles from './styles.module.scss';

const CoursesTopic = () => {
  const store = useStore('coursesStore');
  const [{ data }] = useSiteQuery<CoursesTopicsQuery>({ query });
  const menus = data?.coursesTopics;

  const handleClick = useCallback(
    (item: string, e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      store.setTopic(item);
    },
    [store],
  );

  const isActive = useCallback(
    (item: string) => {
      return store.topics.includes(item);
    },
    [store],
  );

  if (menus) {
    return (
      <div className={styles.topicMenu}>
        {menus.map(
          (m) =>
            m && (
              <button
                key={m.title!}
                type="button"
                className={cc({ [styles.item]: true, [styles.active]: isActive(m.slug!) })}
                onClickCapture={(e) => handleClick(m.slug!, e)}
              >
                {m.title}
              </button>
            ),
        )}
      </div>
    );
  }
  return null;
};

export default observer(CoursesTopic);
