import React from 'react';
import cc from 'classcat';

import PhotoIcon from '../../images/icons/photo.svg';
import PlayIcon from '../../images/icons/play-video.svg';

import PhotoCarousel from '../PhotoCarousel';

import styles from './styles.module.scss';

interface Props {
  sectionHeading: string;
  title: string;
  photos: Maybe<AssetFragment>[];
  videoUrl: string;
  children?: React.ReactNode;
  onGalleryClick: (e: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>) => void;
  onVideoClick: (e: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>) => void;
}

const HeroUnit = ({ sectionHeading, title, photos, videoUrl, children, onGalleryClick, onVideoClick }: Props) => {
  const num = photos.length;

  return (
    <div className={cc(['sectionWrapper', styles.hero, styles.heroWithoutCta])}>
      <div className={cc(['container', styles.heroContainer])}>
        <div className="row">
          <div className="col-lg-6">
            <div className="sectionHeading">{sectionHeading}</div>
            <h1 className={cc(['heroH1', styles.unitH1])} data-test-id="customTitle">
              {title}
            </h1>
            <div className={styles.heroImageMobile}>
              <PhotoCarousel photos={photos.filter((p): p is AssetFragment => p !== null && p !== undefined)} />
              {num > 1 && (
                <button className={styles.galleryBtn} onClick={onGalleryClick}>
                  <PhotoIcon />
                  Photos <span>{num}</span>
                </button>
              )}
            </div>
            {children}
          </div>
          <div className="col-lg-6">
            <div className={styles.heroImage}>
              <PhotoCarousel photos={photos.filter((p): p is AssetFragment => p !== null && p !== undefined)} />
              {num > 1 && (
                <button className={styles.galleryBtn} onClick={onGalleryClick}>
                  <PhotoIcon />
                  Photos <span>{num}</span>
                </button>
              )}
              {videoUrl && (
                <button className={styles.videoBtn} onClick={onVideoClick}>
                  <PlayIcon />
                  Watch video tour
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroUnit;
